
	/**
	*
	* 		Modal
	*			- A Modal Function
	*
	**/
	@use '../_config' as Config;

	//Modal
	#modal {
		position:fixed;
		top:0;
		right:0;
		bottom:0;
		left:0;
		width:100vw;
		min-width:100%;
		min-height:100%;
		height:100vh;
		overflow:auto;
		z-index:1;
		padding:20px;
		box-sizing:border-box;
		background:rgba(0,0,0,0.75);
		text-align:center;
		z-index:999999999;
		display:flex;
		justify-content:center;
		align-items:center;
		@media all and (max-width:450px){
			padding:0;
		}
		> .content {
			max-width:700px;
			padding:52px;
		    vertical-align:middle;
		    position:relative;
		    z-index:2;
		    box-sizing:border-box;
		    width:90%;
		    background:Config.color('background');
		    text-align:left;
		    margin:auto;
		    border-top:5px solid Config.color('sage');

		    .confirm {
		    	h2 {
		    		font-size:30px;
		    		margin:0;
		    	}
		    	p {
		    		margin:10px 0 0;
		    	}
		    	.buttons {
		    		padding-top:25px;
		    		justify-content:space-between;
		    	}
		    }

			@media all and (max-width:450px){
				padding:52px 20px;
			}
		}

		a.close {
			position:absolute;
			margin:0 !important;
			padding:0;
		    top:-17.5px;
		    right:-12.5px;
		    display:block;
		    width:30px;
		    height:30px;
		    text-indent:-9999px;
		    background-size:contain;
		    background-repeat:no-repeat;
		    background-position:center center;
		    background:Config.color('brand');
		    transition:0.3s ease background !important;
		    border-radius:100%;
	  		cursor:pointer;
	  		display:flex;
	  		span {
	  			height:1.5px;
	  			background:#FFF;
	  			transition:0.3s ease background;
	  			position:absolute;
	  			transform:rotate(45deg);
	  			width:15px;
	  			top:calc( 50% - (15px / 2) + 7px );
	  			left:calc(50% - (15px / 2));
	  			+ span {
					transform:rotate(-45deg);
	  			}
	  		}
	  		&:hover {
	  			background:Config.color('text');
	  			span {
	  				background:Config.color('sage');
	  			}
	  		}
		}

		form {
			.recaptcha {
				margin:0;
			}
			footer {
				padding-top:10px;
				a {
					padding-left:10px;
					text-decoration:underline;
					cursor:pointer;
				}
			}
		}

		.success {
			margin:auto;
			.checkmark {
			    width:100px;
			    height:100px;
			    border-radius:50%;
			    display:block;
			    stroke-width:2;
			    stroke:Config.color('sage');
			    stroke-miterlimit:10;
			    box-shadow:inset 0px 0px 0px Config.color('sage');
			    animation:modal-success-fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
			    position:relative;
			    top:5px;
			    right:5px;
			   	margin:0 auto;
			   	circle {
				    stroke-dasharray:166;
				    stroke-dashoffset:166;
				    stroke-width:2;
				    stroke-miterlimit:10;
				    stroke:Config.color('sage');
				    fill:none;
				    animation:modal-success-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
			   	}
			   	path {
				    transform-origin:50% 50%;
				    stroke-dasharray:48;
				    stroke-dashoffset:48;
				    animation:modal-success-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
				}
			}

		}
	}



	.mopen {
		//@media all and (max-width:550px){
			overflow:hidden;
		//}
	}





	@keyframes modal-success-stroke {
	    100% {
	        stroke-dashoffset:0;
	    }
	}

	@keyframes modal-success-scale {
	    0%, 100% {
	        transform:none;
	    }
	    50% {
	        transform:scale3d(1.1, 1.1, 1);
	    }
	}

	@keyframes modal-success-fill {
	    100% {
	        box-shadow:inset 0px 0px 0px 3px Config.color('sage');
	    }
	}