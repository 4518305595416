
	/**
	*
	* 		Batch
	*			- Batch Style(s)
	*
	**/
	@use '../components/_buttons.scss';
	
	#pbatch {

		#batch-lookup {
			padding:150px 25px 200px;
			max-width:644px;

			@media all and (max-width:700px){
				padding:75px 25px 100px;
			}

			@media all and (max-width:500px){
				padding:75px 25px;
			}

			> header {
				padding-bottom:20px;

				h1 {
					font-weight:400;
					font-size:43px;
					letter-spacing:0;
					margin:0;
				}

				p {
					font-weight:400;
					font-size:14px;
					line-height:21px;
					letter-spacing:0.02em;
					margin-bottom:0;

					a {
						text-decoration:underline;
						font-size:13px;
					}
				}

			}

			form {
				padding-right:0;
				input {
					font-size:16px;
				}
				button {
					@extend .button;
				}
			}

			.batch-info {
				margin-top:50px;
				padding:40px;
				background:#F5F5F5;
			}

		}

	}