
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use '../_config' as Config;

	.pdefault {
		.banner {
			margin-bottom:50px;
		}
		article {
			padding-bottom:50px;

			@media all and (max-width:755px){
				padding-bottom:50px;
			}

			h2, h3, h4, h5 {
				font-weight:normal;
				font-size:32px;
				@media all and (max-width:755px){
					font-size:26px;
				}
			}

			h3 {
				font-size:29px;
				@media all and (max-width:755px){
					font-size:27px;
				}
			}

			h4 {
				font-size:27px;
				@media all and (max-width:755px){
					font-size:25px;
				}
			}

			h5 {
				font-size:25px;
				@media all and (max-width:755px){
					font-size:23px;
				}
			}


			p {
				font-size:16px;

				@media all and (max-width:755px){
					font-size:16px;
					line-height:23px;
					letter-spacing:0.44px;
				}
			}


			strong {
			}

			a {
				color:Config.color('brand');
				text-decoration:none;
			}

			ul li,
			ol li {
				padding-bottom:15px;
				font-size:16px;
				line-height:30px;

				@media all and (max-width:755px){
					font-size:16px;
					line-height:23px;
					letter-spacing:0.44px;
				}
			}
		}
		
	}