
	/**
	*
	* 		Product Card
	*			- A Product Card in a Collection
	*
	**/

	//Product Card
	[data-testid="animated-teaser"] {
		@media all and (max-width:750px){
			display:none !important;
		}
	}