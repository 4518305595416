
	/**
	*
	* 		Modal
	*			- A Modal Function
	*
	**/

	//Quick Card, Quick-Add Modal
	#modal .ingredients {
		h2 {
			font-size:30px;
		}
	}