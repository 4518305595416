
	/**
	*
	* 		Layout
	*			- Grid and Layout styles for the website
	*
	**/
	:root {
		--container-padding:26px;
		--wrap-padding:50px;
	}

	@media all and (max-width:500px){
		:root {
			--container-padding:15px;
			--wrap-padding:15px;
		}
	}

	//Container
	.container {
		box-sizing:border-box;
		max-width:1440px;
		margin:0 auto;
		padding:0 var(--container-padding);

		> .wrap {
			//width:100%;
			//max-width:1120px;
			padding:0 var(--wrap-padding);
			margin:0 auto;
			box-sizing:border-box;
		}
	}

	.grecaptcha-badge {
		visibility:hidden;
	}

	.recaptcha-note {
		font-size:12px;
		font-style:italic;
		a {
			text-decoration:underline;
		}
	}