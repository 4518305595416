
	/**
	*
	* 		BUTTONS
	*			- Add any button styles in here.
	* 			- Below is grouped into a buttons list but the .button is also accessible directly
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	//Single Button
	.button {
		appearance:none;
		user-select:none;
		display:inline-block;
		background:Config.color('brand');
		font-size:clamp( 12px , 2.2vw , 14px );
		font-style:normal;
		font-weight:500;
		line-height:100%;
		letter-spacing:0.6px;
		text-transform:uppercase;
		padding:11px 18px;
		border:1px solid Config.color('brand');
		color:#FFF;
		vertical-align:center;
		text-align:center;
		text-decoration:none;
		cursor:pointer;
		transition:0.3s ease background, 0.3s ease color, 0.3s ease border, 0.3s ease opacity;

		svg {
			position:relative;
			transition:0.3s ease-in-out transform;
			display:inline-block;
			path {
				transition:0.3s ease stroke;
			}
		}

		&.arrow-left {
			svg {
				transform:rotate(180deg);
			}
			&:hover {
				svg {
					transform:rotate(180deg) translateX(2px);
				}
			}
		}

		&.arrow-up {
			svg {
				transform:rotate(180deg);
			}
			&:hover {
				svg {
					transform:rotate(180deg) translateY(2px);
				}
			}
		}

		&[disabled] {
			opacity:.5;
			background:#666 !important;
			cursor:not-allowed;
			border:1px solid #666 !important;
			color:#FFF !important;
		}

		&.hidden {
			opacity:0;
			pointer-events:none !important;
		}

		&.hide {
			display:none;
		}

		&:hover {
			background:color.adjust( Config.color('brand') , $lightness: -20% );
			border-color:color.adjust( Config.color('brand') , $lightness: -20% );
			svg {
				transform:translateX(2px);
			}
			&.arrow-down {
				svg {
					transform:translateY(2px);
				}
			}
		}

		&.small {
			padding:10px 20px;
			font-size:12px;
		}

		&.narrow {
			padding:5px 12px;
			font-size:12px;
			svg {
				path {
					stroke-width:1;
				}
			}
		}

		//White
		&.white:not(.inverted) {
			background:#FFF;
			color:Config.color('text');
			border-color:#FFF;
			svg {
				path {
					stroke:Config.color('text');
				}
			}
			&:hover {
				background:Config.color('text');
				border-color:Config.color('text');
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
			}
		}

		//Dark
		&.dark:not(.inverted) {
			background:Config.color('text');
			border-color:Config.color('text');
			color:#FFF;
			&:hover {
				background:Config.color('brand');
				border-color:Config.color('brand')
			}
		}

		//Dark
		&.sage:not(.inverted) {
			background:color.adjust( Config.color('sage') , $lightness: 20% );
			border-color:color.adjust( Config.color('sage') , $lightness: 20% );
			color:Config.color('brand');
			&:hover {
				background:Config.color('sage');
				border-color:Config.color('sage');
				color:#FFF;
			}
		}

		//With Arrow
		&.arrow {
			span {
				display:flex;
				align-items:center;
				justify-content:center;
				span:first-child {
					padding-right:10px;
				}
			}
		}

		//Inverted
		&.inverted {
			background:transparent;
			border-color:Config.color('brand');
			color:Config.color('brand');
			svg {
				path {
					stroke:Config.color('brand');
				}
			}
			&:hover {
				background:Config.color('brand');
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
			}

			//Inverted White
			&.white {
				border-color:#FFF;
				color:#FFF;
				svg {
					path {
						stroke:#FFF;
					}
				}
				&:hover {
					background:#FFF;
					color:Config.color('text');
					svg {
						path {
							stroke:Config.color('text');
						}
					}
				}
			}

			//Inverted Dark
			&.dark {
				color:Config.color('text');
				border-color:Config.color('text');
				svg {
					path {
						stroke:Config.color('text');
					}
				}
				&:hover {
					background:Config.color('text');
					color:#FFF;
					svg {
						path {
							stroke:#FFF;
						}
					}
				}
			}

			//Inverted White
			&.sage {
				border-color:Config.color('sage');
				color:Config.color('sage');
				svg {
					path {
						stroke:Config.color('sage');
					}
				}
				&:hover {
					background:Config.color('sage');
					color:#FFF;
					svg {
						path {
							stroke:#FFF;
						}
					}
				}
			}
		}

		&:hover {

		}

	}

	//Button Container
	.buttons {
		display:flex;
		flex-wrap:wrap;
		gap:10px;
	}