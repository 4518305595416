
	/**
	*
	* 		Modal
	*			- A Modal Function
	*
	**/
	@use '../_config' as Config;

	//Quick Card, Quick-Add Modal
	#modal .shop-points {
		header {
			border-bottom:1px dashed Config.color('sage');
			padding-bottom:25px;
			margin-bottom:35px;
			h2 {
				font-size:clamp(30px, 9vw, 49px );
				text-align:center;
				margin:0;
				line-height:normal;
				font-weight:100;
			}
		}
		> div {
			display:flex;
			justify-content:space-between;
			gap:60px;

			> div {
				max-width:332px;
				width:100%;

				svg {
					width:100%;
					height:auto;
					max-width:332px;
					padding:1px; //Fixes Safari cutting off the edge
				}

				h3 {
					margin:0;
					font-size:14px;
					font-family:Config.font('primary');
					font-style:normal;
					font-weight:600;
					line-height:92.857%;
					letter-spacing:1px;
					text-transform:uppercase;
					padding:26px 0 12px;
				}

				p {
					font-size:16px;
					font-style:normal;
					font-weight:400;
					line-height:150%;
					margin:0;
				}
			}

			@media all and (max-width:450px){
				flex-direction:column;
				align-items:center;
				> div {
					margin:0 auto;
					svg {
						width:80%
					}
				}
			}

		}

		footer {
			border-top:1px dashed Config.color('sage');
			padding-top:45px;
			margin-top:35px;
			text-align:center;
			.buttons {
				justify-content:center;
			}
			> a {
				font-size:12px;
				text-decoration:underline;
				transition:0.3s ease color;
				display:inline-block;
				margin-top:10px;
				&:hover {
					color:Config.color('brand');
				}
			}
		}
	}