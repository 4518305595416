
	/**
	*
	* 		Account Activation Page
	*			- Styles for the "Activate" page
	*
	**/
	@use '_login.scss';

	#pactivate {

		@extend #plogin;

		#activate-account-form {
			.preloader {
				position:fixed;
				z-index:100;
			}
		}

	}
