
	/**
	*
	* 		Retailers
	*			- The Stockists & Makers Page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	#pstockists,
	#pmakers {

		#intro {
			padding-top:50px;
		}

		#intro .container,
		#retailers header,
		#recommend header {
			text-align:center;
			padding-bottom:100px;

			@media all and (max-width:755px){
				padding-bottom:50px;
			}

			h1,
			h2 {
				font-weight:normal;
				text-align:center;
				font-size:42px;
				letter-spacing:-0.34px;
				line-height:125%;
				margin:0 auto 15px;
			}

			p {
				text-align:center;
				font-size:16px;
				letter-spacing:0.49px;
				line-height:30px;
				max-width:775px;

				@media all and (max-width:755px){
					text-align:justify;
					font-size:16px;
					line-height:23px;
					letter-spacing:0.44px;
				}
			}

			.buttons {
				justify-content:center;
				@media all and (max-width:450px){
					flex-direction:column;
				}
			}
		}

		#store-map {
			padding:75px 0;
			border:1px dashed color.adjust( Config.color('sage') , $lightness: 20% );
			border-width:1px 0;

			@media all and (max-width:755px){
				padding:50px 0;
			}

			> div {
				display:flex;
				height:600px;
				gap:2%;

				.directory {
					width:30%;
					height:100%;
					display:flex;
					flex-direction:column;
					max-width:369px;

					> header {
						width:100%;
						padding:0 0 20px 0;
						border-bottom:1px dashed color.adjust( Config.color('sage') , $lightness: 20% );
						box-sizing:border-box;
						h2 {
							font-size:36px;
							font-style:normal;
							font-weight:400;
							line-height:119.048%;
							margin:0 0 20px;
						}
						input {
							padding:13px 10px;
						}
						.button {
							width:100%;
						}
					}
					> div {
						overflow-y:scroll;
						padding-right:20px;
						width:100%;
						&::-webkit-scrollbar {
						    display: none;
						}

						.none {
							padding:30px 20px;
							font-style:italic;
							text-transform:lowercase;
							font-size:14px;
							text-align:center;
							a {
								text-decoration:underline;
							}
						}
						.store {
							padding:30px 20px;
							border-bottom:1px dashed Config.color('sage');
							transition:0.3s ease background;
							position:relative;

							header {
								display:flex;
								justify-content:space-between;
								padding-bottom:5px;
								h3 {
									font-size:20px;
									font-style:normal;
									font-weight:400;
									letter-spacing:0.35px;
									line-height:133.333%;
									margin:0;
								}
								span {
									display:flex;
									align-items:center;
									svg {
										width:10px;
										transition:0.3s ease width;
									}
									span {
										padding-left:5px;
										font-weight:bold;
										font-size:10px;
										transition:0.3s ease font-size;
									}
								}
							}

							p {
								margin:5px 0;
							}

							&:hover,
							&.hover {
								background:color.adjust( Config.color('sage'), $lightness:-10% );

								&,
								a {
									color:#FFF;
								}

								header {
									svg {
										color:#FFF;
										width:14px;
									}
									span {
										font-size:12px;
									}
								}

								&:after {
									content:' ';
									width:0;
									height:0;
									border-top:10px solid transparent;
									border-bottom:10px solid transparent;
									border-left:10px solid color.adjust( Config.color('sage'), $lightness:-10% );
									position:absolute;
									right:-10px;
									top:calc(50% - 5px)
								}
							}
						}
					}
				}
				.map {
					height:100%;
					width:100%;
					.marker {
						position:relative;
						top:calc( -25px / 2 );
						left:calc( -25px / 2 );
						transition:0.3s ease left, 0.3s ease top, 0.3s ease color;
						&.hover,
						&:hover {
							top:calc( -35px / 2 );
							left:calc( -35px / 2 );
							color:Config.color('brand');
							svg {
								width:35px;
								height:35px;
							}
						}
						svg {
							cursor:pointer;
							transition:0.3s ease width, 0.3s ease height;
							width:25px;
							height:25px;
							&:hover {
								width:35px;
								height:35px;
							}
						}
						@media all and (max-width:1000px){
							&.selected {
								top:calc( -35px / 2 );
								left:calc( -35px / 2 );
								color:Config.color('brand');
								svg {
									width:35px;
									height:35px;
								}
								.card {
									opacity:1;
									pointer-events:all;
								}
							}
						}
						.card {
							background:Config.color('background');
							border-top:3px solid Config.color('sage');
							box-shadow:0 1px 4px rgba(0,0,0,.2);
							position:absolute;
							padding:20px;
							bottom:calc( 100% + 15px );
							transition:0.3s ease opacity;
							opacity:0;
							left:-7px;
							width:300px;
							pointer-events:none;

							@media all and (max-width:400px){
								width:200px;
							}

							&:after {
								content:' ';
								width:0;
								height:0;
								border-left:10px solid transparent;
								border-right:10px solid transparent;
								border-top:10px solid Config.color('background');
								position:absolute;
								left:15px;
								top:100%;
							}

							header {
								display:flex;
								justify-content:space-between;
								padding-bottom:5px;
								h3 {
									font-size:20px;
									font-style:normal;
									font-weight:400;
									letter-spacing:0.35px;
									line-height:133.333%;
									margin:0;
								}
								span {
									display:flex;
									align-items:center;
									svg {
										width:10px;
										transition:0.3s ease width;
										cursor:auto;
									}
									span {
										padding-left:5px;
										font-weight:bold;
										font-size:10px;
										transition:0.3s ease font-size;
									}
								}
							}

							p {
								svg {
									width:10px;
									height:10px;
									padding-right:5px;
									path {
										fill: currentcolor;
									}
								}
								margin:5px 0 10px;
								font-size:13px;
								a {
									color:Config.color('text');
								}
							}
						}
					}
				}


				@media all and (max-width:1000px){
					flex-direction:column;
					.directory {
						width:100%;
						max-width:100%;
						height:auto;
						header {
							form {
								display:flex;
								.row {
									max-width:150px;
									padding:0;
								}
							}
						}
						> div {
							display:none;
						}
					}
				}

				@media all and (max-width:450px){
					.directory {
						header {
							form {
								flex-direction:column;
								gap:10px;
								.row {
									max-width:100%;
								}
							}
						}
					}
				}
			}
		}

		#retailers {
			max-width:800px;

			header {
				padding-top:75px;
			}

			h3 {
				font-size:34px;
				letter-spacing:-0.34px;
				line-height:43px;
				font-weight:normal;
			}

			.list {
				display:flex;
				flex-wrap:wrap;

				&[data-list-size="1"] address {
					width:100%;
				}

				&[data-list-size="2"] address {
					width:50%;
				}

				address {
					font-size:18px;
					letter-spacing:0.49px;
					line-height:30px;
					font-weight:300;
					font-style:normal;
					width:33%;
					margin-bottom:40px;
					padding:20px;
					box-sizing:border-box;

					@media all and (max-width:700px){
						width:50% !important;
					}

					@media all and (max-width:450px){
						width:100% !important;
						margin-bottom:10px;
					}

					h4 {
						margin:0;
						a {
							font-weight:400;
							font-family:Config.font('primary');
							text-decoration:none;
							border-bottom:1px solid Config.color('text');
							line-height:normal;
							display:inline-block;
							white-space:nowrap;
							overflow:hidden;
							text-overflow:ellipsis;
							max-width:100%;
						}
					}

					[itemprop="telephone"] a {
						color:Config.color('text');
						text-decoration:none;
					}

					span {
						display:block;
					}

					span.tag {
						font-size:9px;
						padding:5px 10px;
						text-transform:uppercase;
						display:inline-block;
						font-weight:400;
						line-height:normal;
						letter-spacing:0.1em;
						margin-bottom:5px;

						&.new {
							background:#CCC;
						}
					}
				}
			}

			#preloader {
				width:100%;

				.icon-preload-full-color {
					stroke:#000;
					width:200px;
					height:200px;
					margin:0 auto;
					overflow:visible;
					display:block;
				    position:relative;
				    left:50px;

					circle {
						margin:0 auto;

						cx:46px;

						&:first-child {
							cx:26px;
						}

						&:last-child {
							cx:66px;
						}
					}
				}
			}
		}


		#recommend {
			max-width:800px;

			header {
				padding:50px 0;

				@media all and (max-width:450px){
					padding:50px 0 20px;
				}
			}

			form {
				p {
					margin:0 0 15px;
					line-height:normal;
				}
			}

		}
	}
