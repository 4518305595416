
	/**
	*
	* 		Page Loader classes
	*			-
	*
	**/
	@use '../_config' as Config;
	
	#pageloader {
		z-index:1599;
		background:none;
		position:fixed;
		mix-blend-mode:difference;
		display:none;
		svg {
			path {
				fill:#FFF;
			}
		}
	}

	.body {
		transition:0.3s ease filter;
	}

	body[data-page-state="loading"] {
		#pageloader {
			display:block;
		}
		.body {
			filter: blur(6px);
		}
	}

	#nprogress {
		pointer-events:none;

		.bar {
			background:Config.color('brand');
			position:fixed;
			z-index:1600;
			top:0;
			left:0;
			width:100%;
			height:3px;
		}

		.peg {
			display:block;
			position:absolute;
			right:0;
			width:100px;
			height:100%;
			opacity:1;
			-webkit-transform:rotate(3deg) translate(0px,-4px);
			-ms-transform:rotate(3deg) translate(0px,-4px);
			transform:rotate(3deg) translate(0px,-4px)
		}

		.spinner {
			display:block;
			position:fixed;
			z-index:1600;
			top:15px;
			right:15px;
		}

		.spinner-icon {
			width:18px;
			height:18px;
			box-sizing:border-box;
			border:2px solid transparent;
			border-top-color:Config.color('brand');
			border-left-color:Config.color('brand');
			border-radius:50%;
			-webkit-animation:nprogress-spinner 400ms linear infinite;
			animation:nprogress-spinner 400ms linear infinite
		}
	}

	.nprogress-custom-parent {
		overflow:hidden;
		position:relative;
		#nprogress {
			.bar,
			.spinner {
				position:absolute;
			}
		}
	}

	@-webkit-keyframes nprogress-spinner {
		0% {
			-webkit-transform:rotate(0deg)
		}
		100% {
			-webkit-transform:rotate(360deg)
		}
	}

	@keyframes nprogress-spinner {
		0% {
			transform:rotate(0deg)
		}
		100% {
			transform:rotate(360deg)
		}
	}

