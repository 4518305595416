
	/**
	*
	* 		Content Block
	* 			- Styles for the left/right content block + photo
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	//Content Block
	.block {
		max-width:1200px;

		&.left {
			flex-direction:row-reverse;

			//If Content is Last
			.content {
				padding-left:222px;
				margin:auto 0 auto auto;
			}

			//If Image is Last
			.image {
				left:0;
			}
		}

		&.right {

			//If Content is First
			.content {
				padding-right:222px;
			}

			//If Image is First
			.image {
				right:0;
			}

		}

		> div {
			position:relative;
			display:flex;
		}

		.content {
			background:color.adjust( Config.color('sage') , $lightness:23% );
			width:62%;
			padding:65px;
			max-width:431px;
			min-height:358px;
			display:flex;
			div {
				margin:auto;
				h2 {
					font-size:clamp(34px, 4.5vw, 42px);
					font-style:normal;
					font-weight:400;
					letter-spacing:clamp(-0.34px, 4.5vw, 0px );
					line-height:clamp(117.647%, 4.5vw, 119.048%);
					margin:0 0 20px 0;
				}
				p {
					font-size:16px;
					font-weight:400;
					line-height:150%;
					margin:0 0 20px 0;
				}
				.buttons {
					padding-top:15px;
				}
			}
		}
		.image {
			width:52%;
			padding:46px 0;
			position:absolute;
			z-index:1;
			top:0;
			img {
				object-fit:cover;
				display:block;
			}
		}

		@media all and (max-width:1099px){
			&.right > div {
				flex-direction:row-reverse;
			}
			.content {
				transform:none !important;
				min-height:auto;
				box-sizing:border-box;
				padding:65px !important;
				max-width:100%;
				width:50%;
			}
			.creative {
				transform:none !important;
				&.image {
					max-width:100%;
					width:50%;
					height:auto;
					position:static;
					padding:0;
					flex-grow:1;
					img {
						width:100%;
						height:100%;
					}
				}
			}
		}

		@media all and (max-width:800px){
			margin:0;
			padding:0;
			> div {
				flex-direction:column !important;
				.content {
					width:100%;
					padding:40px clamp( var(--container-padding), 4.5vw, 40px ) !important;
				}
				.creative {
					&.image {
						width:100%;
						overflow:hidden;
						max-height:295px;
						img {
							min-height:calc( 295px + ( 295px * .3 ) );
						}
					}
				}
			}
		}

	}