
	/**
	*
	* 		404
	*			- 404 Page Styles should go here
	*
	**/
	@use '../_config' as Config;
	
	#pcollections {

		#subnav + section#filters {
			margin-top:25px;
		}


		.collection {
			position:relative;

			.none {
				max-width:500px;
				margin:0 auto;
				grid-column:1/-1;
				width:100%;
				text-align:left;
				padding:100px 0;
				text-align:center;

				h2 {
					font-family:Config.font('headline');
					font-weight:normal;
					font-size:32px;
					text-align:center;
					line-height:28px;
					display:inline-block;
					margin:0;
				}

				a {
					color:Config.color('brand');
					text-decoration:underline;
				}

				@media all and (max-width:450px){
					font-size:16px;
					letter-spacing:0.56px;
					line-height:28px;
				}

			}

			.advertisement {
				grid-row:1;
				grid-column:-2;
				img {
					width:100%;
					height:auto;
					object-fit:cover;
					display:block;
					background:linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.1) 100%);
				}
				.details {
					padding:20px 0;
					display:none;
					flex-direction:column;
					justify-content:space-between;

					h3 {
						font-weight:400;
						font-size:clamp(20px, 4.7vw, 24px);
						line-height:100%;
						letter-spacing:0;
						display:block;
						white-space:nowrap;
						overflow:hidden;
						text-overflow:ellipsis;
						max-width:100%;
						margin:0;
						padding:0;
						@media all and (max-width:400px){
							line-height:normal;
						}
					}

					.inci {
						font-size:10px;
						text-align:left;
						text-transform:lowercase;
						letter-spacing:0.25px;
						line-height:100%;
						margin:0;
						font-weight:100;
						padding:5px 0 0;
					}
					p {
						margin:0;
						line-height:150%;
						padding:10px 0;
						font-size:13px;
					}
				}
				@media all and (max-width:600px){
					.details {
						display:flex;
					}
				}
			}

			.divider {
				width:100%;
				grid-column:1 / -1;
				text-align:center;
				padding-top:20px;

				h2 {
					margin:auto 0;
					font-weight:normal;
					font-style:italic;
					text-align:center;
					font-size:40px;
					letter-spacing:0.49px;
					line-height:43px;
					align-self:center;
					+ p {
						font-weight:400;
						font-size:16px;
						line-height:30px;
						letter-spacing:.49px;
						font-style:italic;
						margin:0 auto 30px;
						max-width:750px;
					}
				}
			}
		}

	}
