
	/**
	*
	* 		Modal
	*			- A Modal Function
	*
	**/

	//Quick Card, Quick-Add Modal
	#modal form#review {
		header {
			padding-bottom:30px;
			h2 {
				margin:0;
				font-size:clamp(32px, 4.7vw, 56px);
				line-height:120%;
				letter-spacing:0;
				font-weight:400;
				line-height:114.286%;
				letter-spacing:-0.56px;
			}
			p {
				margin:0;
				font-size:clamp(14px, 4.7vw, 16px);
				font-style:normal;
				font-weight:400;
				line-height:150%;
			}
		}


		> p {
			margin:0;
		}

		.rating {
			padding-bottom:0;
			display:block;
			svg {
				width:100px;
				height:25px;
				path {
					cursor:pointer;
					transition:0.3s ease fill, 0.3s ease stroke;
				}
			}
		}

	}