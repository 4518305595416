
	/**
	*
	* 		Account - Points Page
	*			- Styles for the "Points" page
	*
	**/
	@use '../_config' as Config;

	#ppoints {
		main .content {

			h2 {
				font-style:italic;
				+ p {

				}
			}

			.list {

				display:flex;
				justify-content:space-between;
				flex-wrap:wrap;
				gap:7px;

				> div {
					width:calc( 33.33% - 5px);
					padding:30px 30px 20px;
					box-sizing:border-box;
					border:1px dashed Config.color('sage');
					display:flex;
					flex-direction:column;


					@media all and (max-width:650px){
						width:100%;
					}

					.icon {
						position:relative;
						height:73px;
						width:auto;
						margin:10px auto;
						width:100%;
						img {
							margin:0 auto;
							display:block;
							align-self:center;
							filter: brightness(0) saturate(100%) invert(26%) sepia(28%) saturate(550%) hue-rotate(22deg) brightness(102%) contrast(85%);
						}
					}

					.button {
						width:100%;
						box-sizing:border-box;
					}

					h4 {
						font-family:Config.font('primary');
						text-transform:uppercase;
						font-weight:500;
						font-size:12px;
						letter-spacing:0.34px;
						text-align:center;
					}

					> .modal {
						display:none;
					}

					&.points-full {
						width:calc( 66.66% - 3px);
						display:flex;
						justify-content:space-between;
						flex-direction:row;
						gap:20px;

						> div:not(.modal) {
							width:48%;
							display:flex;
							flex-direction:column;
							.button {
								margin: auto auto 0;
							}
						}
						.image {
							object-fit:cover;
							height:100%;
							display:block;
						}

						@media all and (max-width:1350px){
							.image {
								width:50%;
							}
						}

						@media all and (max-width:650px){
							width:100%;
						}

						@media all and (max-width:400px){
							> div:not(.modal) {
								width:100%;
							}
							.image {
								display:none;
							}
						}

					}


					&.completed {
						opacity:.5;

						button > span {
							display:none;

							&.finished {
								display:block;
							}
						}
					}

					button:hover,
					&.completed button {
						transition:all .25s ease;
						background:#3A3939;
						color:#FFF;
					}

				}

			}
		}

		.reward-flex {
			display:flex;
			align-items:center;
			justify-content:center;
			gap:20px;
			img {
				filter: brightness(0) saturate(100%) invert(26%) sepia(28%) saturate(550%) hue-rotate(22deg) brightness(102%) contrast(85%);
			}
			h2 {
				font-size:30px;
				margin:0;
				font-style:italic;
				text-transform:lowercase;
			}
			p {
				margin:5px 0 0;
				text-transform:lowercase;
			}
		}

	}
