
	/**
	*
	* 		Toggle View
	*			-
	*
	**/
	@use '../_config' as Config;

	//Review Component
	.toggle {
		background:Config.color('sage');
		width:25px;
		height:12px;
		border-radius:25px;
		padding:2px;
		cursor:pointer;
		display:flex;
		transition:0.3s ease background;
		div {
			width:50%;
			height:100%;
			background:Config.color('background');
			border-radius:25px;
			position:relative;
			left:0;
			transition:0.3s ease left;
		}
		&.active {
			background:Config.color('brand');
			div {
				left:50%;
			}
		}
	}