
	/**
	*
	* 		GENERAL
	*			- General supporting Styles
	*
	**/
	@use '../_config' as Config;

	body {
		margin:0;
		min-width: 320px;
		font-weight: 400;
		line-height: 150%;
		font-family: Config.font('primary');
		color: Config.color('text');
		background: Config.color('background');
		overflow-x: hidden;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	}
		
	.body {
		padding-top:95px;
		min-width:320px;
		@media all and (max-width:450px){
			padding-top:75px;
		}
	}

	::selection {
		background:Config.color('sage');
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family:Config.font('headline');
		text-transform:lowercase;
		letter-spacing:-0.52px;
		leading-trim:both;
		text-edge:cap;
		/*
		em {
			letter-spacing:-0.34px;
		}
		*/
	}

	a {
		color:Config.color('text');
		cursor:pointer;
		&[disabled] {
			cursor:not-allowed;
		}
	}

	@each $key, $value in Config.$colors {
		.bg#{$key} {
			background: $value;
		}
		.color#{$key} {
			color: $value;
		}
	}