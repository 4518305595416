
	/**
	*
	* 		Product Specifications
	*			-
	*
	**/
	@use '../_config' as Config;
	
	#subscribe {


		&.hidden {
			form {
				right:-400px !important;
			}
			.offer {
				bottom:-50px !important;
			}
		}

		&.open {
			form {
				right:0;
			}
			.offer {
				bottom:-50px;
			}
		}

		.offer {
			position:fixed;
			z-index:9999999;
			bottom:0;
			left:20px;
			transition:0.3s ease bottom;
		}

		form {
			position:fixed;
			bottom:0;
			z-index:9999999;
			right:-400px;
			margin:0 20px 20px;
			box-shadow:rgba(0,0,0,.15) 0 0 30px;
			box-sizing:border-box;
			padding:30px 20px 20px;
			color:Config.color('text');
			background:Config.color('background');
			max-width:350px;
			transition:0.5s ease right;

			.close {
				position:absolute;
				top:0;
				right:0;
				width:25px;
				height:25px;
				border-radius:50%;
				margin-right:8px;
				margin-top:8px;
			}

			header {

				h3 {
					font-family:Config.font('headline');
					text-transform:uppercase;
					text-align:center;
					font-size:40px;
					font-weight:normal;
					line-height:100%;
					letter-spacing:1px;
					margin:0;
					span {
						padding:8px 0;
						display:block;
					}
					small {
						font-size:11px;
						font-family:Config.font('primary');
						display:block;
						line-height:100%;
						letter-spacing:1px;
						font-weight:400;
					}
				}

				p {
					font-size:12px;
					font-weight:400;
					line-height:normal;
					letter-spacing:1px;
					padding:8px 0 0;
				}
			}


			[type="submit"] {
				width:100%;
				display:block;
				margin-top:12px;
			}
		}



		&.completed {
			form {
				header {
					padding:45px 0;
					h3 {
						font-size:25px;
						letter-spacing:0;
						text-transform:none;
					}
					p {
						text-align:center;
						line-height:160%;
						font-size:13px;
						letter-spacing:0;
					}
				}
			}
		}

	}