
	/**
	*
	* 		Account - Stores Page
	*			- Styles for the "Stores" page
	*
	**/
	@use '../_config' as Config;

	#pstores {

		#store-profile-container {
			position:relative;

			header {

				display:block;
				padding-bottom:15px;
				border-bottom:1px dashed Config.color('sage');
				margin-bottom:30px;

				h2 {
					text-transform:none;
					font-weight:normal;
					font-style:italic;
					text-transform:lowercase;
					font-weight:600;
					margin:0;
					padding:0;
					+ p {
						margin-bottom:0;
					}
				}

				p.instructions {
					strong {
						font-size:14px;
						font-weight:600;
						margin-bottom:0;
					}
				}

			}

			h3 {
				font-weight:normal;
				text-align:left;
				text-transform:lowercase;
				font-style:italic;
				font-size:24px;
				margin:0;
				margin-bottom:30px;
			}

			h4 {
				font-weight:normal;
				text-align:left;
				font-size:14px;
				letter-spacing:1.2px;
				text-transform:uppercase;
				font-family:Config.font('primary');
				margin:0;
				margin-bottom:30px;
			}

			.empty {
				text-align:center;
				.buttons {
					padding-top:30px;
					justify-content:center;
				}
			}

			.errors {
				background:rgba(255,0,0,.1);
				color:#000;
				padding:30px;
				margin:5px 0 40px;
				display:none;
				ul {
					margin:0;
					padding:0;
					list-style:none;
					font-size:13px;
					li {
						padding-bottom:10px;
						color:#500;
						padding-bottom:10px;
						font-size:15px;
						font-style:italic;
					}
				}
			}

			.header {
				display:flex;
				justify-content:space-between;
				vertical-align:middle;
				padding-bottom:20px;
				h3 {
					margin-bottom:0;
					line-height:normal;
				}
			}

			.form {

				padding:30px;
				background:#F9F9F9;

				&.break {
					margin:0;
					padding:0 30px 30px;
				}

				.entry {
					margin:0 0 30px;
				}

				input,
				textarea,
				select {
					margin:5px 0;
					font-size:14px;
					font-family:Montserrat;
					padding:15px 10px;
					box-sizing:border-box;
					width:100%;
					outline:none;

					&:not([type="submit"]){
						border:0.5px solid #979797;
					}

					&::placeholder {
						color:#979797;
						font-style:italic;
					}
				}

				p.details {
					font-size:12px;
					margin:0;

					code {
						background:#EFEFEF;
						border:1px solid #EEE;
						margin-top:5px;
						padding:0 5px;
						font-size:80%;
						display:inline-block;
					}
				}


			}

			.buttons {
				display:flex;
				justify-content:space-between;

				.btn {
					font-size:14px;
					margin:5px 0;
					padding:0;
					height:50px;
					transition:all 0.2s ease;
					overflow:hidden;
					span {
						padding:15px 10px;
						width:100%;
						height:100%;
						display:block;
						position:relative;
						+ span {
							color:#090;
						}
					}
					&.saved {
						border:1px solid #090;
						span {
							top:-100%;
						}
						&:hover {
							background:#090;
							span + span {
								color:#FFF;
							}
						}
					}
				}
			}

		}
	}
