
	/**
	*
	* 		Loading
	*			- Covers up the section with a loading icon
	*
	**/
	@use '../_config' as Config;

	//Loading Module
	.preloader {
		background:Config.color('background');
		position:absolute;
		z-index:2;
		top:0;
		left:0;
		width:100%;
		height:100%;
		overflow:hidden;
		.icon {
			width:100%;
			height:100%;
			padding:50px 0;
			box-sizing:border-box;
			display:flex;
			mix-blend-mode:difference;
			svg {
				transform: rotateY(45deg);
				animation:preloader linear 1.5s infinite;
				margin:auto;
				display:block;
			}
		}
		&.sticky {
			.icon {
				svg {
					margin:0 auto;
				}
			}
		}
		&.overlay {
			background:rgba( Config.color('background') , 0.7 )
		}
	}

	.preloader-mini {
		width:12px;
		height:12px;
	}

	@keyframes preloader {
		from { transform: rotateY(0); }
		to { transform: rotateY(360deg); }
	}