
	/**
	*
	* 		Header
	*			- The header styles will go hehre
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	header#header {
		position:fixed;
	  	z-index:9;
	  	width:100%;
	  	top:0;
	  	background:#FFF;
	  	transition:0.5s ease all;
	  	box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.10);


	  	&.scroll:not(&.open),
	  	&.locked {
	  		top:-112px;
	  	}

		.logo {
		  	display: block;
		  	position:relative;
		  	margin:0.67em 0;
		  	font-size:2em;
		  	z-index:1;

		  	img {
		    	display: block;
		    	width: 100%;
		  	}
		  	@media all and (max-width:450px){
		  		svg {
		  			height:18px;
		  			width:auto;
		  		}
		  	}
		}


	  	.shipping {
	  		background:#EFEFEF;
	  		width:100%;
	  		weight:500;
	  		text-align:center;
	  		line-height:normal;
	  		font-size:9px;
	  		text-transform:uppercase;
	  		padding:10px 0;
	  		letter-spacing:1.08px;
	  		position:relative;
	  		z-index:3;

	  		.bar-offer {
	  			padding:10px 0 0;
	  			display:block;
	  		}
	  	}

	  	#notifications {
	  		background:Config.color('offset');
	  		color:Config.color('text');
	  		width:100%;
	  		font-weight:500;
	  		text-align:center;
	  		line-height:140%;
	  		font-size:10px;
	  		text-transform:uppercase;
	  		padding:10px 0;
	  		letter-spacing:0.5px;
	  		position:relative;
	  		z-index:3;

	  		i {
	  			padding:0 3px;
	  			color:Config.color('brand');
	  		}

	  		span {
	  			position:absolute;
	  			transition:0.3s ease opacity;
	  			width:100%;
	  			left:0;
	  			opacity:0;
	  			&.active {
	  				transition:1s ease opacity;
	  				position:static;
	  				opacity:1;
	  			}
	  		}

	  		.bar-offer {
	  			padding:10px 0 0;
	  			display:block;
	  		}
	  	}

	  	#navigation {
	  		position:relative;
	  		z-index:2;
	  		background:#FFF;


	  		@media all and (min-width:1201px){
			  	[data-viewport="mobile"] {
			  		display:none !important;
				}
			}

	  		@media all and (max-width:1200px){
	  			padding:18px 0;
	  			[data-viewport="desktop"] {
	  				display:none !important;
	  			}
	  		}

		  	@media all and (max-width:450px){
		  		padding:9px 0;
		  	}

		  	.container {
		  		display:flex;
		  		flex-direction:row;

			  	nav {
			  		margin:auto 0 auto auto;
		  			letter-spacing:0.5px;
		  			font-size:14px;
		  			font-weight:400;
		  			display:flex;
		  			flex-direction:row;
		  			width:100%;

				  	.logo {
				  		max-width:150px;
				  		height:100%;
				  		width:100%;
				  		margin:auto auto auto 0;
				  	}

			  		a {
			  			color:Config.color('text');
			  			text-decoration:none;
			  			margin:auto;
			  			align-self:center;
			  		}

			  		.primary {
			  			display:flex;
			  			margin:auto;
			  			gap:25px;
			  			> div {
			  				padding:19px 0;
			  				&:not(.dropdown) a {
			  					transition:0.3s ease color;
			  					&:hover {
			  						color:Config.color('sage');
			  					}
			  				}
			  				> a {
			  					min-height:27px;
			  					display:flex;
			  					align-items:center;
			  					margin:auto;
			  					text-align:center;
			  					line-height:120%;
			  				}
			  			}
					}

				  	.secondary {
				  		display:flex;
				  		gap:10px;
				  		margin:auto 0 auto auto;

				  		> *:not(:last-child){
				  			padding:0 10px;
				  		}

				  		.dropdown {
				  			padding:22px 0;
				  			a.login {
					  			padding:0 10px;
							}
				  		}

					  	.cart {
					  		margin:auto;
					  		display:flex;
					  		align-items:center;
					  		cursor:pointer;
					  		svg {
					  			transition:0.3s ease color;
					  			display:inline-block;
					  			line-height:100%;
					  			width:16px;
					  			height:16px;
					  		}
					  		.count {
					  			display:flex;
					  			justify-content:center;
					  			transition:0.3s ease color;
					  			padding-left:3px;
					  			font-size:10px;
					  			font-style:normal;
					  			font-weight:500;
					  			line-height:140%;
					  			letter-spacing:0.5px;
					  			svg {
					  				max-height:13px;
					  			}
					  		}
					  		&:hover {
					  			color:Config.color('sage');
					  		}
					  	}

					  	.search-btn {
					  		margin:auto;
					  	}

					  	.login {
					  		display:flex;
					  		align-items:center;
					  		cursor:pointer;
					  		text-align:center;
					  		svg {
					  			width:18px;
					  			height:18px;
					  			vertical-align:top;
					  		}
					  		.count {
					  			display:flex;
					  			justify-content:center;
					  			transition:0.3s ease color;
					  			padding-left:3px;
					  			font-size:10px;
					  			font-style:normal;
					  			font-weight:500;
					  			line-height:140%;
					  			letter-spacing:0.5px;
					  			svg {
					  				max-height:13px;
					  			}
					  		}
					  		&:hover {
					  			color:Config.color('sage');
					  		}
					  	}

					  	.search {
					  		display:flex;
					  		align-items:center;
					  		cursor:pointer;
					  		transition:0.3s ease color;
						  	.icon-search {
						  		width:17px;
						  		height:18px;
						  		circle,
						  		line {
						  			stroke:currentColor;
						  		}
						  	}
						  	&:hover {
						  		color:Config.color('sage');
						  	}
						  	@media all and (max-width:400px){
						  		display:none !important
						  	}
						}

					  	.language {
					  		display:flex;
					  		flex-direction:row;

					  		svg {
					  			margin:auto;
					  		}

					  		button {
						  		font-size:10px;
						  		line-height:140%;
						  		letter-spacing:0.6px;
						  		text-align:right;
						  		font-weight:500;
						  		color:Config.color('text');
						  		background:none;
						  		border:none;
						  		padding:0;
						  		text-transform:uppercase;
						  		cursor:pointer;
						  		&:hover:not(.selected) {
						  			color:Config.color('sage');
						  		}
						  		&.selected {
						  			font-weight:700;
						  		}
					  		}

					  		hr {
					  			width:1px;
					  			height:13px;
					  			background:#3A3939;
					  			border:none;
					  			margin:auto 7px;
					  		}

					  	}

					}

				  	[data-mobile-menu] {
				  		height:12px;
				  		width:21px;
				  		position:relative;
				  		cursor:pointer;
				  		background:none;
				  		border:none;
				  		align-self:center;
				  		span {
				  			height:1px;
				  			background:#444;
				  			width:100%;
				  			position:absolute;
				  			top:0;
				  			left:0;

				  			+ span {
				  				top:5px;

				  				+ span {
				  					top:10px;
				  				}
				  			}
				  		}
				  	}
			  	}
			  	.dropdown {
		  			margin:auto;
		  			align-self:center;
		  			//padding:19px 0;

		  			> a {
		  				position:relative;
		  			}

		  			&:hover {
	  					.menu {
	  						//display:block;
	  						display:flex;
	  					}
	  					> a:after {
	  						background:Config.color('text');
	  						content:' ';
	  						position:absolute;
	  						bottom:-20px;
	  						z-index:1;
	  						left:0;
	  						width:100%;
	  						height:2px;
	  						display:block;
	  					}
	  				}

			  		.menu {
			  			display:flex;
		  				//padding:50px 0;
	  					align-items:stretch;
	  					flex-direction:row-reverse;
			  			background:#FFF;
			  			position:absolute;
			  			z-index:0;
			  			left:0;
			  			top:100%;
			  			width:100%;
		  				display:none;
		  				border-top:2px solid Config.color('offset');
		  				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

		  				.container {
		  					justify-content:center;
		  					align-items:center;
		  					position:relative;
		  					margin:0 auto 0 0;
		  					z-index:1;
		  				}

			  			.image {
			  				position:relative;
			  				width:100%;
			  				max-width:41%;
			  				max-height:250px;
			  				left:0;
			  				top:0;
			  				img {
			  					object-fit:cover;
			  					position:relative;
			  					display:block;
			  					width:100%;
			  					height:100%;
			  				}
			  				@media all and (max-width:2000px){
			  					max-width:34%;
			  				}
			  				@media all and (max-width:1400px){
			  					max-width:28%;
			  				}
			  				@media all and (max-width:1200px){
			  					display:none;
			  				}
			  			}

			  			.description {
			  				max-width:400px;
		  					min-height:250px;
			  				padding:0 88px 0 40px;
			  				border-right:1px solid #E7E7E7;
			  				background:#FFF;
			  				display:flex;
			  				flex-direction:column;
			  				justify-content:center;
			  				max-width:249px;

			  				h3 {
			  					font-size:24px;
			  					letter-spacing:0.28px;
			  					line-height:133.333%;
			  					font-weight:400;
			  					margin:0;
			  				}

			  				.overview {
			  					margin:0;
			  					font-size:12px;
			  					display:flex;
			  					align-items:center;
			  					strong {
			  						font-style:italic;
			  						font-weight:500;
			  						padding-right:5px;
			  					}
			  					svg {
			  						height:12px;
			  					}
			  				}
			  				.stats {
								display:flex;
								padding:10px 0 0;
								box-sizing:border-box;
								gap:15px;

								div {
									text-transform:uppercase;
									font-size:10px;
									display:flex;
									font-weight:500;

									a {
										text-transform:uppercase;
										font-size:10px;
										font-weight:500;
										font-family:Config.font('primary');
										letter-spacing:0.5px;
									}

									span.total {
										font-family:Config.font('headline');
										font-size:17px;
										font-weight:600;
										letter-spacing:1.44px;
										display:flex;
										align-items:center;
										text-transform:lowercase;
										svg {
											margin:0 5px;
											&.stores {
												margin:0;
											}
										}
									}

									&.trees {
										padding-left:10px;
										span svg {
											height:20px;
											width:auto;
										}
									}

									&.points span svg {
										height:19px;
									}

								}

							}

			  				p {
			  					font-size:14px;
			  					font-weight:400;
			  					letter-spacing:0.79px;
			  					line-height:157.143%;
			  				}
			  			}

			  			.list {
			  				padding-left:88px;
		  					min-height:250px;
			  				display:flex;
			  				flex-direction:column;
			  				justify-content:center;
			  				background:#FFF;

			  				h4 {
			  					font-size:16px;
			  					letter-spacing:0.28px;
			  					line-height:133.333%;
			  					font-weight:600;
			  					padding-bottom:10px;
			  					margin:0;
			  				}
			  				a {
			  					display:inline-block;
			  					color:Config.color('text');
			  					font-weight:400;
			  					font-size:14px;
			  					margin-bottom:15px;
								border-bottom:1px solid transparent;
								cursor:pointer;
								&.logout,
			  					&:hover {
									border-bottom:1px solid Config.color('text');
			  					}
			  				}
			  			}
			  		}
			  	
			  		&.business {
			  			.menu {
			  				.description {
			  					padding-right:50px;
			  				}
			  				.list {
			  					padding-left:50px;
			  				}
			  			}
			  		}
			  	}
			}

		}

	  	#mobile-menu {
	  		position:absolute;
	  		left:0;
	  		width:100%;
	  		display:none;
	  		height:calc( 100vh - 92px );
	  		overflow-y:scroll;
	  		overscroll-behavior:none;
	  		background:#FFF;

	  		@media all and (min-width:1201px){
	  			display:none !important;
	  		}

	  		@media all and (max-width:450px){
	  			height:calc( 100vh - 76px );
	  		}


	  		.container {
	  			background:#FFF;
	  			padding:0 30px 40px;
	  			position:relative;
	  			z-index:1;

			  	.language {
			  		display:flex;

			  		.wrap {
			  			display:flex;
				  		flex-direction:row;
				  		padding:0 0 30px 0;
				  		text-align:right;
				  		margin:0 0 0 auto;

				  		svg {
				  			margin:auto;
				  		}

				  		a {
				  			font-family:Config.font('headline');
					  		font-size:10.5px;
					  		line-height:27px;
					  		letter-spacing:0.4px;
					  		text-align:right;
					  		font-weight:100;
					  		text-decoration:none;
					  		color:Config.color('text');
					  		&.selected {
					  			font-weight:bold;
					  			color:#030303;
					  		}
				  		}

				  		hr {
				  			width:1px;
				  			height:13px;
				  			background:#3A3939;
				  			border:none;
				  			margin:auto 7px;
				  		}

				  	}
			  	}

			  	.dropdowns {
			  		transition:0.5s ease transform;

			  		&.open {
			  			transform:translateX(-100vw);
			  			> * {
			  				&:not(.active){
			  					display:none;
			  				}
				  			&.active {
				  				.subnav {
					  				display:block;
					  			}
				  			}
				  		}
			  		}

			  		.control {
		  				font-family:Config.font('headline');
		  				font-size:24px;
		  				font-style:normal;
		  				font-weight:400;
		  				line-height:133.333%;
		  				display:flex;
		  				justify-content:space-between;
		  				transition:0.3s ease color;
		  				cursor:pointer;
		  				@media all and (min-width:600px){
		  					justify-content:left;
		  					> span {
		  						display:flex;
		  						align-items:center;
		  						&:first-child {
		  							padding-right:10px;
		  						}
			  				}
		  				}
		  				svg {
		  					transition:0.3s ease left;
	  						position:relative;
	  						left:0;
		  					path {
		  						transition:0.3s ease color;
		  					}
		  				}
		  				&:hover {
		  					color:Config.color('sage');
			  				svg {
			  					left:5px;
			  					path {
			  						stroke:Config.color('sage');
			  					}
			  				}
		  				}
			  		}

			  		.link,
			  		.dropdown {
			  			padding-top:25px;
			  			margin:0 auto;
			  			@media all and (max-width:400px){
			  				width:100%;
			  			}
			  		}


		  			.subnav {
		  				display:none;
		  				transform:translateX(100vw);
		  				width:100%;
		  				margin-top:-25px;
		  				.category {
		  					a {
		  						justify-content:left;
		  						span {
		  							line-height:100%;
		  							&:first-child {
		  								padding-right:10px;
		  							}
		  						}
		  					}
		  				}
		  				.list {
		  					padding-top:20px;
		  					display:flex;
		  					flex-direction:column;
		  					gap:15px;
			  				a {
			  					color:#3A3939;
			  					font-weight:400;
			  					line-height:100%;
			  					font-size:14px;
			  					&:hover {
			  						color:Config.color('sage');
			  					}
			  				}
			  				.box {
			  					background-image:linear-gradient(to right,Config.color('sage') 44%,white 0%);
			  					background-position:bottom;
			  					background-size:10px 1px;
			  					background-repeat:repeat-x;
			  					padding:30px 0 45px;
			  					display:flex;
			  					flex-direction:column;
			  					gap:15px;
			  					> span {
									font-size:16px;
									font-style:normal;
									font-weight:400;
									line-height:133.333%;
									margin:0;
									font-family:Config.font('headline');
			  					}
			  					&:last-child {
			  						background:none;
			  						border-bottom:0;
			  					}
			  				}
			  			}
			  			.content {
			  				border-top:1px solid color.adjust( Config.color('sage'), $lightness: 22% );
			  				padding-top:35px;
			  				margin-top:35px;

			  				@media all and (min-width:800px){
			  					display:flex;
			  					gap:50px;
			  				}

			  				.image {
			  					position:relative;
			  					min-height:200px;
			  					width:100%;
			  					img {
				  					object-fit:cover;
								}
			  				}

			  				.description {
			  					padding-top:20px;
								h3 {
									font-size:24px;
									font-style:normal;
									font-weight:400;
									line-height:133.333%;
									margin:0;
									padding-bottom:12px;
								}
								p {
									font-size:14px;
									font-style:normal;
									font-weight:400;
									line-height:157.143%;
								}
							}
			  			}
		  			}

		  		}

		  		.secondary {
		  			padding-top:35px;
		  			margin-top:35px;
		  			border-top:1px solid #F1F1EA;

		  			.stats {
						display:flex;
						padding:0 0 15px 0;
						margin-bottom:35px;
						margin-top:-20px;
						box-sizing:border-box;
						border-bottom:1px solid #F1F1EA;
						justify-content:space-evenly;

						div {
							text-transform:uppercase;
							font-size:13px;
							display:flex;
							align-items:center;
							margin:0 auto;
							font-weight:500;

							a {
								text-transform:uppercase;
								font-size:13px;
								font-weight:500;
								font-family:Config.font('primary');
								letter-spacing:0.5px;
							}

							span.total {
								font-family:Config.font('headline');
								font-size:30px;
								font-weight:600;
								letter-spacing:1.44px;
								display:flex;
								align-items:center;
								text-transform:lowercase;
								height:30px;
								svg {
									margin:0 5px;
									&.stores {
										margin:0;
									}
								}
							}

							&.trees {
								padding-left:10px;
								span svg {
									height:28px;
								}
							}

							&.points span svg {
								height:19px;
							}

						}

					}

		  			> a {
		  				display:flex;
		  				align-items:center;
		  				font-size:14px;
		  				font-weight:400;
		  				line-height:157.143%;
		  				margin-bottom:24px;
		  				transition:0.3s ease color;
		  				> span {
		  					display:flex;
			  				svg {
			  					padding-right:10px;
			  				}
			  			}
			  			&:hover {
			  				color:Config.color('sage');
			  			}
		  			}

		  			.login {
		  				svg {
		  					width:16px;
		  					height:18px;
		  				}
		  				&, svg {
		  					transition:0.3s ease color;
		  				}
		  				&:hover {
			  				color:Config.color('sage');
		  				}
		  			}

		  			.search {
		  				svg {
		  					width:17px;
		  					height:18px;
		  					transition:0.3s ease color;
		  				}
		  				&:hover {
			  				color:Config.color('sage');
		  				}
		  			}

		  			.language {
		  				font-size:14px;
		  				color:Config.color('text');
		  				select {
		  					border:none;
		  					color:Config.color('text');
		  					background-image:url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
		  					background-position: right 0.7rem top 50%;
		  					background-size:0.5rem auto;
		  					background-repeat:no-repeat;
		  					transition:0.3s ease color;
		  					cursor:pointer;
		  					appearance:none;
		  					padding:0 25px 0 10px;
		  					border:1px solid Config.color('text');
		  					margin-left:10px;
		  					border-radius:0;
		  					font-size:70%;
		  					letter-spacing:0.34px;
		  					&:hover {
		  						color:Config.color('sage');
		  					}
		  				}
		  			}
		  		}
		  	}
	  	}



		&.open {
			#navigation {
				.container {
					nav {
					  	[data-mobile-menu] {
				  			span {
				  				transform: rotate(45deg);
				  				top:5px;
				  				+ span {
				  					transform: rotate(-45deg);
				  					top:5px;
				  					+ span {
				  						display:none;
				  					}
				  				}
				  			}
				  		}
				  	}
			  	}
			}
			#mobile-menu {
				display:block;
			}
		}

	}

	[lang="fr"] {
		header#header {
			.primary {
				padding:0 !important;
				> div {
					> a {
						font-size:13px;
						@media all and (max-width:1400px){
							&:after {
								bottom:-19px !important;
							}
						}
					}
				}
			}
		}
	}