
	/**
	*
	* 		Safety Page
	*			- Styles for "Safety" Page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	#psafety {

		//Main Content
		main {

			section {
				padding:50px 0 75px;

				&:last-of-type {
					padding-bottom:100px;
				}

				&#introduction {
					padding-top:50px;
				}

				.container {
					max-width:800px;

					.content {
						font-size:16px;
						letter-spacing:0.25px;
						line-height:200%;

						a:not(.button) {
							text-decoration:underline;
						}

						h1 {
							font-weight:normal;
							text-align:center;
							font-size:40px;
							line-height:53px;
							font-style:italic;
							span {
								display:block;
								font-size:70%;
								font-style:normal;
							}
						}

						h2 {
							font-weight:normal;
							font-size:32px;
							line-height:150%;
							span {
								display:block;
								font-size:10px;
								font-style:italic;
								letter-spacing:0.5px;
								line-height:normal;
								font-family:Config.font('primary');
							}
							@media all and (max-width:755px){
								font-size:28px;
								letter-spacing:0.4px;
							}
						}

						h3 {
							font-family:Config.font('primary');
							text-transform:uppercase;
							font-weight:500;
							font-size:18px;
							line-height:120%;
							letter-spacing:0.49px;
							padding-top:50px;
							@media all and (max-width:755px){
								font-size:17px;
							}
						}


						ul {
							padding-top:20px;
							li {
								list-style:disc;
								padding-bottom:20px;
							}
						}

					}

				}

				+ section {
					border-top:1px solid Config.color('sage');
				}

				footer {
					padding-top:75px;
				}
			}
		}

		//Subnav
		#quicklink {
			padding:60px 0;
			background:color.adjust( Config.color('sage'), $lightness:24% );

			header {
				text-align:center;

				h3 {
					font-style:italic;
					font-weight:normal;
					font-size:32px;
					letter-spacing:0.49px;
					line-height:150%;
					margin:0;
					@media all and (max-width:755px){
						font-size:28px;
						letter-spacing:0.4px;
					}
				}


				p {
					font-weight:500;
					font-size:14px;
					letter-spacing:0.79px;
					line-height:150%;
				}

			}

			ul {
				margin-top:30px;
				list-style:none;
				padding:20px 0 0;
				display:flex;
				flex-wrap:wrap;
				justify-content:center;
				max-width:800px;
				margin:30px auto 0;
				gap:20px;
				li {

				}
			}
		}


		[data-viewport],
		.caption {
			i {
				margin-right:10px;
				min-width:25px;
				min-height:25px;
				background:Config.color('text');
				color:#FFF;
				display:flex;
				font-size:12px;
				border-radius:100%;
				span {
					margin:auto;
					font-weight:600;
				}
			}
		}

		//Caption
		.caption {
			text-align:left;
			ul {
				padding:0;
				li {
					font-weight:600;
					font-size:12px;
					text-transform:uppercase;
					line-height:17px;
					letter-spacing:0.36px;
					padding-bottom:15px;
					font-style:normal;


					display:flex;
					align-items:center;
					justify-content:left;
				}
			}
		}


		//General Table Styles
		table[data-viewport="desktop"] {
			width:100%;
			padding:50px 0;

			@media all and (max-width:850px){
				display:none;
			}


			.icon {
				position:relative;
				display:inline-block;
				cursor:pointer;
				margin-left:6px;
				&:hover {
					.box {
						display:block;
					}
				}
				.box {
					display:none;
					position:absolute;
					background:#FFF;
					border-radius:2px;
					padding:16px;
					text-align:center;
					filter:drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.14));
					font-size:12px;
					font-weight:500;
					line-height:150%;
					width:250px;
					left:calc( ( 50% - (250px / 2 ) ) );
					bottom:100%;
					text-align:center;
					box-sizing:border-box;
					&:after {
						width:0;
						height:0;
						border-left:10px solid transparent;
						border-right:10px solid transparent;
						border-top:10px solid #FFF;
						content:' ';
						position:absolute;
						bottom:-10px;
						left:calc( 50% - 10px );
					}
				}
			}

			thead {
				tr {
					th {
						border-bottom:1.5px solid Config.color('brand');
						padding:9px 0;
						&:first-child {
							text-align:left;
							div {
								display:block;
							}
						}
						div {
							font-size:15px;
							font-weight:600;
							line-height:160%;
							display:flex;
							justify-content:center;
							align-items:center;
						}
					}
				}
			}

			tbody,
			tfoot {
				tr {
					&:first-child {
						padding-top:17px;
					}
					td {
						border-bottom:1px solid #E6E6E6;
						padding:13px 0;
						text-align:center;
						&,a {
							font-size:16px;
							font-style:normal;
							font-weight:500;
							line-height:150%;
						}
						a {
							text-decoration:none;
							transition:0.3s ease color;
							&:hover {
								//color:#000;
							}
						}

						.icon {
							margin:0;
							i {
								margin:auto;
							}
						}

						&:first-child {
							text-align:left;
						}

						&:nth-child(even){
							background:#F6F6F6;
						}

					}
				}
			}
		}


		div[data-viewport="mobile"] {
			padding:50px 0;

			@media all and (min-width:851px){
				display:none;
			}

			h3 {
				font-family:Config.font('primary');
				font-size:15px;
				font-style:normal;
				font-weight:600;
				line-height:160%;
				margin:0;
				padding-bottom:15px;
			}

			.icon {
				display:inline-block;
				cursor:pointer;
				margin-left:6px;
				&.open {
					.dialog {
						display:flex;
					}
				}
				.dialog {
					display:none;
					position:fixed;
					top:0;
					left:0;
					width:100vw;
					height:100vh;
					background:rgba(0,0,0,.6);
					z-index:6;

					.box {
						margin:auto;
						background:#FFF;
						border-radius:2px;
						padding:16px;
						text-align:center;
						filter:drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.14));
						font-size:12px;
						font-weight:500;
						line-height:150%;
						text-align:center;
						width:250px;
						left:calc( ( 50% - (250px / 2 ) ) );
						bottom:100%;
						svg {
							float:right;
						}
					}
				}
			}

			ul {
				margin:0;
				padding:0 0 15px 0;

				> li {
					border-top:1.5px solid #4C4B4B;
					list-style:none;
					padding:15px 0;

					.dropdown {
						width:100%;
						display:flex;
						align-items:center;
						justify-content:space-between;
						cursor:pointer;
						transition:0.2s ease padding-bottom;
						p {
							margin:0;
							font-size:16px;
							font-style:normal;
							font-weight:400;
							line-height:160%;
							margin:0;
						}
					}

					&.open {
						.dropdown {
							padding-bottom:15px;
							p {
								font-weight:600;
							}
							svg {
								transform:rotate(180deg);
							}
						}
						ol {
							max-height:500px;
						}
					}

					ol {
						margin:0;
						padding:0;
						list-style:none;
						max-height:0;
						overflow:hidden;
						transition:0.3s ease max-height;
						> li {
							display:flex;
							justify-content:space-between;
							align-items:center;
							padding:15px;

							> div {
								font-size:15px;
								font-style:normal;
								font-weight:400;
								line-height:160%;
								margin:0;
								display:flex;
								align-items:center;
							}

							i {
								margin-right:0;
							}

						}
					}
				}
			}
		}


		#essential-oil-safety-guide {
			.container {
				max-width:920px;
				.content {
					max-width:700px;
					margin:0 auto 50px;
				}
			}
		}


		#dilution-ratio {
			.calculator {
				padding-top:60px;
				.step {
					padding:60px;
					background:color.adjust( Config.color('sage'), $lightness:20% );
					border-top:3px solid Config.color('brand');

					@media all and (max-width:450px){
						padding:20px;
					}

					h2 {
						font-weight:normal;
						font-size:32px;
						letter-spacing:0.49px;
						font-style:italic;
						line-height:150%;
						margin:0 0 15px;
						small {
							display:block;
							font-size:12px;
							font-family:Config.font('primary');
							text-transform:lowercase;
							line-height:normal;
						}
						@media all and (max-width:755px){
							font-size:28px;
							letter-spacing:0.4px;
						}
					}

					p {
						margin:0 0 30px 0;
						font-style:italic;
						text-transform:lowercase;
						font-size:15px;
						&.error {
							margin:0;
							font-weight:500;
							font-size:12px;
						}
					}

					ul {
						padding:20px 40px;
						border:1px dashed Config.color('sage');
						li {
							padding:3px 0;
							font-size:13px;
							font-style:italic;
							font-weight:400;
							line-height:150%;
							text-align:left;
							list-style:disc;

							strong {
								font-weight:500;
								text-transform:uppercase;
								font-size:13px;
								font-style:normal;
							}
						}
					}

					.fields {
						display:flex;
						gap:10px;
						.field {
							position:relative;
							width:100%;

							input,
							select {
								padding:8px 40px 8px 20px;
								font-size:12px;
								font-style:normal;
								font-weight:500;
								line-height:183.333%;
								letter-spacing:0.6px;
								text-transform:uppercase;
								background:transparent;
								background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'%3E%3Cpath d='M8 1L4.39909 5L0.798144 1' stroke='%235D5A88' stroke-linecap='round'/%3E%3C/svg%3E");
								background-repeat:no-repeat;
								background-position:95% center;
								border:1px solid Config.color('text');
								color:Config.color('text');
								appearance:none;
								width:100%;
								box-sizing:border-box;
								&[name="type"] {
									max-width:100px;
								}
							}

							input {
								background:none;
							}

						}
					}


					table {
						border:1px dashed Config.color('sage');
						width:100%;
						thead {
							tr {
								th {
									text-align:left;
									padding:15px 10px;
									text-transform:uppercase;
									font-weight:600;
									font-size:13px;
									line-height:120%;
									letter-spacing:0.49px;
									@media all and (max-width:755px){
										font-size:17px;
									}
								}
							}
						}
						tbody,
						tfoot {
							tr {
								td {
									padding:15px 10px;
									text-align:left;
									font-size:90%;
									border-top:1px dashed Config.color('sage');
									&:first-child {
										font-weight:500;
									}
								}
							}
						}
						tfoot {
							tr {
								@media all and (max-width:450px){
									&.head {
										display:none;
									}
								}
								&:first-child {
									td {
										text-align:left;
										padding:40px 10px 15px;
										text-transform:uppercase;
										font-weight:600;
										font-size:13px;
										line-height:120%;
										letter-spacing:0.49px;
										@media all and (max-width:755px){
											font-size:17px;
										}
									}
								}
								td {
									padding:5px 10px;
									font-size:90%;
									.fields {
										.field {
											select {
												border:none;
												width:auto;
												padding:0 20px 0 0;
											}
										}
									}
								}
							}
						}
					}

					.footer {
						padding-top:20px;
						margin:0;
					}
				}

				.buttons {
					padding-top:30px;
					display:flex;
					justify-content:space-between;
					div {
						display:flex;
						align-items:center;
						gap:5px;
					}
				}
			}
		}


	}
