
	/**
	*
	* 		Safety Page
	*			- Styles for "Safety" Page
	*
	**/
	@use '../_config' as Config;

	#pgiveaway {

		//Main Content
		main {

			section.container {
				padding-bottom:25px;
				max-width:800px;

				&:last-of-type {
					padding-bottom:100px;
				}

				&#introduction {
					padding-top:50px;
				}


				font-size:16px;
				letter-spacing:0.25px;
				line-height:200%;

				a:not(.button) {
					text-decoration:underline;
				}

				h1 {
					font-weight:normal;
					text-align:center;
					font-size:40px;
					line-height:53px;
					font-style:italic;
					span {
						display:block;
						font-size:50%;
						font-style:normal;
					}
				}

				h2 {
					font-weight:normal;
					font-style:italic;
					font-size:32px;
					line-height:150%;
					text-align:center;
					span {
						display:block;
						font-size:10px;
						font-style:italic;
						letter-spacing:0.5px;
						line-height:normal;
						font-family:Config.font('primary');
					}
					@media all and (max-width:755px){
						font-size:28px;
						letter-spacing:0.4px;
					}
				}

				h3 {
					font-family:Config.font('primary');
					text-transform:uppercase;
					font-weight:500;
					font-size:18px;
					line-height:120%;
					letter-spacing:0.49px;
					padding-top:50px;
					@media all and (max-width:755px){
						font-size:17px;
					}
				}


				ul {
					padding-top:20px;
					li {
						list-style:disc;
						padding-bottom:20px;
					}
				}


				.social {
					font-size:50px;
					display:flex;
					width:80%;
					margin:0 auto;
					padding:60px 0 30px;
					a {
						margin:auto;
						text-decoration:none;
						svg {
							fill:Config.color('text');
							display:block;
							width:40px;
						}
						&:hover {
							svg {
								fill:Config.color('brand');
								transition:all 0.5s ease;
							}
						}
						&.share {
							position:relative;
							.success {
								top:-10px;
								right:0;
								font-size:10px;
								position:absolute;
								opacity:0;
								transition:0.3s ease opacity, 0.3s ease top;
								white-space:nowrap;
								&.show {
									opacity:1;
									top:-25px;
								}
							}
						}
					}
				}

				code {
					font-size:clamp(16px, 5.5vw, 25px);
					border:1px dashed Config.color('text');
					font-family:Config.font('primary');
					padding:20px;
					margin:60px auto 20px;
					display:block;
					text-align:center;
					color:Config.color('text');
					cursor:pointer;
					position:relative;
					transition:0.3s ease background;
					max-width:300px;
					.success {
						top:-10px;
						right:0;
						font-size:10px;
						position:absolute;
						opacity:0;
						transition:0.3s ease opacity, 0.3s ease top;
						&.show {
							opacity:1;
							top:-25px;
						}
					}
					.copy {
						position:absolute;
						top:-1px;
						right:-1px;
						align-items:center;
						opacity:0;
						transition:0.3s ease opacity;
						width:10px;
						height:10px;
						padding:5px;
						background:Config.color('text');
						border:1px solid transparent;
						margin-left:10px;
						display:inline-block;
						svg {
							width:100%;
							height:100%;
							display:block;
							path {
								fill:#FFF;
							}
						}
					}
					&:hover {
						background:rgba( Config.color('brand') , 0.25 );
						.copy {
							opacity:1;
						}
					}
				}

				.success {
					color:Config.color('brand');
					font-style:italic;
				}

				footer {
					margin:1em 0 75px;
					padding-bottom:150px;
					border-bottom:1px solid Config.color('sage');
					p {
						font-size:12px;
						font-style:italic;
						margin:0;
					}
				}
			}
		}

	}
