
	/**
	*
	* 		Product Card
	*			- A Product Card in a Collection
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;



	//Product Card
	.product-card {
		width:100%;
		text-align:center;
		position:relative;
		transition:0.5s ease filter;
		justify-self:center;

		@media all and (max-width:700px){
			padding:0;
		}

		&.loading {
			filter:blur(4px);
		}

		> a {
			display:flex;
			text-decoration:none;
			width:100%;
			height:100%;
			flex-direction:column;
			margin:0 auto;
			//max-width:300px;
			position:relative;
			transition:0.3s ease background;

			.image {
				display:flex;
				background-size:cover;
				background-position:center center;
				vertical-align:middle;
				position:relative;
				z-index:0;
				div {
					background-color:#F3F3F3;
					transition:0.3s ease opacity;
					width:100%;

					img {
						width:100%;
						height:100%;
						aspect-ratio:1/1;
						object-fit:cover;
						display:block;
						margin:auto;
						align-self:center;
						opacity:1;
						transition:0.3s ease opacity;
						position:relative;
						z-index:1;
						&.hover {
							position:absolute;
							z-index:0;
							top:0;
							left:0;
						}
					}
				}
			}

			.details {
				padding:20px;
				box-sizing:border-box;
				position:absolute;
				text-align:left;
				height:100%;
				width:100%;
				display:flex;
				flex-direction:column;
				justify-content:space-between;
				background:linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.1) 100%);
				color:#FFF;
				top:0;
				left:0;

				.name {
					font-weight:400;
					font-size:clamp(20px, 4.7vw, 24px);
					line-height:100%;
					letter-spacing:0;
					display:block;
					white-space:nowrap;
					overflow:hidden;
					text-overflow:ellipsis;
					max-width:100%;
					margin:0;
					@media all and (max-width:400px){
						line-height:normal;
					}
				}

				.top {
					.header {
						display:flex;
						justify-content:space-between;
						flex-wrap:wrap;
						gap:0 10px;
					}
					.profile {
						display:none;
					}
					.inci {
						display:none;
					}
					.caption {
						padding:10px 0;
					}
				}

				.inci {
					font-weight:500;
					font-size:10px;
					text-align:left;
					text-transform:lowercase;
					letter-spacing:0.25px;
					line-height:100%;
					text-align:center;
					padding:0 0 10px;
					margin:0;
					@media all and (max-width:600px){
						display:block !important;
						text-align:left;
						font-weight:100;
						padding:5px 0 0;
					}
				}

				.profile {
					font-weight:500;
					font-size:11px;
					text-align:center;
					text-transform:lowercase;
					letter-spacing:0.25px;
					line-height:100%;
					padding-top:10px;
					.benefits {
						&:after {
							content:' | ';
						}
					}
					@media all and (min-width:600px) and (max-width:700px), (min-width:800px) and (max-width:1200px){
						.benefits {
							display:block;
							&:after {
								content:'—';
								display:block;
							}
						}
					}
					@media all and (max-width:600px){
						display:block !important;
						text-align:left;
						font-size:10px;
						line-height:normal;
						padding:5px 0 15px;
						font-weight:600;
						.benefits {
							&:after {
								content:'';
							}
							&:before {
								content:' | ';
							}
						}
					}
				}

				.hover {
					display:none;
					text-align:center;
					align-self:center;
					justify-self:center;
					margin:auto;

					.headline {
						font-weight:400;
						font-size:clamp(24px, 4.7vw, 32px);
						line-height:120%;
						letter-spacing:0;
						display:block;
						margin:0;
						@media all and (min-width:800px) and (max-width:950px){
							font-size:24px;
						}
					}

				}

				.type {
					font-family:Config.font('primary');
					font-weight:400;
					line-height:157.143%;
					font-size:14px;
					display:block;
					margin:0;
				}

				.caption {
					margin:0;
					font-size:12px;
					line-height:150%;
					padding:5px 0 10px;
					@media all and (max-width:600px){
						padding:5px 0 0;
						font-size:13px;
					}
				}

				.from {
					font-weight:400;
					line-height:157.143%;
					font-size:clamp(14px, 3.4vw, 16px);
					text-align:left;
					display:block;
					margin:5px 0 0;
					font-size:13px;
					text-transform:lowercase;
					display:none;

					.price {
						svg {
							width:15px;
							height:15px;
							position:relative;
							top:2px;
							padding-right:4px;
						}
					}

					> div {
						display:inline-block;
						padding:0 5px;
					}

					.was {
						text-decoration:line-through;
						color:color.adjust( Config.color('sage') , $lightness:-25% );
						padding-right:5px;
					}

					small {
						font-size:10px;
						line-height:normal;
					}
				}




				@media all and (max-width:600px){
					background:transparent;
					position:relative;
					color:Config.color('text');
					padding:20px 0;
					.from {
						display:block;
						margin-top:0;
					}
					.price {
						text-align:left;
					}
				}

			}

			.buttons {
				margin:auto 0 0 auto;
				.button {
					white-space:nowrap;
					.was {
						opacity:.5;
						color:#FFF;
					}
					&.unavailable {
						border:1px solid #CCC;
						background:#CCC;
						color:#000;
						&:hover {
							cursor:not-allowed;
						}
					}

					&[disabled="disabled"] {
						&, &:hover {
							background:#AAA;
							border:1px solid #AAA;
							color:#FFF;
							cursor:default;
						}
					}
					> span {
						> span {
							display:flex;
							align-items:center;
							gap:3px;
						}
					}
					&:hover {
						.price {
							.was {
								color:inherit;
							}
						}
					}
				}
				.price {
					//margin:10px 0 0;
					display:flex;
					.points {
						width:12px;
						height:12px;
						padding-right:5px;
						transform:none !important;
					}
					&:after {
						content:'-';
						padding-left:3px;
					}

					.was {
						text-decoration:line-through;
						padding-right:5px;
					}
				}

				@media all and (min-width:800px) and (max-width:915px){
					.button {
						font-size:11px;
						.points {
							display:none;
						}
					}
				}
				@media all and (max-width:600px){
					flex-direction:column;
					margin:auto auto 0 0;
					.button {
						width:100%;
						border:1px solid Config.color('text');
						color:Config.color('text');
						.price {
							display:none;
						}
					}
					.price {
						display:block;
					}
				}
			}

			@media all and (min-width:600px){
				&:hover {
					.image {
						div {
							img:not(.hover){
								opacity:0;
							}
						}
					}
					.details {
						background:rgba(0,0,0,.5);
						.top {
							opacity:0;
							display:none;
						}
						.hover {
							opacity:1;
							display:block;
						}
						.buttons {
							opacity:1;
						}
					}
				}
			}
		}

		@media all and (max-width:400px){
			> a {
				.description {
					padding:5px 5px 0;
				}
			}
		}
	}