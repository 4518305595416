
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	@use '../base/_layout.scss' as *;

	#phome {



		//Quote
		#introduction {
			padding:clamp( 35px , 8.5vw , 71px ) 0 clamp( 51px , 8.5vw , 83px );
			> div {
				max-width:773px;
				text-align:center;
				gap:25px;
				h2 {
					font-family:Config.font('headline');
					font-size:clamp( 30px , 8.5vw, 56px );
					font-style:normal;
					font-weight:400;
					line-height:clamp( 117.647% , 8.5vw, 114.286% );
					letter-spacing:clamp( -0.34px , 8.5vw , 0px );
					display:flex;
					justify-content:center;
					margin:0;
				}
				p {

				}
			}
		}


		//Category List
		#categories {
			padding-bottom:98px;
			position:relative;
			max-width:100%;
			overflow-x:hidden;
			@media all and (max-width:800px){
				padding:0 0 clamp(63px, 4.5vw, 98px);
			}
			.list {
				display:flex;
				gap:10px;
				align-items:stretch;
				justify-content:center;
				.category {
					display:flex;
					flex-direction:column;
					flex-grow:1;
					img {
						width:100%;
						height:auto;
					}
					.content {
						text-align:center;
						max-width:384px;
						margin:0 auto;
						height:100%;
						display:flex;
						flex-direction:column;
						padding:20px 7px 0;

						h2 {
							font-size:clamp( 24px , 5vw , 34px);
							font-style:normal;
							font-weight:400;
							line-height:clamp( 117.647% , 4.5vw , 133.333% );
							letter-spacing:clamp( 0px , 4.5vw , -0.34px );
							text-transform:lowercase;
							margin:0;
							flex-grow:1;
						}
						p {
							font-size:14px;
							font-style:normal;
							font-weight:400;
							line-height:157.143%;
						}
						.button {
							margin:auto auto 0;
						}
					}
				}

				@media all and (max-width:800px){
					justify-content:left;
					padding:0 var(--container-padding);
					.category {
						max-width:45%;
					}
				}

				@media all and (max-width:500px){
					.category {
						min-width:60%;
					}
				}
			}

			nav {
				position:absolute;
				width:100%;
				top:50%;
				left:0;
				z-index:1;
				justify-content:space-between;
				display:none;

				@media all and (max-width:800px){
					display:flex;
				}

				.button {
					padding:10px;
					&:first-child {
						svg {
							transform:rotate(180deg);
						}
					}
				}
			}

		}


		//Favourites
		#favourites {
			padding-bottom:105px;
			overflow-x:hidden;
			max-width:100%;
			header {
				display:flex;
				justify-content:space-between;
				align-items:center;
				h2 {
					font-size:42px;
					font-style:normal;
					font-weight:400;
					line-height:119.048%;
				}
				@media all and (max-width:750px){
					flex-direction:column;
					padding-bottom:40px;
					h2 {
						font-size:clamp( 34px , 4.5vw, 42px );
						line-height:clamp( 117.647%, 4.5vw, 119.048% );
						letter-spacing:clamp( -0.34px, 4.5vw, 0px );
						margin-bottom:20px;
					}
				}
			}
			.slider {
				position:relative;

				.list {
					display:flex;
					gap:10px;
					padding:0 var(--container-padding);

					img {
						display:block;
					}

					.item {
						position:relative;
						max-height:620px;
						min-height:320px;
						max-width:653px;

						img {
							height:100%;
							width:100%;
							object-fit:cover;
						}

						&.active {
							.content {
								opacity:1;
								pointer-events:auto;
								transform:translateY(0)
							}
						}

						> a.button,
						.content {
							position:absolute;
							left:20px;
							bottom:20px;
						}

						.content {
							padding:clamp( 15px, 3vw, 20px ) clamp( 15px, 3vw, 25px );
							background:#FFF;
							max-width:450px;
							transform:translateY(100px);
							max-width:83.5%;
							opacity:0;
							pointer-events:none;
							transition:0.3s ease transform, 0.3s ease opacity;

							h3 {
								font-family:Config.font('primary');
								font-size:clamp(12px, 4.5vw, 14px);
								font-style:normal;
								font-weight:500;
								line-height:clamp(183.333%, 4.5vw, 157.143%);
								letter-spacing:clamp(0.6px, 4.5vw, -.7px);
								text-transform:uppercase;
								margin:0;
								padding-bottom:clamp(5px, 4.5vw, 10px);
							}

							p {
								font-family:Config.font('headline');
								font-size:clamp(20px, 4.5vw, 24px);
								font-style:normal;
								font-weight:400;
								line-height:clamp(130%, 4.5vw, 133.333%);
								margin:0;
							}

							.buttons {
								padding-top:clamp(25px, 4.5vw, 40px);
							}
						}


						@media all and (max-width:750px){
							max-width:90%;
							max-height:100%;

							img {
								max-height:250px;
							}

							> a.button {
								display:none;
							}

							.content {
								position:static;
								width:100%;
								max-width:100%;
								box-sizing:border-box;
								opacity:1;
								transform:none;
							}
						}
					}
				}

				nav {
					position:absolute;
					top:50%;
					left:0;
					width:100%;
					display:flex;
					justify-content:space-between;
					pointer-events:none;
					z-index:2;
					.button {
						padding:10px;
						pointer-events:all;
						&:first-child {
							svg {
								transform:rotate(180deg);
							}
						}
					}
					ul {
						margin:0;
						padding:0;
						display:none;
						align-items:center;
						justify-content:center;
						width:100%;
						gap:10px;
						li {
							width:10px;
							height:10px;
							border-radius:100%;
							display:block;
							border:1px solid color.adjust( Config.color('brand') , $lightness:-20% );
							transition:0.3s ease border, 0.3s ease background;
							cursor:pointer;
							pointer-events:all;

							&.active {
								border:1px solid Config.color('brand');
								background:Config.color('brand');
							}

							&:hover {
								border:1px solid Config.color('brand');
							}
						}
					}
					@media all and (max-width:750px){
						position:static;
						padding-top:30px;
						.button {
							display:none;
						}
						ul {
							display:flex;
						}
					}
				}
			}
		}

		//Eco Friendly
		#eco {
			padding:clamp( 55px, 12vw, 105px ) var(--container-padding);
			header {
				padding-bottom:45px;
				h2 {
					text-align:center;
					font-size:clamp( 38px, 4.5vw, 56px);
					font-weight:400;
					line-height:clamp( 119.048%, 4.5vw, 114.286% );
					letter-spacing:-0.56px;
					margin:0;
				}
			}
			ul {
				display:grid;
				grid-template-columns:repeat(4,1fr);
				padding:0;
				gap:30px 9px;
				max-width:1095px;
				margin:0 auto;
				li {
					text-align:center;
					> div {
						padding-bottom:25px;
						display:flex;
						align-items:center;
						justify-content:center;
						min-height:56px;
						svg {
							max-height:100%;
						}
					}
					h3 {
						font-family:Config.font('primary');
						font-size:clamp( 12px, 4.5vw, 14px );
						font-weight:500;
						line-height:clamp( 183.333%, 4.5vw, 157.143% );
						letter-spacing:clamp( 0.6px, 4.5vw, 0.7px );
						text-transform:uppercase;
						padding-bottom:5px;
						margin:0;
					}
					p {
						font-size:clamp( 12px, 4.5vw, 14px );
						font-weight:400;
						line-height:clamp( 150%, 4.5vw, 157.143% );
						margin:0;
					}
				}
				@media all and (max-width:750px){
					grid-template-columns:repeat(2,1fr);
					li {
						max-width:170px;
						margin:0 auto;
						> div {
							padding-bottom:15px;
						}
					}
				}
			}
		}

		#press {
			background:color.adjust( Config.color('sage'), $lightness: 23% );
			padding:44px 0 54px;
			header {
				h2 {
					font-family:Config.font('primary');
					font-size:14px;
					font-weight:500;
					line-height:157.143%;
					letter-spacing:0.7px;
					text-transform:uppercase;
					margin:0;
					padding:0 0 30px;
					text-align:center;
				}
			}
			.list {
				display:flex;
				gap:30px;
				justify-content:center;
				white-space:nowrap;
				//flex-wrap:wrap;
				position:relative;
				max-width:100vw;
				overflow:hidden;
				> div {
					//min-width:100%;
					height:100%;
					display:flex;
					//gap:150px;
					justify-content:center;
					align-items:center;
					gap:30px;
					img {
						max-height:60px;
						max-width:100px;
						margin:auto 25px;
						filter: invert(33%) sepia(12%) saturate(1281%) hue-rotate(25deg) brightness(86%) contrast(85%);

						&[src*="daily-hive.svg"],
						&[src*="fashion.svg"] {
							max-width:150px;
						}

						&[src*="yahoo.svg"],
						&[src*="curiocity.svg"] {
							max-width:150px;
						}
					}
				}
			}
			/*
			.list {
				display:flex;
				gap:85px;
				@extend .container;

				.swiper-wrapper {
					justify-content:space-between;
				}

				.item {
					text-align:center;
					max-width:350px;
					width:33%;
					@media all and (max-width:500px){
						&:not(.swiper-slide-active){
							opacity:0;
						}
						max-width:100%;
						width:100%;
					}
					> div {
						padding-bottom:clamp(8px, 4.5vw, 25px);
						display:flex;
						align-items:center;
						justify-content:center;
					}
					p {
						font-size:16px;
						font-weight:400;
						line-height:150%;
						margin:0;
					}
				}
			}
			nav {
				display:none;

				@media all and (max-width:800px){
					display:block;
					padding-top:30px;
				}
				ul {
					margin:0;
					padding:0;
					display:flex;
					align-items:center;
					justify-content:center;
					width:100%;
					gap:10px;
					li {
						width:10px;
						height:10px;
						border-radius:100%;
						display:block;
						border:1px solid color.adjust( Config.color('brand') , $lightness:-20% );
						transition:0.3s ease border, 0.3s ease background;
						cursor:pointer;
						pointer-events:all;

						&.active {
							border:1px solid Config.color('brand');
							background:Config.color('brand');
						}

						&:hover {
							border:1px solid Config.color('brand');
						}
					}
				}
			}
			*/
		}


		#testimonials {
			padding:clamp( 65px, 12vw, 110px ) var(--container-padding);
			max-width:1100px;
			display:flex;
			justify-content:space-between;
			> div {
				position:relative;
				width:50%;

				blockquote {
					background:Config.color('brand');
					width:320px;
					height:313px;
					padding:56px 22px;
					box-sizing:border-box;
					position:absolute;
					top:calc(50% - (313px/2) );
					left:150px;
					display:flex;
					z-index:1;
					> div {
						margin:auto;
						color:#FFF;

						header {
							display:flex;
							gap:3px;
							align-items:center;
							justify-content:center;
							padding-bottom:20px;
						}

						q {
							font-family:Config.font('headline');
							font-size:clamp(16px, 4.7vw, 24px);
							font-weight:400;
							line-height:clamp(130%, 4.7vw, 133.333%);
							text-align:center;
							display:block;
						}
						cite {
							display:block;
							font-size:11px;
							font-weight:600;
							text-transform:uppercase;
							letter-spacing:1px;
							line-height:18px;
							font-style:normal;
							text-align:center;
							padding-top:15px;
						}
					}
				}

				&:last-child {
					max-width:390px;
					display:flex;
					flex-direction:column;

					h2 {
						font-size:clamp(34px, 10vw, 42px);
						letter-spacing:clamp(-0.34px, 10vw, 0px);
						line-height:clamp(117.647%, 10vw, 119.048%);
						font-style:normal;
						font-weight:400;
						line-height:100%;
						text-transform:lowercase;
						max-width:90%;
						margin:0;
						padding:27px 0 10px;
					}
					p {
						font-size:clamp(14px, 10vw, 16px);
						line-height:clamp( 157.143%, 10vw, 150%);
						font-weight:400;
					}

					nav {
						margin:auto auto 0 5px;
						font-size:14px;
						font-weight:500;
						line-height:157.143%;
						letter-spacing:0.7px;
						text-transform:uppercase;
						display:flex;
						gap:25px;
						align-items:center;
					}
				}
			}

			@media all and (max-width:1000px){
				justify-content:space-evenly;
				> div {
					width:auto;
					blockquote {
						display:none;
					}
				}
			}

			@media all and (max-width:750px){
				flex-direction:column;
				> div {
					margin:0 auto;

					blockquote {
						padding:clamp(28px, 4.7vw, 56px) clamp(20px, 4.7vw, 22px);
						height:auto;
						width:clamp(230px, 50vw, 320px);
						display:block;
						left:20%;
					}

					iframe {
						position:relative;
						left:-30%;
					}

					&:last-child {
						max-width:100%;
						h2 {
							max-width:355px;
							margin:0;
						}
						nav {
							padding-top:25px;
						}
					}
				}
			}

			@media all and (max-width:550px) {
				> div {
					margin:0;
					blockquote {
						right:0;
						left:auto;
						top:auto;
						bottom:0;
						margin:0;
					}
					iframe {
						left:0;
						width:auto;
						padding-bottom:53px;
					}
					&:last-child {
						padding-top:25px;

						h2 {
							max-width:clamp(320px, 50vw, 355px);
						}
					}
				}
			}
		}

		#products {
			padding:0 var(--container-padding) 110px;
			header {
				padding-bottom:30px;
				h2 {
					font-size:42px;
					font-weight:400;
					line-height:119.048%;
					text-align:center;
				}
			}

			.collection {
				.product-card:nth-child(4) {
					display:none;
					@media all and (max-width:800px){
						display:block;
					}
				}
			}

			footer {
				text-align:center;
				padding-top:50px;
			}
		}

	}
