
	/**
	*
	* 		Account - Points Page
	*			- Styles for the "Points" page
	*
	**/
	@use '../_config' as Config;

	#phistory {
		main .content {

			#order-history {
				width:100%;

				h2 {
					font-style:italic;
					+ p {

					}
				}

				//Cart Table
				table {
					width:100%;
					border-collapse:collapse;
					position:relative;

					@media all and (min-width:671px){
						[data-view="mobile"] {
							display:none;
						}
					}

					@media all and (max-width:670px){
						[data-view="desktop"] {
							display:none;
						}
					}

					@media all and (max-width:1000px){
						width:100%;
					}

					thead {
						th {
							margin:auto 0;
							font-weight:normal;
							text-align:center;
							letter-spacing:0.49px;
							line-height:43px;
							text-transform:uppercase;
							font-size:12px;
							border-bottom:1px dashed Config.color('sage');
							text-align:left;
						}
					}

					tbody {

						.demo {
							opacity:.10;
						}

						.notice td {
							padding:0;
							text-align:center;
							div {
								margin-top:-65px;
								width:100%;
								font-style:italic;
								position:absolute;
								padding:0;
								font-weight:600;
								color:#AAA;

								@media all and (max-width:550px){
									margin-top:-90px;
								}
							}
						}

						tr {
							td {
								padding:10px 0;
								vertical-align:middle;

								@media all and (max-width:400px){
									font-size:14px;
								}

								&.thumbnail {
									text-align:center;
									min-width:100px;
									img {
										max-height:80px;
										opacity:.8;
									}
								}

								&.product,
								.product {
									font-weight:normal;
									letter-spacing:0.63px;
									font-size:14px;
									.title {
										margin:0;
										text-decoration:none;
										border-bottom:1px solid transparent;
										font-size:18px;
									}

									a.title:hover {
										border-bottom:1px solid #CCC;
									}

									.variant,
									.type {
										margin:0;
										padding:0;
										color:#B3B3B3;
										font-size:80%;
									}
								}

								&.price,
								&.total,
								.price,
								.total {
									font-weight:normal;
									letter-spacing:0.63px;
									font-size:14px;
									margin:0;
									text-decoration:none;

									.was {
										text-decoration:line-through;
										color:rgba(255,0,0,.5);
										+ span {
											display:block;
											position:relative;
											top:-6px;
										}
									}
								}

								&.quantity,
								.quantity {
									input {
										border:1px solid #D9D9D9;
										font-weight:500;
										padding:5px 10px;
										color:#000;
										width:100%;
										outline:none;
										font-weight:500;
										color:#4C4B4B;
										font-size:12px;
										letter-spacing:1.32px;
										line-height:16px;
										display:block;
										width:50px;
									}

									.qty {
										font-weight:normal;
										letter-spacing:0.63px;
										font-size:14px;
										color:#4C4B4B;
										margin:0;
										text-decoration:none;
										padding:0 10px;
									}
								}

								&.action a {
									color:#4C4B4B;
								}
							}
							&:last-child td {
								padding-bottom:30px;
							}
						}
					}

					tfoot {
						border-top:1px solid #D9D9D9;

						tr {
							td {
								border:1px solid #D9D9D9;
								padding:10px;
								font-family:bakerville-display-pt, serif;
								font-size:14px;
								font-weight:normal;
								color:#4C4B4B;

								&:first-child {
									border-width:0;
									text-align:right;
									font-style:italic;
									font-weight:bold;
								}

								@media all and (max-width:550px){
									border:none;
								}

							}
						}

					}
				}
			}
		}

	}
