
	/**
	*
	* 		Product Card
	*			- A Product Card in a Collection
	*
	**/
	@use '../_config' as Config;
	
	#pcollections,
	#pwholesale {

		//Filters
		section#filters {
			margin:0 auto 45px;
			> div {
				display:flex;
				align-items:center;
				justify-content:center;
				gap:10px;

				&[data-view="desktop"] {
					display:flex;
					@media all and (max-width:880px){
						display:none;
					}
					h3 {
						font-family:Config.font('primary');
						text-transform:uppercase;
						font-weight:400;
						letter-spacing:1.32px;
						line-height:18px;
						font-size:10px;
						margin:0;
					}
					.currency {
						display:flex;
						gap:5px;
					}
					.list {
						display:flex;
						gap:10px;

						&:after {
							content:' | ';
							color:Config.color('sage');
						}

						.filter {
							display:inline-block;
							min-width:160px;

							ul {
								padding:0;
								margin:0 0 40px 0;

								li {
									list-style:none;

									a {
										font-size:10px;
										font-family:Config.font('primary');
										letter-spacing:0.92px;
										line-height:18px;
										font-weight:300;
										border-radius:5px;
										display:inline-block;
										padding:0 6px;
										cursor:pointer;

										&:hover {
											background:#F7F7F7;
											font-weight:500;
										}
									}

									&.selected a {

										background:#444;
										color:#FFF;
										font-weight:500;

									}

								}

							}

							select {
								border:1px solid #D9D9D9;
								color:#4C4B4B;
								padding:3px;
								font-size:12px;
								font-family:Montserrat;
								padding:5px 25px 5px 5px;
								min-width:160px;
								appearance:none;
								background-image:url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
								background-repeat:no-repeat;
								background-size:11%;
								background-position-x:98%;
								background-position-y:5px;
								border-radius:0;
								@media all and (max-width:850px){
									width:100%;
								}
							}

						}

					}
				}

				&[data-view="mobile"] {
					display:none;
					justify-content:space-between;
					@media all and (max-width:880px){
						display:flex;
					}

					.currency {
						display:flex;
						gap:5px;
						h3 {
							font-family:Config.font('primary');
							text-transform:uppercase;
							font-weight:400;
							letter-spacing:1.32px;
							line-height:18px;
							font-size:10px;
							margin:0;
						}
						@media all and (max-width:400px){
							flex-direction:column;
							.toggle {
								margin:0;
							}
						}
					}

					.btn {
						font-family:Config.font('primary');
						font-weight:500;
						font-size:10px;
						line-height:18px;
						letter-spacing:1.32px;
						color:Config.color('text');
						text-decoration:none;
						text-transform:uppercase;
						border-bottom:1px solid Config.color('brand');
						transition:0.3s ease border, 0.3s ease color;
						padding-bottom:5px;
						margin-bottom:10px;
						display:inline-block;
						cursor:pointer;
						&:after {
							content:'+';
							float:right;
							padding-left:15px;
						}
						&:hover {
							color:#000;
							border-bottom:1px solid #000;
						}
					}


					nav {
						position:fixed;
						top:0;
						right:0;
						width:100%;
						height:100%;
						background:rgba(0,0,0,.7);
						z-index:3;
						visibility:hidden;
						right:-100%;
						transition:0.5s ease opacity 0.5s, 0 ease visibility 0.5s, 0 ease right 0.5s;
						transition-delay:0.5s;

						&.open {
							visibility:visible;
							right:0;
							opacity:1;
							transition:none;
							.window {
								transition-delay:0.15s;
								right:0;
							}
						}

						.window {
							background:Config.color('background');
							width:100%;
							height:100%;
							max-width:350px;
							min-width:285px;
							padding:20px;
							overflow-y:scroll;
							position:absolute;
							right:-100%;
							transition:0.5s ease right;
							transition-delay:0;
							box-sizing:border-box;

							@media all and (max-width:360px){
								max-width:80%;
							}

							header {
								display:flex;
								justify-content:space-between;
								align-items:center;
								padding-bottom:10px;

								h3 {
									font-family:Config.font('headline');
									font-weight:400;
									font-size:18px;
									line-height:28px;
									margin:0;
									padding:0;
								}
							}
							.list {
								.filter {
									padding:10px 0;

									h4 {
										font-family:Config.font('headline');
										color:Config.color('text');
										font-weight:400;
										font-size:17px;
										line-height:28px;
										margin:0;
									}

									.option {
										display:flex;
										margin:5px 0;
										align-items:center;

										input[type="radio"] {
											appearance:none;
											width:16px;
											height:16px;
											border:1px solid Config.color('text');
											border-radius:100%;
											margin-right:7px;
											cursor:pointer;
											&:checked {
												background:Config.color('brand');
												border:1px solid Config.color('text');
											}
										}

										label {
											font-family:Config.font('primary');
											text-transform:lowercase;
											font-weight:400;
											font-size:14px;
											line-height:30px;
											cursor:pointer;
										}
									}
								}
							}
							footer {
								padding-top:10px;
								padding-bottom:30px;
								display:flex;
								justify-content:space-between;
								a {
									display:block;
									margin:10px 0;
								}
							}
						}
					}
				}
			}
		}
	}