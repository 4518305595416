
	/**
	*
	* 		Cart
	*			- The Cart Page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	#pcart {

		#cart {
			display:flex;
			align-items:flex-start;
			padding-bottom:100px;
			padding:30px;

			&[data-count="0"],
			&[data-count="1"],
			&[data-count="2"],
			&[data-count="3"] {
				border-bottom:none;
			}

			@media all and (max-width:1000px){
				flex-direction:column-reverse;
			}

			@media all and (max-width:500px){
				padding-bottom:50px;
			}

			.view {
				position:relative;
				width:65%;

				&[data-responsive="mobile"] {
					display:none;
				}

				@media all and (max-width:550px){
					&[data-responsive="mobile"] {
						display:block;
					}
					&[data-responsive="desktop"] {
						display:none;
					}
				}

				table {
					border-collapse:collapse;
					width:100%;

					@media all and (max-width:550px){

						.thumbnail {
							text-align:left;
							img {
								max-height:125px;
							}
							@media all and (max-width:400px){
								min-width:0;
								padding-right:10px;
							}
						}

						.details {

							.product {
								margin:0 auto auto;
							}

							.quantity {
								margin:20px auto 0;

								input {
									font-size:20px;
									width:100px;
								}
							}

							.total {
								display:none;
								@media all and (max-width:400px){
									display:block;
									margin-top:10px;
								}
							}
						}

						.action {
							position:relative;
							text-align:right;

							.total {
								font-size:20px;
								margin-bottom:15px;
								@media all and (max-width:400px){
									font-size:14px;
								}
							}

							.list {
								display:block;
								a {
									display:inline-block;
								}
							}
						}
					}

					@media all and (max-width:1000px){
						width:100%;
					}

					thead {
						th {
							margin:auto 0;
							font-family:Config.font('headline');
							font-weight:normal;
							text-align:center;
							letter-spacing:0.49px;
							line-height:43px;
							border-bottom:1px solid Config.color('sage');
							text-align:left;
						}
					}

					tbody {

						.demo {
							opacity:.10;
							pointer-events:none;
						}

						.none {
							text-align:center;
							padding:30px 0;
						}

						.notice td {
							padding:0;
							text-align:center;
							position:relative;
							> div {
								margin-top:-72px;
								width:100%;
								position:relative;
								padding:0;
								display:flex;
								justify-content:center;

								.icon {
									display:inline-block;
									background:Config.color('text');
									border-radius:100%;
									width:39px;
									height:39px;
									display:flex;
									margin-right:10px;
									svg {
										width:19px;
										height:19px;
										display:block;
										margin:auto;
										path {
											color:Config.color('background');
										}
									}
								}

								p {
									font-weight:600;
									font-size:13px;
									line-height:18px;
									letter-spacing:0.1px;
									text-transform:uppercase;
									max-width:200px;
									text-align:left;
									margin:0;
								}

								@media all and (max-width:550px){
									margin-top:-90px;
								}
							}
						}

						tr td {
							padding:10px 0;
							vertical-align:middle;

							&.thumbnail {
								text-align:center;
								min-width:100px;
								img {
									max-height:80px;
									width:auto;
									display:inline-block;
								}
							}

							&.product,
							.product {
								font-weight:normal;
								letter-spacing:0.63px;
								font-size:14px;
								.title {
									margin:0;
									text-decoration:none;
									text-transform:lowercase;
									border-bottom:1px solid transparent;
									font-family:Config.font('headline');
									font-size:21px;
								}

								a.title:hover {
									border-bottom:1px solid Config.color('text');
								}

								.variant,
								.type {
									margin:0;
									font-size:80%;
								}
							}

							&.price,
							&.total,
							.price,
							.total {
								font-weight:normal;
								letter-spacing:0.63px;
								font-size:14px;
								margin:0;
								text-decoration:none;

								.was {
									text-decoration:line-through;
									color:rgba(255,0,0,.5);
									display:block;
									+ span {
										display:block;
										position:relative;
										//top:-6px;
									}
								}
							}

							&.quantity,
							.quantity {
								input {
									border:1px solid Config.color('sage');
									font-weight:500;
									padding:5px 10px;
									width:100%;
									outline:none;
									font-weight:500;
									font-size:12px;
									letter-spacing:1.32px;
									line-height:16px;
									display:block;
									width:50px;
								}

								.qty {
									font-weight:normal;
									letter-spacing:0.63px;
									font-size:14px;
									margin:0;
									text-decoration:none;
									padding:0 10px;
								}
							}

							&.action {
								.list {
									display:flex;
								}

								a {
									text-decoration:none;
									position:relative;
									text-transform:uppercase;
									display:block;
									color:Config.color('text');
									transition:0.3s ease color;
									cursor:pointer;
									span {
										display:none;
										font-size:10px;
										position:absolute;
										top:20px;
										transform:translateX(-42%);
										background:Config.color('text');
										padding:4px 8px;
										color:#FFF;
										white-space:nowrap;
										font-weight:bold;
										text-align:center;
										line-height:normal;
										&::before {
											width:0;
											height:0;
											border-left:5px solid transparent;
											border-right:5px solid transparent;
											border-bottom:5px solid Config.color('text');
											position:absolute;
											content:'';
											top:-5px;
											left:46%;
										}
									}
									&:hover {
										color:Config.color('brand');
										span {
											display:block;
										}
									}
								}
							}
						}
					}


					@media all and (max-width:650px){
						.price {
							display:none !important;
						}
					}
				}
			}

			.subtotal {
				width:35%;
				margin-bottom:50px;

				@media all and (max-width:1000px){
					width:100%;
				}

				.box {
					margin:0 0 10px 40px;
					background:color.adjust( Config.color('sage') , $lightness:20% );
					padding:20px;
					text-align:center;
					display:flex;
					flex-direction:column;
					box-sizing:border-box;
					//max-height:200px;

					@media all and (max-width:1000px){
						width:100%;
						margin:0;
					}

					.title {
						margin:auto 0;
						font-family:Config.font('headline');
						font-weight:normal;
						letter-spacing:0.49px;
						text-transform:lowercase;
						font-style:italic;
						font-size:25px;
						padding:0;
						.text {
							padding-right:20px;
						}
						+ .info {
							padding-top:20px;
						}
					}

					.payments {
						font-size:11px;
						margin-bottom:0;
						padding-top:0;
						a {
							font-weight:bold;
							text-decoration:none;
							cursor:pointer;
							display:block;
						}
					}

					.info {
						font-size:10px;
						font-style:italic;
						margin:auto 0;
						line-height:normal;
					}

					.incentive {
						padding:10px 0;
						font-size:14px;
						max-width:80%;
						width:100%;
						margin:0 auto;
						font-weight:500;
						font-style:italic;
						text-transform:lowercase;

						p {
							margin:0;
						}

						@media all and (max-width:330px){
							strong {
								font-size:99%;
							}
						}

					}

					.update {
						display:none;
					}
				}

				.gift {
					margin:0 0 0 40px;
					text-align:center;

					@media all and (max-width:1000px){
						width:100%;
						margin:0;
					}

					.gift-button {
						display:inline-block;
						font-size:10px;
						letter-spacing:1px;
						text-transform:uppercase;
						text-decoration:underline;
						font-weight:500;
						vertical-align:center;
						text-align:center;
						cursor:pointer;
						outline:none;
					}

					.message {
						margin-top:20px;

						.head {
							display:flex;
							justify-content:space-between;
							padding-bottom:5px;

							h6 {
								margin:auto 0;
								font-weight:normal;
								text-align:center;
								line-height:normal;
								color:Config.color('text');
								font-size:18px;
								text-align:left;
							}

							[data-hide-gift] {
								padding:5px 8px;
								font-weight:bold;
								letter-spacing:0;
								position:relative;
								top:29px;
							}
						}

						textarea {
							width:100%;
							border:1px solid Config.color('sage');
							background:#FFF;
							padding:10px 30px 10px 15px;
							letter-spacing:1.25px;
							font-size:12px;
							line-height:150%;
							resize:vertical;
						}

					}

				}

			}
		}

		#products {
			header {
				padding-top:50px;
				border-top:1px solid Config.color('sage');

			}
		}
	}

