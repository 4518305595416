
	/**
	*
	* 		Instagram Component
	*			- The Instagram Component
	*
	**/

	//Instagram
	#instagram {
		padding:clamp(55px, 12vw, 100px) 0 65px;
		header {
			text-align:center;
			padding-bottom:20px;
			h2 {
				font-size:34px;
				//font-size:clamp(20px, 4.5vw, 34px);
				font-style:normal;
				font-weight:400;
				line-height:117.647%;
				letter-spacing:-0.34px;
				text-transform:none;
			}
			p {
				font-size:16px;
				font-weight:400;
				line-height:150%;
			}
		}

		> div {
			overflow:hidden;
			max-width:100%;
			position:relative;
			min-height:279px;
			ul {
				padding:0;
				margin:0;
				display:flex;
				gap:10px;
				position:absolute;
				top:0;
				left:0;
				padding-left:10px;
				&:first-child {
					animation:ig-scroll-primary 700s linear infinite;
				}
				&:last-child {
					animation:ig-scroll-secondary 700s linear infinite;
				}
				li {
					a {
						position:relative;
						display:block;
						img {
							max-width:279px;
							max-height:279px;
							aspect-ratio:1/1;
							object-fit:cover;
							display:block;
						}
						span {
							opacity:0;
							width:100%;
							height:100%;
							display:flex;
							background:rgba(0,0,0,.50);
							color:#FFF;
							align-items:center;
							justify-content:center;
							transition:0.3s ease opacity;
							position:absolute;
							text-align:center;
							font-size:25px;
							top:0;
							left:0;
						}
						&:hover {
							span {
								opacity:1;
							}
						}
					}
				}
			}
		}

		@media all and (max-width:500px){
			padding-bottom:0;
			> div {
				min-height:200px;
				ul {
					li {
						a {
							img {
								max-width:180px;
								max-height:180px;
							}
						}
					}
				}
			}
		}
	}


	@keyframes ig-scroll-primary {
		0% {
			transform:translateX(0);
		}
		100% {
			transform:translateX(-100%);
		}
	}

	@keyframes ig-scroll-secondary {
		0% {
			transform:translateX(100%);
		}
		100% {
			transform:translateX(0%);
		}
	}