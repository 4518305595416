
	/**
	*
	* 		Rating
	*			- The Star Rating
	*
	**/

	//Rating Module / Stars
	.rating {
		display:flex;
		align-items:center;
		padding-bottom:12px;
		a,
		span {
			margin-left:6px;
			font-size:12px;
			font-style:normal;
			font-weight:400;
			line-height:150%;
		}
	}