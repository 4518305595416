
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use '../_config' as Config;

	#pbusiness {

		.banner {
			&.empty {
				h1 {
					font-size:20px;
					margin:2em auto 0;
					@media all and (max-width:450px){
						margin:1em auto 0;
					}
				}
			}
		}

		#banner {
			.wrap {
				.content {
					header {
						h1 {
							font-size:48px;
							line-height:104.167%;
							font-weight:400;
							margin:0;
						}
						p {
							font-size:11px;
							font-style:normal;
							font-weight:500;
							line-height:109.091%;
							letter-spacing:1.056px;
							text-transform:uppercase;
						}
					}
				}
			}
		}


		#partners {
			padding:25px 0 clamp(40px, 8vw, 100px);
			overflow-x:hidden;
			header {
				padding-bottom:10px;
				h2 {
					font-family:Config.font('primary');
					font-size:12px;
					font-style:normal;
					font-weight:500;
					line-height:133.333%;
					letter-spacing:0.6px;
					text-transform:uppercase;
					text-align:center;
				}
			}
			> div {
				position:relative;
				animation:ig-scroll-primary 150s linear infinite;
				white-space:nworap;
				display:flex;
				gap:60px;
				> div {
					display:flex;
					gap:60px;
					img {
						margin:auto;
						filter: brightness(0) saturate(100%) invert(34%) sepia(3%) saturate(5232%) hue-rotate(21deg) brightness(86%) contrast(89%);
					}
				}
			}
		}


		#introduction {
			padding:20px var(--container-padding);
			//header {
				margin:0 auto;
				max-width:700px;
				text-align:center;
				&.small {
					max-width:525px;
				}
				h2 {
					font-size:clamp(35px, 3.5vw, 40px);
					font-weight:400;
					line-height:107.5%;
					text-align:center;
					margin:0;
					em {
						font-size:42px;
					}
				}
			/*}
			.content {
				display:flex;
				padding:40px 0;
				gap:60px;
				> div {
					p {
						font-size:14px;
						line-height:145%;
						max-width:295px;
					}
					&:first-child {
						margin:46px 0 auto auto;
					}
					&.image {
						img {
							display:block;
							object-fit:cover;
						}
					}
					&:last-child {
						margin:auto auto 64px 0;
					}
				}
				@media all and (max-width:800px){
					display:grid;
					grid-template-columns:1;
					padding:20px 0;
					gap:0;
					> div {
						&:first-child {
							margin:0;
						}
						&:last-child {
							margin:0;
						}
						p {
							max-width:100%;
							font-size:16px;
							text-align:center;
						}
						&.image {
							order:2;
							margin:20px auto 0;
							img {
								width:100%;
								max-height:250px;
								object-fit:cover;
							}
						}
					}
				}
			}
			*/
			@media all and (max-width:450px){
				& + #partners {
					padding-top:0;
				}
			}
		}

		.spacer {
			padding:clamp(40px, 3.5vw, 75px) 0;
			&.container {
				padding:clamp(40px, 3.5vw, 75px) var(--container-padding);
			}
		}


		//Featured content with edged images
		.edged {
			display:flex;
			gap:30px;
			.content {
				max-width:385px;
				margin:0 auto;
				text-align:center;
				width:100%;
				h2 {
					font-size:40px;
					font-style:normal;
					font-weight:400;
					line-height:107.5%;
					em {
						font-size:42px;
					}
				}
				.buttons {
					justify-content:center;
					padding-top:20px;
				}
			}
			.image {
				width:100%;
				div {
					position:relative;
					max-width:500px;
					width:100%;
					height:100%;
					max-width:20vw;
					content:' ';
					img {
						object-fit:cover;
					}
				}
				&:last-child {
					div {
						margin:auto 0 auto auto;
					}
				}
			}

			&.wide {
				.content {
					max-width:605px;
				}
			}
			@media all and (min-width:701px) and (max-width:1200px){
				.image {
					width:50%;
				}
			}
			@media all and (max-width:700px){
				.content {
					max-width:100%;
					padding:0 var(--container-padding);
				}
				.image {
					display:none;
				}
			}
		}


		#categories {
			margin-top:clamp( 100px, 4.5vw, 160px );
			> header {
				max-width:670px;
				margin:0 auto;
				padding:0 var(--container-padding) 60px;
				h2 {
					font-size:40px;
					font-weight:400;
					line-height:107.5%;
					text-align:center;
					margin:0;
					em {
						font-size:42px;
					}
				}
			}

			.list {
				.category {
					img {
						max-height:546px;
						min-height:350px;
					}
					@media all and (max-width:800px){
						max-width:100%;
					}
					@media all and (max-width:500px){
						max-width:100%;
					}
				}
			}

			.swiper-slide {
				img {
					min-height:546px;
					object-fit:cover;
				}
			}
			@media all and (max-width:450px){
				.controls {
					a {
						transform:scale(0.8);
					}
				}
			}
		}


		.split {
			display:flex;
			justify-content:center;
			align-items:center;
			padding:clamp(30px, 8vw, 75px) var(--container-padding);
			gap:90px;
			div {
				width:50%;

				&.content {
					max-width:425px;
					text-align:center;

					.starting {
						display:block; 
						font-size:20px;
					}

					.block {
						display:block;
					}

					h2 {
						font-size:40px;
						font-style:normal;
						font-weight:400;
						line-height:107.5%;
						margin-top:0;
						em {
							font-size:42px;
						}
					}

					p {
						line-height:145%;
					}

					.buttons {
						width:100%;
						padding-top:30px;
						justify-content:center;
					}
				}

				&.images {
					max-width:438px;

					div {
						max-width:438px;
						margin:0 auto;
						position:relative;
						width:100%;

						img {
							object-position:center center;
							object-fit:cover;
							width:100%;
							&.mini {
								position:absolute;
								border:10px solid Config.color('background');
								max-width:188px;
								max-height:188px;
							}
						}
					}
				}

			}
			&.small {
				.content {
					h2 {
						font-size:34px;
						font-weight:400;
						line-height:120%;
					}
					p {
					}
				}
			}
			&.testimonial {
				blockquote {
					text-align:left;
					margin:0 20px;
					.rating {
						padding-bottom:10px;
						svg {
							padding-right:2px;
						}
					}
					q {
						font-family:Config.font('headline');
						font-size:clamp(26px, 3.5vw, 34px);
						font-weight:400;
						line-height:100%;
						padding:15px 0 20px;
						display:block;
						&:before {
							position:absolute;
							margin-left:-15px;
						}
					}
					cite {
						display:block;
						font-size:12px;
						font-weight:500;
						line-height:133.333%;
						letter-spacing:0.6px;
						text-transform:uppercase;
					}
				}
			}


			&.top {
				.images {
					div {
						.mini {
							top:-75px;
						}
					}
				}
			}

			&.bottom {
				.images {
					div {
						.mini {
							bottom:-75px;
						}
					}
				}
			}

			&.left {
				.images {
					div {
						.mini {
							left:-61px;
						}
					}
				}
			}

			&.right {
				gap:29px;
				.images {
					margin-right:61px;
					div {
						.mini {
							right:-61px;
						}
					}
				}
			}

			@media all and (max-width:800px){
				flex-direction:column;
				div {
					max-width:100%;
					width:100%;
					&.images {
						display:flex;
						order:1;
						div {
							img {
								height:100%;
								max-height:300px;
								margin:0 auto;
							}
						}
					}
				}
				&.top {
					.images {
						padding-top:50px;
					}
				}
				&.right {
					div {
						&.images {
							div {
							max-width:90%;
								margin:auto 0 auto auto;
							}
						}
					}
				}
				&.left {
					div {
						&.images {
							div {
								max-width:90%;
								margin:auto 0 auto auto;
							}
						}
					}
				}
			}

			@media all and (max-width:450px){
				&.top {
					.images {
						div {
							.mini {
								top:-50px;
							}
						}
					}
				}

				&.bottom {
					.images {
						div {
							.mini {
								bottom:-50px;
							}
						}
					}
				}

				&.left {
					.images {
						div {
							.mini {
								left:-36px;
							}
						}
					}
				}

				&.right {
					gap:29px;
					.images {
						margin-right:36px;
						div {
							.mini {
								right:-28px;
							}
						}
					}
				}

				div {
					&.content {
						.buttons {
							flex-direction:column;
						}
					}
					&.images {
						div {
							img {
								&.mini {
									width:120px;
									height:120px;
								}
							}
						}
					}
				}
			}
		}

		#customized {
			padding:50px var(--container-padding);
			header {
				h2 {
					font-size:40px;
					line-height:107.5%;
					text-align:left;
					max-width:474px;
					font-weight:400;
					em {
						font-size:43px;
					}
				}
			}
			> div {
				display:flex;
				padding:0 var(--container-padding);
				justify-content:center;
				gap:10px;
				> div {
					img {
						display:block;
						width:100%;
						object-fit:cover;
					}
					> div {
						h3 {
							font-size:36px;
							font-weight:400;
							line-height:100%;
							max-width:338px;
						}
						p {
							font-size:16px;
							line-height:145%;
							max-width:400px;
						}
						.buttons {
							padding-top:20px;
						}
					}
				}
			}
			@media all and (max-width:650px){
				> div {
					flex-direction:column;
					gap:30px;
					img {
						height:auto;
					}
				}
			}
		}


		#industry {
			padding:clamp(20px, 4.5vw, 30px) var(--container-padding);
			header {
				max-width:385px;
				margin:0 auto 50px;
				text-align:center;
				h2 {
					font-size:40px;
					font-style:normal;
					font-weight:400;
					line-height:107.5%;
					em {
						font-size:42px;
					}
				}
			}
			> div {
				display:grid;
				grid-template-columns: repeat(4, minmax(0, 1fr));
				gap:15px;
				justify-content:center;
				@media all and (max-width:1200px){
					grid-template-columns:repeat(2, minmax(0,1fr));
				}
				@media all and (max-width:650px){
					grid-template-columns:repeat(1, minmax(0,1fr));
				}
				.box {
					position:relative;
					img {
						display:block;
						object-fit:cover;
						width:100%;
					}
					> div {
						position:absolute;
						top:0;
						left:0;
						background:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 25%, rgba(0, 0, 0, 0.80) 90.03%) no-repeat;
						width:100%;
						height:100%;
						display:flex;
						div {
							margin:auto 0 0;
							padding:36px 23px;
							h2 {
								font-size:clamp( 24px , 5vw , 28px);
								font-style:normal;
								font-weight:400;
								line-height:clamp( 117.647% , 4.5vw , 133.333% );
								letter-spacing:clamp( 0px , 4.5vw , -0.34px );
								text-transform:lowercase;
								margin:0;
								flex-grow:1;
								color:#FFF;
								padding-bottom:10px;
							}
							p {
								font-size:14px;
								font-style:normal;
								font-weight:400;
								line-height:157.143%;
							}
						}
					}
				}
			}
		}

		#process {
			header {
				max-width:385px;
				margin:0 auto 50px;
				text-align:center;
				h2 {
					font-size:40px;
					font-style:normal;
					font-weight:400;
					line-height:107.5%;
					em {
						font-size:42px;
					}
				}
			}

			ol {
				counter-reset:list-number;
				padding-left:0;
				li {
					counter-increment:list-number;
					text-align:left;
					display:flex;
					align-items:flex-start;
					justify-content:center;
					padding-bottom:50px;
					&:before {
						content: counter(list-number);
						font-family:Config.font('headline');
						border:1px solid Config.color('sage');
						padding:20px;
						aspect-ratio:1/1;
						display:block;
						border-radius:100%;
						font-size:30px;
						width:25px;
						height:25px;
						text-align:center;
						line-height:100%;
						@media all and (max-width:450px){
							padding:15px;
							font-size:25px;
						}
					}
					div {
						padding-left:20px;
						max-width:750px;
						h3 {
							font-family:Config.font('headline');
							font-size:25px;
							margin:0 0 5px;
						}
						p {
							margin:0;
						}
					}
				}
			}

		}

		#examples {
			position:relative;
			margin:clamp( 20px, 3.5vw, 75px) auto;

			header {
				max-width:1017px;
				margin:0 auto;
				padding-bottom:30px;
				h2 {
					max-width:450px;
					font-size:40px;
					font-style:normal;
					font-weight:400;
					line-height:107.5%;
					text-align:left;
					margin:0;
					em {
						font-size:42px;
					}
				}
			}

			.swiper-slide {
				img {
					display:block;
					width:100%;
					height:auto;
					object-fit:cover;
				}
				> div {
					padding:20px;
					text-align:center;
					display:flex;
					flex-direction:column;
					flex-grow:1;

					h3 {
						font-size:24px;
						font-weight:400;
						line-height:130%;
						margin:0;
					}
					p {
						font-size:14px;
						line-height:145%;
					}
					.buttons {
						padding-top:20px;
						justify-content:center;
						margin:auto auto 0;
					}
				}
			}

			nav.controls {
				position:absolute;
				width:100%;
				top:50%;
				left:0;
				z-index:1;
				justify-content:space-between;
				display:none;

				@media all and (max-width:800px){
					display:flex;
				}

				.button {
					padding:10px;
					&:first-child {
						svg {
							transform:rotate(180deg);
						}
					}
				}
			}
		}

	}
