
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use '../_config' as Config;


	#pwholesale {

		//
		#account .content footer {
			width:100%;
			position:fixed;
			bottom:0;
			left:0;
			background:Config.color('background');
			box-shadow:0px -4px 4px 0px rgba(0, 0, 0, 0.05);
			z-index:3;
			.container {
				padding:10px var(--container-padding);
				align-items:center;
				justify-content:space-between;
				h2 {
					font-size:20px;
					margin:0;
				}

				.shipping {
					width:30%;
					min-width:210px;
					p {
						font-size:11px;
						margin:0;
							text-align:center;
							box-sizing:border-box;
					}
					svg {
						margin:0 auto;
						display:block;
					}
					.bar {
						width:100%;
						width:100%;
						border:1px solid Config.color('sage');
						padding:2px;
						box-sizing:border-box;
						div {
							background:Config.color('sage');
							height:5px;
							width:100%;
							overflow:hidden;
							div {
								background:Config.color('text');
								transition:0.3s ease width;
								height:100%;
							}
						}
					}
				}

				.button {
				}
			}

			[data-view="desktop"] {
				display:flex;
				justify-content:space-between;
				width:100%;
			}

			[data-view="mobile"] {
				display:none;
				gap:10px;
				width:100%;
				.top {
					display:flex;
					justify-content:space-between;
				}
				.shipping {
					width:100%;
					padding:10px 0;
					p {
						position:absolute;
						text-align:center;
						width:calc(100% - var(--container-padding) );
						color:#FFF;
					}
					.bar {
						height:25px;
						> div {
							height:19px;
						}
					}
				}
			}

			@media all and (max-width:550px){
				[data-view="desktop"] {
					display:none;
				}
				[data-view="mobile"] {
					display:block;
				}
			}
		}

		section#filters {
			padding:0;
			margin:0;
		}


		//Product Details
		.details {
			padding:20px 0;
			box-sizing:border-box;
			text-align:left;
			color:Config.color('text');
			flex-direction:column;
			justify-content:space-between;
			text-transform:lowercase;

			.name {
				font-weight:400;
				font-size:clamp(20px, 4.7vw, 24px);
				line-height:100%;
				letter-spacing:0;
				display:block;
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
				max-width:100%;
				margin:0;
				small {
					font-size:12px;
					padding-left:5px;
					font-family:Config.font('primary');
					&:before {
						content:' (';
					}
					&:after {
						content:')';
					}
				}
			}

			.profile,
			.type,
			.case {
				font-weight:400;
				font-size:11px;
				text-align:left;
				text-transform:lowercase;
				letter-spacing:0.25px;
				line-height:100%;
				margin:0;
			}

			.caption {
				margin:0;
				font-size:12px;
				line-height:150%;
				padding:5px 0;
			}

			.type,
			.case {
				font-weight:500;
				margin:0;
				padding-top:10px;
			}
		}

		label {
			font-weight:500;
			display:block;
			font-size:10px;
			text-align:left;
			text-transform:uppercase;
			letter-spacing:0.25px;
			line-height:100%;
			padding-bottom:5px;
			margin:0;
			width:100%;
		}

		.wholesale {
			width:100%;
			position:relative;

			section:not(#filters) {

				//Form Fields
				input[type="number"],
				input[disabled],
				textarea,
				select {
					padding:2px 40px 2px 10px;
					font-size:12px;
					font-style:normal;
					font-weight:500;
					line-height:183.333%;
					letter-spacing:0.6px;
					text-transform:uppercase;
					background:transparent;
					background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'%3E%3Cpath d='M8 1L4.39909 5L0.798144 1' stroke='%235D5A88' stroke-linecap='round'/%3E%3C/svg%3E");
					background-repeat:no-repeat;
					background-position:90% center;
					border:1px solid Config.color('text');
					color:Config.color('text');
					appearance:none;
					width:100%;
					box-sizing:border-box;
				}

				textarea {
					padding:10px;
					max-width:95%;
					background-image:none;
					text-transform:none;
				}

				input[type="number"] {
					padding:2px 20px;
					background:none;
				}

				input[disabled] {
					background-image:none;
					opacity:0.5;
				}

			}

			.page-info {
				padding-bottom:20px;
				display:flex;
				justify-content:space-between;
				h2 {
					margin:0 auto;
				}
				div {
					display:flex;
					gap:10px;
					align-items:center;
					p {
						text-transform:uppercase;
						margin:0;
						font-size:11px;
					}
				}
			}

			//Wholesale Product List
			&.list {
				width:100%;
				min-height:200px;
				position:relative;
				section {

					+ section {
						padding-top:40px;
					}

					header {
						h2 {
							font-style:italic;
							margin-top:100px;
						}
					}

					&:first-child {
						header {
							h2 {
								margin-top:0;
							}
						}
					}

					.collection {
						@media all and (max-width:500px){
							grid-template-columns: repeat(1, minmax(0, 1fr));
						}
					}

					.product-card {
						img {
							width:100%;
							height:100%;
							aspect-ratio:1/1;
							object-fit:cover;
							display:block;
							margin:auto;
							align-self:center;
							opacity:1;
							transition:0.3s ease opacity;
							position:relative;
							z-index:1;
						}

						.options {
							border-top:1px dashed Config.color('sage');
							padding-top:10px;
							.rows {
								display:flex;
								gap:10px;
								.row {
									display:block;
									padding:10px 0;
									width:50%;
								}
							}
						}

						.group {
							h3 {
								text-align:left;
								font-family:Config.font('primary');
								font-weight:600;
								font-size:12px;
								margin:0;
								display:flex;
								justify-content:space-between;
								span {
									&:after {
										content:': ';
									}
								}
							}
							.case {
								font-weight:400;
								font-size:11px;
								text-align:center;
								text-transform:lowercase;
								letter-spacing:0.25px;
								line-height:100%;
								margin:0;
							}
							padding-bottom:20px;
							margin-bottom:20px;
							border-bottom:1px dashed Config.color('sage');
						}

						.buttons {
							flex-direction:column;
							margin:auto auto 0 0;
							.button {
								white-space:nowrap;
								width:100%;
								.was {
									opacity:.5;
									color:#FFF;
								}
								&.unavailable {
									border:1px solid #CCC;
									background:#CCC;
									color:#000;
									&:hover {
										cursor:not-allowed;
									}
								}

								&[disabled="disabled"] {
									&, &:hover {
										background:#AAA;
										border:1px solid #AAA;
										color:#FFF;
										cursor:default;
									}
								}
							}
							.price {
								margin:10px 0 0;
							}
						}
					}
				}
			}

			//
			&.review {
				padding-bottom:100px;
				@media all and (max-width:650px){
					[data-view="desktop"] {
						display:none !important;
					}
				}
				@media all and (min-width:651px){
					[data-view="mobile"] {
						display:none !important;
					}
				}

				header {
					h2 {
						@media all and (max-width:650px){
							margin-bottom:0;
						}
					}
				}
				section {

					table {
						width:100%;

						thead {
							tr {
								th {
									font-weight:500;
									border-bottom:1px solid Config.color('sage');
									font-family:Config.font('headline');
									font-style:italic;
									text-transform:lowercase;
									padding-bottom:3px;
								}
							}
						}

						tfoot {
							tr {
								&:first-child {
									td {
										border-top:1px solid Config.color('sage');
										padding-top:20px;
									}
								}
								&:last-child {
									td {
										padding-top:20px;
									}
								}
								td {
									padding:5px 0;
									div {
										display:flex;
										justify-content:space-between;
									}
								}
								td.text {
									padding-right:20px;
									font-family:Config.font('headline');
									text-transform:lowercase;
									text-align:right;
									padding-left:20px;
									border-left:1px solid Config.color('sage');
								}
								td.value {
									text-align:center;
								}
								&.total {
									td {
										padding-bottom:20px;
									}
								}
								#notes {
									@media all and (max-width:650px){
										max-width:100%;
									}
								}
							}
						}

						tbody {
							td {
								padding:20px 0;
							}
							td.image {
								width:100px;
								div {
									padding-right:10px;
									img {
										width:100px;
										height:100px;
									}
								}
								@media all and (max-width:650px){
									vertical-align:top;
								}

								@media all and (max-width:400px){
									display:none;
								}
							}
							td.qty {
								width:50px;
								input {
									padding:2px !important;
									text-align:center;
								}
							}
							td.details {
								.image {
									padding-bottom:15px;
									img {
										width:100%;
										height:auto;
									}
									@media all and (min-width:401px){
										display:none;
									}
								}
								h2 {
									margin:-7px 0 0;
									display:flex;
									justify-content:space-between;
								}
								.caption {
									max-width:300px;
								}
								.actions {
									padding-top:20px;
									display:flex;
									gap:20px;
									div {
										width:100%;
									}
								}
							}
							td.testers {
								width:100px;
							}
							td.total {
								width:100px;
							}
							td.spacer {
								padding:20px;
							}
						}
					}
				}
			}

		}



	}
