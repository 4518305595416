
	/**
	*
	* 		Review Component
	*			- The Review Component
	*
	**/
	@use '../_config' as Config;

	//Review Component
	#reviews {
		padding-bottom:20px;
		header {
			display:grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			justify-content:space-between;
			gap:calc( var(--container-padding) * 2 );
			padding-bottom:10px;
			border-bottom:1px solid Config.color('sage');

			@media all and (max-width:750px){
				grid-template-columns:repeat(2, minmax(0,1fr));
				> div {
					&:nth-child(2){
						grid-row: 1;
						grid-column: span 2;
					}
				}
			}

			@media all and (max-width:400px){
				grid-template-columns:repeat(1, minmax(0,1fr));
				text-align:center;
				justify-content:center;
				align-items:center;
				> div {

					&:nth-child(2){
						grid-column:1;
					}
				}
				.note {
					justify-content:center;
				}

				select[name="sort"] {
					margin:0 auto;
					float:none;
				}

			}

			select {
				padding:8px 40px 8px 20px;
				font-size:12px;
				font-style:normal;
				font-weight:500;
				line-height:183.333%;
				letter-spacing:0.6px;
				text-transform:uppercase;
				background:transparent;
				background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'%3E%3Cpath d='M8 1L4.39909 5L0.798144 1' stroke='%235D5A88' stroke-linecap='round'/%3E%3C/svg%3E");
				background-repeat:no-repeat;
				background-position:85% center;
				border:1px solid Config.color('text');
				color:Config.color('text');
				appearance:none;
				float:right;
			}

			h2 {
				font-size:34px;
				font-style:normal;
				font-weight:400;
				line-height:117.647%;
				letter-spacing:-0.34px;
				margin:0 auto;
				padding:0;
				text-align:center;
			}

			.rating {
				padding-top:5px;
				justify-content:center;
			}
		}


		.note {
			font-size:11px;
			font-style:italic;
			padding:0px 0;
			display:flex;
			align-items:center;
			line-height:normal;
			white-space:nowrap;
			a {
				font-weight:500;
				text-decoration:underline;
				display:inline-block;
				margin-right:2px;
			}
			svg {
				width:12px;
				height:12px;
				min-width:12px;
				padding-right:5px;
				path {
					stroke:Config.color('text');
				}
			}
		}
		.none {
			padding:100px 0;
			text-align:center;
		}
		.reviews {
			position:relative;

			figure {
				padding:40px 0;
				margin:0;
				display:grid;
				grid-template-columns:15% 65% 20%;
				gap:var(--container-padding);
				position:relative;

				@media all and (max-width:800px){
					grid-template-columns:15% 80%;
				}

				@media all and (max-width:550px){
					grid-template-columns:100%;
				}

				figcaption {
					max-width:150px;
					span {
						display:block;
						font-size:13px;
						line-height:100%;
						padding-bottom:10px;
					}
				}

				blockquote {
					margin:0;

					.rating {
						padding-bottom:1em;
						svg {
							width:80px;
							height:auto;
						}
					}
					h3 {
						font-size:28px;
						font-style:normal;
						font-weight:600;
						line-height:100%;
						margin:0;
					}
					q {
						font-size:16px;
						font-style:normal;
						font-weight:400;
						line-height:150%;
						padding:1em 0;
						margin:0;
						display:block;
						&:before,
						&:after {
							content:'';
						}
					}

					.actions {
						display:flex;
						align-items:center;

						strong {
							font-size:12px;
							font-style:normal;
							font-weight:600;
							line-height:163.636%;
							letter-spacing:1px;
							padding-right:10px;
						}

						div {
							display:flex;
							gap:10px;
						}
					}
				}
			}
		}

		.pagination {
			border-top:1px solid Config.color('sage');
			padding-top:30px;
			margin-top:30px;
			justify-content:center;
		}
	}