
	/**
	*
	* 		FORMS
	*			- Form Styles should go in here
	*
	**/
	@use '../_config' as Config;
	
	form {

		.success {
			background:#FFF;
			border:1px solid Config.color('sage');
			padding:20px;
		}

		.failed {
			background:#FFF;
			border:1px solid red;
			color:red;
			padding:10px 20px;
			margin-bottom:20px;
		}

		.error {
			font-size:75%;
			text-transform:lowercase;
			margin:0;
			color:Config.color('brand');
			font-style:italic;
			font-weight:600;
		}

		//Row
		.row {
			display:flex;
			width:100%;
			gap:10px;
			padding:5px 0;
			> div {
				width:100%;
			}
			@media all and (max-width:450px){
				flex-direction:column;
			}
		}

		label {
			display:none;
		}

		input,
		select,
		.checkbox > div,
		textarea {
			font-size:15px;
			border:0.5px solid Config.color('sage');
			font-family:Config.font('primary');
			color:Config.color('text');
			padding:8px 10px;
			box-sizing:border-box;
			width:100%;
			outline:none;

			&::placeholder {
				color:Config.color('text');
				font-style:italic;
			}
			&[readonly]{
				cursor:not-allowed;
			}
		}

		.checkbox {
			display:flex;
			align-items:center;
			gap:10px;

			> div {
				display:inline-block;
				width:auto;
				background:#FFF;
				padding:0;
				margin:0;
				input {
					display:none;
					&:checked + label {
						background:Config.color('brand');
					}
				}
				label {
					display:block;
					width:10px;
					height:10px;
					margin:2.5px;
					cursor:pointer;
				}
			}
			p {
				font-size:12px;
				margin:0;
				cursor:pointer;
			}
		}

		select {
			appearance:none;
			background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'%3E%3Cpath d='M8 1L4.39909 5L0.798144 1' stroke='%235D5A88' stroke-linecap='round'/%3E%3C/svg%3E");
			background-repeat:no-repeat;
			background-position:95% center;
		}

		textarea {
			min-height:150px;
		}

	}
