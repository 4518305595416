
	/**
	*
	* 		Banner
	*			- A Page Banner
	*
	**/
	@use '../_config' as Config;

	//Product Card
	.banner {
		min-height:300px;
		width:100%;
		background-size:cover;
		background-position:center center;
		position:relative;
		overflow:hidden;
		display:flex;

		@media all and (max-width:600px){
			min-height:200px;
		}

		> img {
			object-fit:cover;
			height:110%;
		}

		.bg {
			position:relative;
			z-index:1;
			background:rgba(0,0,0,.3);
			flex:1;
		}

		.video {
			width:100%;
			height:100%;
			position:absolute;
			top:0;
			left:0;

			iframe {
				position:absolute;
  				transform: translate(-50%, -50%);
				top:50%;
				left:50%;
				width:100%;
				height:100%;
				@media (min-aspect-ratio: 16/9) {
					height: 56.25vw;
				}
				@media (max-aspect-ratio: 16/9) { 
					width: 177.78vh;
				}
			}
			
			.poster {
				object-fit:cover;
				z-index:-1;
			}
		}

		.container {
			display:flex;
			height:100%;

			h1 {
				margin:auto 0;
				font-weight:normal;
				color:#FFF;
				text-align:center;
				font-size:40px;
				letter-spacing:0.49px;
				line-height:43px;
				align-self:center;
				+ p {
					font-weight:400;
					font-size:18px;
					line-height:30px;
					letter-spacing:.49px;
					color:#fff;
					max-width:500px;
					margin:30px auto;
				}
			}
		}

		&.empty {
			min-height:0;
			.container {
				display:block;
				h1 {
					margin:30px auto;
					font-style:italic;
					color:Config.color('text');
					+ p {
						color:Config.color('text');
					}
				}
				+ .collection {
					padding-top:15px;
				}
			}
		}

		//Parallax
		&.parallax {
			position:relative;
			.wrap {
				position:absolute;
				z-index:1;
				width:100%;
				height:100%;
			}
			.bg {
				z-index:0;
				> div {
					clip: rect(0, auto, auto, 0);
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					z-index:0;
					> div {
						position:fixed;
						display:block;
						top:0;
						left:0;
						width:100%;
						height:100%;
						transform:translateZ(0);
						will-change:transform;
						index:1;
						background-position:center center;
						background-size:cover;
						background-attachment:scroll;
					}
				}
				img {
					visibility:hidden;
				}
			}
		}

		&.fullpage {
			min-height:600px;
			height:100vh;
			width:100%;
		}
	}


	//FEATURE BANNER
	.feature {
		padding-top:0;

		header#header:not(.open) {
			transition:0.5s ease all;
			box-shadow:none;
			#navigation {
				background:transparent;
				.container {
					.logo {
						transition:0.5s ease filter;
						img {
							transition:0.5s ease opacity;
						}
					}
					nav {
						.dropown > a,
						a {
							transition:0.5s ease color;
						}
						.language {
							hr {
								transition:0.5s ease background;
							}
							a {
								transition:0.5s ease color;
							}
						}
						.cart {
							.count {
								transition:0.5s ease font-weight;
							}
						}
					  	[data-mobile-menu] {
					  		span {
					  			transition:0.5s ease background;
					  		}
					  	}
					}
				}
			}
			&:not(:hover):not(.scrolled) {
				background:transparent;
				#navigation {
					border-bottom:none;
					.container {
						.logo {
							svg {
								path {
									fill:#FFF;
									stroke-width:1.5;
								}
							}
						}
						nav {
							.dropdown > a,
							a {
								color:#FFF;
							}

							.language {
								hr {
									background:#FFF;
								}
								button {
									color:#FFF;
									&.selected {
										color:#FFF;
									}
								}
							}
							.cart {
								.count {
									font-weight:bold;
								}
							}
						  	[data-mobile-menu] {
						  		span {
						  			background:#FFF;
						  		}
						  	}
						}
					}
				}
			}
		}

		.banner {
			min-height:600px;
			height:100vh;
			width:100%;
			//Large Banner
			.content {
				text-align:center;
				margin:auto;
				color:#FFF;
				padding:0 var(--container-padding);

				h2 {
					margin:0;
					padding:0;
					font-size:56px;
					line-height:114.286%;
					letter-spacing:-0.56px;
					font-weight:400;

					@media all and (max-width:600px){
						font-size:42px;
						line-height:119.048%;
					}
					@media all and (max-width:400px){
						font-size:38px;
					}
				}

				p {
					text-align:center;
					font-size:16px;
					line-height:157.143%;
					font-weight:500;
					padding:20px 0 10px;
					max-width:462px;
					margin:0 auto;
				}

				.buttons {
					padding-top:30px;
					display:flex;
					align-items:center;
					justify-content:center;
					gap:20px;
				}
			}
		}
	}