
	/**
	*
	* 		Article Snippet
	*			- A Snippet of an Article
	*
	**/
    @use '../_config' as Config;
    
	article.snippet {
        padding-bottom:0;

        .image {
            position:relative;
            overflow:hidden;
            a {
                display:block;
                color:Config.color('text');
                transition:0.5s ease transform;
                background-size:cover;
                background-position:center center;

                img {
                	display:block;
                    object-position:center center;
                    object-fit:cover;
                    width:100%;
                }

                .icons {
                    position:absolute;
                    transition:0.5s ease transform;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    display:flex;
                    z-index:1;
                    i {
                        margin:auto;
                        font-size:60px;
                        color:#FFF;
                    }
                }

                &:hover {
                    transform:scale(1.1);
                }
            }
        }

        .details {
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding-top:5px;
            time {
                font-size:12px;
                font-family:Config.font('primary');
                letter-spacing:0.35px;
                line-height:30px;
                margin:auto 0;
            }
        }

        h3 {
            margin:0;
            font-family:Config.font('headline');
            font-size:28px;
            line-height:43px;
            text-align:left;
            max-width:100%;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            font-weight:300;
            padding-top:10px;
            a {
                color:Config.color('text');
                text-decoration:none;
                &:hover {
                    color:Config.color('brand');
                }
            }
        }

        p {
            font-size:16px;
            font-family:Config.font('primary');
            font-weight:300;
            letter-spacing:0.38px;
            line-height:24px;
            padding-bottom:10px;

            a {
                color:Config.color('text');
                text-decoration:none;
                span {
                    text-decoration:underline;
                }
                &:before {
                    content:'…';
                }
            }
        }

    }