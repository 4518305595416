
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use '../_config' as Config;

	#pbusiness.trial {

		.banner {
			min-height:100vh;
			.box {
				margin:auto auto auto 0;
				> div {
					background:#FFF;
					padding:50px 40px;
					text-align:left;
					max-width:400px;
					margin:50px 0;

					small {
						display:block;
						color:Config.color('text');
						font-family:Config.font('primary');
						font-size:11px;
						font-style:normal;
						font-weight:500;
						line-height:109.091%;
						letter-spacing:1.056px;
						text-transform:uppercase;
						padding-bottom:14px;
					}

					h1 {
						color:Config.color('text');
						font-family:Config.font('headline');
						font-size:48px;
						font-style:normal;
						font-weight:400;
						line-height:104.167%;
						text-align:left;
						span {
							display:block;
						}
					}

					p {
						font-size:16px;
						line-height:145%;
						font-weight:400;
						color:Config.color('text');
					}
				}
			}
		}

		#schedule {
			margin:0 auto 50px;
			position:relative;
			max-width:865px;
			background:#F7F7F7;
			padding:52px 80px 66px;

			.offer {
				> img {
					position:absolute;
					max-width:180px;
					width:100%;
					height:auto;
					aspect-ratio:1/1;
					display:block;
					top:calc( -180px * 0.25 );
					left:calc( -180px * 0.25 );
				}
				> p {
					display:none;
					background:Config.color('brand');
					color:#FFF;
					font-family:Config.font('headline');
					font-size:18px;
					padding:10px 0;
					text-align:center;
					font-style:italic;
					text-transform:lowercase;
				}
			}

			header {

				h2 {
					color:Config.color('text');
					font-family:Config.font('headline');
					font-size:43px;
					font-style:italic;
					font-weight:400;
					line-height:43px;
					max-width:584px;
					margin:0 auto;
					text-align:center;
					padding-bottom:33px;
				}

			}

			footer {
				p {
					font-size:80%;
					font-style:italic;
				}
			}

			@media all and (max-width:1150px){
				background:none;
				padding:25px var(--container-padding) 33px;
				.offer {
					> img {
						position:relative;
						margin:0 auto;
						top:auto;
						left:auto;
						width:150px;
						height:150px;
						padding-bottom:50px;
					}
				}
			}
		}

		hr {
			background:Config.color('offset');
			border:none;
			height:1px;
			margin:100px 0;
		}

	}
