
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	@use '../base/_layout.scss' as *;

	#particle {

		.subcontainer {
			@extend .container;
			max-width:calc( 750px + ( var(--container-padding) * 2 ) );
		}

		[role="article"] {

	        header#title {
	            margin:50px auto 20px;

	            h1 {
	                margin:auto 0;
	                font-family:Config.font('headline');
	                font-weight:normal;
	                text-align:left;
	                font-size:40px;
	                line-height:43px;
	                font-weight:100;
	            }

	            .details {
	                display:flex;
	                justify-content:space-between;
	                flex-wrap:wrap;
	                padding-top:10px;

	                > div {
	                    font-family:Config.font('primary');
	                    font-size:12px;
	                    line-height:43px;
	                    letter-spacing:1px;
	                    text-transform:uppercase;
	                    display:flex;
	                    margin:auto 0;
	                    white-space:nowrap;
	                    &:first-child {
	                        margin-right:10px;
	                    }

	                    nav.tags {
	                        display:inline-block;
	                    }
	                    time {
	                        font-size:12px;
	                        margin-left:5px;
	                        &:before {
	                            content:' | ';
	                        }
	                    }

	                    &.share {
	                        display:flex;
	                        margin:auto 0;
	                        font-weight:500;
	                        span {
	                        	padding-right:5px;
	                        }

	                        a {
	                            font-size:18px;
	                            text-decoration:none;
	                            color:Config.color('text');
	                            transition:0.3s ease color;
	                            cursor:pointer;
	                            margin:0 4px;
	                            position:relative;
	                            top:1px;
	                            &:hover {
	                                color:Config.color('brand');
	                            }
	                        }
	                        @media all and (max-width:350px){
	                            span {
	                                display:none;
	                            }
	                        }
	                    }
	                }
	            }

	        }


	        /**
	        *
	        *   IMAGE
	        *
	        **/
	        > .image {
	            padding-bottom:40px;
	            img {
	            	object-fit:cover;
	            	object-position:center center;
	            	display:block;
	            	width:100%;
	            	height:auto;
	            }

	            @media all and (max-width:750px){
	                padding:0 0 40px 0;
	                max-width:none;
	            }
	        }

	        /**
	        *
	        *   VIDEO
	        *
	        **/
	        > .video {
	            padding-bottom:40px;
	            div {
	                padding-top:25px;
	                padding-bottom:56.25%;
	                height:0;
	                position:relative;
	                iframe {
	                    position:absolute;
	                    top:0;
	                    left:0;
	                    width:100%;
	                    height:100%;
	                }
	            }
	        }


	        /**
	        *
	        *   BODY
	        *
	        **/

	        .body {
	            padding-bottom:50px;

	            .shogun-root > .shg-c {
	                padding-bottom:40px;

	                .shg-c {
	                    .shg-rich-text {
	                        padding-bottom:0;
	                    }
	                }

	            }

	            .html,
	            .shg-rich-text {
	                font-family:Config.font('primary');
	                font-size:18px;
	                line-height:200%;
	                text-align:left;
	                padding-bottom:40px;

	                img {
	                	max-width:100%;
	                	width:auto;
	                	height:auto;
	                }

	                p {
	                    margin:0;
	                    + p {
	                        padding-top:40px;
	                    }
	                }
	                a {
	                	text-decoration:underline;
	                	transition:0.3s ease color;
	                	&:hover {
	                		color:Config.color('brand');
	                	}
	                }
	            }


	            .subcontainer {
		            > h2,
		            > h3,
		            > h4,
		            > h5,
		            > h6 {
		                margin:auto 0;
		                font-family:Config.font('headline');
		                font-weight:normal;
		                text-align:left;
		                font-weight:300;
		                padding-bottom:30px;
		                line-height:normal;
		            }

		            > h2 {
		                font-size:35px;
		            }

		            > h3 {
		                font-size:30px;
		            }

		            > h4 {
		                font-size:25px;
		            }

		            > h5 {
		                font-size:20px;
		            }

		            > h6 {
		                font-size:17px;
		            }
		        }


	            .block {
	            	margin-top:100px;
	                margin-bottom:100px;

	                @media all and (max-width:650px){
	                    padding:50px 0;
	                }
	            }


	            //Quote
	            .quote {
	                padding-top:40px;
	                padding-bottom:60px;
	                max-width:950px;
	                text-align:center;
	                text-transform:lowercase;
	                font-style:italic;

	                q {
	                    font-family:Config.font('headline');
	                    font-weight:normal;
	                    font-size:40px;
	                    line-height:47px;
	                    display:block;
	                    color:Config.color('text');
	                    &:before,
	                    &:after {
	                        content:'"';
	                    }

	                    @media all and (max-width:755px){
	                        font-size:26px;
	                        line-height:35px;
	                    }
	                }

	                cite {
	                    display:block;
	                    font-size:18px;
	                    letter-spacing:0.49px;
	                    line-height:30px;
	                    font-style:normal;
	                    padding-top:20px;

	                    @media all and (max-width:755px){
	                        font-size:16px;
	                        letter-spacing:0.44px;
	                        line-height:30px;
	                    }
	                }
	            }


	            //Statement (Large Text, similar to quote but darker and no author)
	            .statement {
	                padding-top:40px;
	                padding-bottom:60px;
	                text-align:left;

	                h3 {
	                    font-family:Config.font('primary');
	                    font-size:18px;
	                    line-height:30px;
	                    letter-spacing:1px;
	                    text-align:left;
	                    margin:0 0 10px;
	                    text-transform:uppercase;
	                }

	                p {
	                    font-family:Config.font('headline');
	                    font-weight:normal;
	                    font-size:38px;
	                    letter-spacing:0.61px;
	                    line-height:47px;
	                    display:block;
	                    margin:0;

	                    @media all and (max-width:755px){
	                        font-size:26px;
	                        letter-spacing:0.4px;
	                        line-height:35px;
	                    }
	                }
	            }



	            //Featured Products
	           .featured {
					padding:0 var(--container-padding) 110px;
					header {
						padding-bottom:30px;
						h2 {
							font-size:42px;
							font-weight:400;
							line-height:119.048%;
							text-align:center;
						}
					}

					footer {
						text-align:center;
						padding-top:50px;
					}
				}


	            .download {
	                background:color.adjust( Config.color('sage') , $lightness:20% );
	                padding:30px 40px 30px 40px;
	                margin:0 auto 40px;
	                display:block;
	                cursor:pointer;
	                text-decoration:none;
	                transition:0.3s ease all;
	                width:100%;
	                display:flex;


	                @media all and (max-width:550px){
	                    flex-direction:column-reverse;
	                }

	                .content {
	                    padding-right:5%;

	                    span {
	                        font-family:Config.font('headline');
	                        font-size:32px;
	                        line-height:40px;
	                        letter-spacing:0.61px;
	                        text-align:left;
	                        font-weight:300;
	                        margin:0 0 15px 0;
	                        display:block;

	                        + span {
	                            font-family:Config.font('primary');
	                            font-size:18px;
	                            line-height:26px;
	                            letter-spacing:0.49px;
	                            text-align:left;
	                            font-weight:300;
	                            margin:0;
	                        }
	                    }

	                }

	                > .icon {
	                    min-width:105px;
	                    min-height:105px;
	                    margin:auto;
	                    transition:0.3s ease all;

	                    @media all and (max-width:550px){
	                        min-width:50px;
	                        min-height:50px;
	                        margin:0 0 20px;
	                    }
	                }

	                &:hover {
	                    background:#3A3939;
	                    color:#FFF;
	                    .icon {
	                        stroke:#FFF !important;
	                    }
	                }
	            }



	            .recipe {
	                //border-top:1px solid #D9D9D9;
	                //border-bottom:1px solid #D9D9D9;
	                padding:30px;
	                margin:60px auto;

	                @media all and (max-width:600px){
	                    margin:0;
	                }

	                h2 {
	                    font-family:Config.font('headline');
	                    font-size:32px;
	                    line-height:30px;
	                    text-align:left;
	                    font-weight:300;
	                }

	                .overview {
	                    display:flex;
	                    justify-content:space-between;
	                    font-family:Config.font('primary');
	                    font-size:15px;
	                    line-height:30px;
	                    letter-spacing:1px;
	                    text-align:left;
	                    margin:10px 0;
	                    padding:15px 40px;
	                    //background:#F7F7F7;
	                    border:1px dashed Config.color('sage');
	                    flex-wrap:wrap;
	                    gap:20px;
	                    white-space:nowrap;

	                    @media all and (max-width:650px){
	                        div {
	                            width:calc( 50% - 20px );
	                        }
	                    }

	                    @media all and (max-width:400px){
	                        gap:0;
	                        border:none;
	                        padding:15px 0;
	                        div {
	                            width:100%;
	                            margin:10px 0;
	                        }
	                    }

	                    strong {
	                        display:block;
	                        text-transform:uppercase;
	                        font-size:12px;
	                    }
	                }

	                .ingredients,
	                .directions {
	                    font-family:Config.font('primary');
	                    font-size:18px;
	                    line-height:30px;
	                    text-align:left;
	                    font-weight:300;
	                    padding:0;

	                    &.ingredients {
	                        list-style:none;
	                    }

	                    h4 {
	                        font-family:Config.font('headline');
	                        font-size:24px;
	                        line-height:30px;
	                        text-align:left;
	                        font-weight:600;
	                        margin:60px 0 0;
	                    }

	                    ol, ul {
	                        li {
	                            padding-bottom:10px;
	                            @media all and (max-width:550px){
	                                padding-bottom:25px;
	                            }
	                        }
	                    }
	                }

	            }


	        }

	    }


	    #conversations {
	        padding:100px 0;
	        border-top:1px solid Config.color('sage');

	        @media all and (max-width:450px){
	            padding-top:40px;
	        }

	        header {
	            position:relative;
	            padding:20px 24px 40px;

	            h2 {
					font-size:42px;
					font-weight:400;
					line-height:119.048%;
					text-align:center;
					margin:0;
	            }

	            .summary {
	                width:100%;
	                padding-bottom:15px;
	                text-align:center;
	                position:relative;
	            	.actions {
	            		padding-top:20px;
	            	}
	            }

	        }


	        #comments {
	        	position:relative;

	            ul {
	                list-style:none;
	                padding-left:0;
	                li {
	                    border-top:none;
	                    padding:40px 24px;
	                    border-bottom:1px dashed Config.color('sage');

	                    &:last-child {
	                        padding-bottom:75px;
	                        border-bottom:none;
	                    }

	                    @media all and (max-width:450px){
	                        padding:20px 0;
	                        &:last-child {
	                            padding-bottom:50px;
	                        };
	                    }

	                    .wrap {
	                        width:90%;
	                        margin:0 auto;
	                        display:flex;
	                        color:Config.color('text');
	                        font-weight:normal;
	                        font-style:normal;
	                        align-items:center;

	                        @media all and (max-width:600px){
	                            display:block;
	                        }

	                        .header,
	                        .footer {
	                            p {
	                                margin:0;
	                            }
	                        }

	                        .header {
	                            width:20%;
	                            max-width:280px;
	                            strong {
	                            	font-family:Config.font('headline');
	                            	font-style:italic;
	                            	text-transform:lowercase;
	                            	font-size:25px;
	                            }
	                            small {
	                            	font-style:italic;
	                            }
	                            @media all and (max-width:600px){
	                                display:none;
	                            }
	                        }

	                        .content {
	                            width:80%;
	                            margin:0 auto;
	                            font-size:16px;
	                            line-height:160%;

	                            p:first-child {
	                                margin-top:0;
	                            }
	                            @media all and (max-width:650px){
	                                width:100%;
	                                max-width:100%;
	                            }
	                        }

	                        .footer {
	                            display:none;
	                            @media all and (max-width:650px){
	                                padding-top:10px;
	                                display:block;

	                                .spr-review-header-byline span {
	                                    display:inline-block;
	                                    font-style:italic;
	                                    color:Config.color('text');
	                                    font-weight:normal;
	                                    &.name {
	                                        padding-right:5px;
	                                        &:after {
	                                            content:', ';
	                                        }
	                                    }
	                                }
	                            }
	                        }

	                        .title {
	                            font-weight:bold;
	                            line-height:18px;
	                            padding-bottom:10px;
	                        }

	                    }

	                }
	            }

	        }
	    }



	    .related {
	        border-top:1px solid #D9D9D9;
	        padding:50px 0 100px;

	        h4 {
	            font-family:Config.font('primary');
	            font-size:18px;
	            line-height:43px;
	            letter-spacing:1.58px;
	            text-align:center;
	            color:#4C4B4B;
	            font-weight:300;
	            text-transform:uppercase;
	        }

	        .list {
	            display:flex;
	            padding-top:40px;

	            @media all and (max-width:500px){
	                flex-direction:column;
	            }

	            article {
	                border:1px solid #C7C7C7;
	                display:flex;
	                padding:10px;
	                margin:0 auto;

	                @media all and (min-width:500px) and (max-width:800px), (max-width:400px){
	                    flex-direction:column;
	                }

	                .content {
	                    max-width:210px;
	                    padding:0 20px;

	                    .tags {
	                        a {
	                            text-decoration:none;
	                            &:not(&:last-child){
		                            &:after {
		                                content:', ';
		                            }
		                        }
	                        }
	                    }

	                    p {
	                        font-family:Config.font('headline');
	                        font-size:16px;
	                        text-align:left;
	                    }

	                    a {
	                        font-family:Config.font('primary');
	                        font-size:10px;
	                        line-height:32px;
	                        letter-spacing:1.9px;
	                        text-align:left;
	                        color:#252525;
	                        text-decoration:underline;
	                        transition:0.1s ease all;
	                        text-transform:uppercase;
	                        &:hover {
	                            color:#000;
	                        }
	                    }
	                }
	            }

	        }
	    }


	}


	#comment-form {
		footer {
			padding-top:10px;
			a {
				padding-left:10px;
				text-decoration:underline;
				cursor:pointer;
			}
		}
	}
