
	/**
	*
	* 		Country Selector
	* 			- The Country Selector top banner
	*
	**/
	@use '../_config' as Config;
	
	header#header {
		#countrySelector {
			overflow:hidden;
			height:30px;
			text-align:center;
			transition:0.5s ease height;
			position:relative;
			background:Config.color('brand');
			color:#FFF;
			font-size:12px;
			display:flex;
			p {
				display:inline-block;
				margin:auto;
				a {
					margin-left:5px;
					display:inline-block;
					color:#FFF;
					text-decoration:underline;
				}
			}
			.close {
				position:absolute;
				right:10px;
				height:100%;
				display:flex;
				a {
					color:#FFF;
					font-weight:bold;
					margin:auto;
				}
			}
		}
		&.scrolled {
			#countrySelector {
				height:0px;
			}
		}
	}

	.geolocation {
		padding-top:30px;
	}