
	/**
	*
	* 		Product
	*			- The Product Page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	#pproducts {

		#product {

			.grid {
				display:grid;
				grid-template-columns: repeat(3, minmax(0, 1fr));
				justify-content:space-between;
				gap:calc( var(--container-padding) * 2 );
				position:relative;
				min-height:100vh;
				padding-bottom:150px;
				.col {
					position:relative;
					.sticky {
						position:sticky;
						top:20px;
					}
				}
				.pin-spacer {
					.wrap {
						position:absolute;
					}
				}

				&.auto {
					min-height:auto;
				}
			}


			//Details
			#overview {
				header {
					padding-bottom:15px;
					.title {
						display:flex;
						justify-content:space-between;
						align-items:center;
						padding-bottom:6px;
						flex-wrap:wrap;
						h1 {
							font-size:56px;
							font-style:normal;
							font-weight:400;
							line-height:114.286%;
							letter-spacing:-0.56px;
							margin:0;
						}
						p.price {
							font-family:Config.font('headline');
							font-size:34px;
							font-style:normal;
							font-weight:400;
							line-height:117.647%;
							letter-spacing:-0.34px;
							opacity:.5;
							margin:0;
							svg {
								padding-right:3px;
							}
							.was {
								padding-right:5px;
								font-size:80%;
								position:relative;
								top:-3px;
								opacity:.5;
							}
						}
					}

					.type {
						font-size:11px;
						font-style:normal;
						font-weight:500;
						line-height:163.636%;
						letter-spacing:1px;
						text-transform:uppercase;
					}
				}
				> p {
					font-size:16px;
					font-style:normal;
					font-weight:400;
					line-height:150%;
				}
			}
		}

		.gallery {
			> div {
				position:relative;
				img {
					width:100%;
					display:block;
					transition:0.3s ease opacity;
					object-fit:cover;
					height:auto;
					position:absolute;
					opacity:0;
					transition:0.3s ease opacity;
					top:0;
					left:0;
					&.selected {
						position:relative;
						opacity:1;
					}
				}
			}

			footer {
				padding-top:15px;
				display:flex;
				flex-wrap:wrap;
				gap:11px;
				justify-content:center;
				img {
					display:block;
					border:1px solid transparent;
					cursor:pointer;
					filter:grayscale(0.5);
					object-fit:cover;
					&.selected {
						border:1px solid Config.color('text');
						filter:none;
					}
				}
			}
		}

		.cart-form {
			.submit,
			.button {
				width:100%;
			}
		}

		.currency {
			display:flex;
			gap:5px;
			padding-bottom:25px;
			justify-content:flex-start;

			h3 {
				font-family:Config.font('primary');
				text-transform:uppercase;
				font-weight:400;
				letter-spacing:1.32px;
				line-height:18px;
				font-size:10px;
				margin:0;
			}

			@media all and (max-width:400px){
				flex-direction:column;
				.toggle {
					margin:auto 0 auto auto;
				}
			}
		}

		.batch {
			header {
				border-top:1px solid Config.color('text');
				padding:15px 0;
				display:flex;
				align-items:center;
				font-size:14px;
				font-style:normal;
				line-height:157.143%;
				letter-spacing:0.7px;
				text-transform:uppercase;
				svg {
					padding-right:10px;
				}
				strong {
					font-weight:500;
				}
			}
			[data-batch="form"] {
				input[type="text"] {
					font-size:14px;
					line-height:157.143%;
					padding:10px 20px;
				}
				+ 	a {
					font-size:12px;
					font-weight:400;
					line-height:150%;
					text-decoration:underline;
				}
			}
		}

		#ingredient-block,
		#benefit-block {
			text-align:center;
			position:sticky;
			top:20px;
			img {
				width:90%;
				margin:0 auto;
				position:relative;
				object-fit:cover;
			}
			div {
				padding:102px 32.5px 50px;
				background:color.adjust( Config.color('sage'), $lightness: 22% );
				position:relative;
				top:-80px;
				z-index:-1;

				h2 {
					font-size:34px;
					font-style:normal;
					font-weight:400;
					line-height:117.647%;
					letter-spacing:-0.34px;
					margin:0 auto;
					max-width:90%;
					padding:0;
				}
				p {
					font-size:14px;
					font-style:normal;
					font-weight:400;
					line-height:157.143%;
					margin:0 0 20px;
					padding-top:12px;
				}
			}
		}


		#primary-notes,
		#key-benefits {
			text-align:center;
			header {
				h2 {
					font-size:34px;
					font-style:normal;
					font-weight:400;
					line-height:117.647%;
					letter-spacing:-0.34px;
				}
			}

			[data-viewport="mobile"] {
				header {
					padding-bottom:30px;
					h2 {
						margin-bottom:0;
					}
				}
				.swiper-parent {
					position:relative;
				}
				p {
					padding-top:10px;
				}
			}

			@media all and (min-width:751px){
				[data-viewport="mobile"] {
					display:none;
				}
			}

			@media all and (max-width:750px){
				[data-viewport="desktop"] {
					display:none;
				}
			}

			ul {
				padding:0;
				display:flex;
				flex-direction:column;
				gap:38px;
				li {
					max-width:340px;
					margin:0 auto;
					
				}
			}

			img {
				border-radius:100%;
				display:block;
				margin:0 auto;
			}

			h3 {
				font-size:14px;
				font-style:normal;
				font-weight:500;
				line-height:157.143%;
				letter-spacing:0.7px;
				text-transform:uppercase;
				font-family:Config.font('primary');
				margin-bottom:5px;
			}

			.details {
				span {
					text-align:center;
					font-size:10px;
					font-style:normal;
					font-weight:500;
					line-height:140%;
					color:Config.color('text');
					opacity:.5;
					letter-spacing:0.5px;
					display:block;
					&.inci,
					&.method {
						text-transform:uppercase;
					}
				}
			}

			p {
				font-size:14px;
				font-style:normal;
				font-weight:400;
				line-height:157.143%;
				max-width:350px;
				margin:10px auto 0;
			}

			nav {
				position:absolute;
				//width:calc( 100% + ( var(--container-padding) * 2 ) );
				top:calc( 50% - (42px/2));
				//left:calc( var(--container-padding) * -1 );
				width:100%;
				left:0;
				z-index:1;
				display:flex;
				justify-content:space-between;


				.button {
					padding:10px;
					&:first-child {
						svg {
							transform:rotate(180deg);
						}
					}
				}
			}

			footer {
				padding-top:20px;
			}
		}


		#whats-included {
			padding-bottom:100px;
			header {
				h2 {
					font-size:34px;
					font-style:normal;
					font-weight:400;
					line-height:117.647%;
					letter-spacing:-0.34px;
				}
			}
			.collection {
				grid-template-columns: repeat(4, minmax(0, 1fr));
				margin:0;
				padding:0;
				.image {
					overflow:hidden;
					width:100%;
					img {
						width:100%;
						height:auto;
						object-fit:cover;
					}
				}
				h3 {
					font-size:14px;
					font-style:normal;
					font-weight:500;
					line-height:157.143%;
					letter-spacing:0.7px;
					margin-bottom:0;
					text-transform:uppercase;
					font-family:Config.font('primary');
				}
				span {
					font-size:10px;
					font-style:normal;
					font-weight:500;
					line-height:140%;
					color:Config.color('text');
					opacity:.5;
					letter-spacing:0.5px;
					display:block;
					&.inci {
						text-transform:uppercase;
					}
				}
				p {
					font-size:14px;
					font-style:normal;
					font-weight:400;
					line-height:157.143%;
				}
			}
		}


		#usage {
			header {
				text-align:center;
				h2 {
					font-size:34px;
					font-weight:400;
					line-height:40px;
					letter-spacing:-0.34px;
					max-width:340px;
					margin:0 auto;
					span {
						display:inline-block;
					}
				}
				img {
					width:100%;
					padding:20px 0;
					object-fit:cover;
				}
			}
			.content {
				p {
					font-size:14px;
					font-style:normal;
					font-weight:400;
					line-height:22px;
				}
			}

			.full {
				margin-bottom:100px;
				header {
					padding-bottom:50px;
				}
				> div {
					display:flex;
					gap:5%;
					img {
						width:55%;
						height:100%;
					}
					> .content {
						display:flex;
						flex-direction:column;
						justify-content:space-between;
						width:40%;
						#safety {
							margin-top:20px;
							dd {

							}
						}
						@media all and (max-width:500px){
							padding-top:20px;
						}
					}
				}
				@media all and (max-width:900px){
					> div {
						display:block;
						img {
							width:100%;
						}
						> .content {
							width:100%;
						}
					}
				}
				@media all and (max-width:500px){
					margin-bottom:0;
				}
			}
		}

		#safety {
			padding:19px 0;
			border:1px solid Config.color('text');
			border-width:1px 0;
			dt {
				display:flex;
				justify-content:space-between;
				align-items:center;
				a {
					display:flex;
					justify-content:space-between;
					align-items:center;
					cursor:pointer;
					width:100%;
					h2 {
						font-family:Config.font('primary');
						font-size:14px;
						font-style:normal;
						font-weight:500;
						line-height:157.143%;
						letter-spacing:0.7px;
						text-transform:uppercase;
					}
					> span {
						width:10px;
						height:10px;
						> span {
							display:block;
							width:100%;
							height:1px;
							background:Config.color('text');
							position:relative;
							top:5px;
							+ span {
								transform:rotate(90deg);
								transition:0.3s ease transform;
								top:4px;
							}
						}
					}
				}
			}
			dd {
				max-height:0;
				overflow:hidden;
				transition:0.3s ease max-height;
				p {
					font-size:14px;
					font-style:normal;
					font-weight:400;
					line-height:22px;
				}
			}
			&.open {
				dt {
					a {
						> span {
							span {
								transform:rotate(0deg) !important;
							}
						}
					}
				}
				.content {
					max-height:200px;
				}
			}
		}

		#quote {
			position:sticky;
			top:20px;
			padding:51px 36px 61px;
			background:Config.color('brand');
			text-align:center;
			color:#FFF;
			width:100%;
			max-width:407px;
			margin:0 auto;
			box-sizing:border-box;
			q {
				font-family:Config.font('headline');
				display:block;
				font-size:24px;
				font-style:normal;
				font-weight:400;
				line-height:133.333%;
				padding:25px 0 10px;
			}
			cite {
				font-size:11px;
				font-style:normal;
				font-weight:600;
				line-height:18px;
				letter-spacing:1px;
				text-transform:uppercase;
			}
		}


		#story {
			margin-bottom:150px;
			overflow:hidden;


			.content {
				position:relative;
				background:#000;

				.wrap {
					position:relative;
					z-index:1;

					.grid {
						//padding:182px 0;
						padding-bottom:0;
						position:relative;
						z-index:1;

						.col {
							display:flex;
							align-items:center;
							justify-content:center;

							#caption {
								color:#FFF;
								text-align:center;
								h2 {
									font-size:42px;
									font-style:normal;
									font-weight:400;
									line-height:119.048%;
								}
								p {
									font-size:16px;
									font-style:normal;
									font-weight:400;
									line-height:150%;
									max-width:365px;
									margin:0 auto;
								}
							}

							#article {
								color:#FFF;
								max-width:405px;
								margin:0 auto;
								h2 {
									font-size:24px;
									font-style:normal;
									font-weight:400;
									line-height:133.333%;
								}
								p {
									font-size:16px;
									font-style:normal;
									font-weight:400;
									line-height:150%;
								}
								.word {
									display:inline-block;
								}
							}

							.buttons {
								display:none;
								text-align:center;
							}
						}
					}
				}

				.bg {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					z-index:0;
					img {
						object-fit:cover;
						object-position:center center;
					}
					.video {
						overflow:hidden;
						width:100%;
						height:100%;
						top:0;
						left:0;
						aspect-ratio:16/9;
						pointer-events:none;
						//position:absolute;
						z-index:0;
						@media all and (max-width:550px){
							display:none;
						}
						div {
							position: relative;
							height: 0;
							padding-top:56.25%;

							@media all and (max-width:730px){
								height:100%;
								padding-top:0;
							}

							iframe {
								clip-path: inset(1px 1px);
								position: absolute;
								top: 0;
								left: 0;
								width:200%;
								height:200%;
								transform:translate(-25%, -10%);

								@media all and (max-width:730px){
									width:300%;
									transform:translate(-25%, -25%);
								}
							}
						}
					}
				}
			}
		}


		#ideas {
			header {
				text-align:center;
				padding-bottom:30px;
				h2 {
					font-size:34px;
					font-weight:400;
					line-height:40px;
					letter-spacing:-0.34px;
					max-width:340px;
					margin:0 auto;
				}
				img {
					width:100%;
					padding:20px 0;
				}
			}

			dl {
				max-width:388px;
				margin:0 auto;
				dt {
					font-size:12px;
				    font-style:normal;
				    font-weight:500;
				    line-height:183.333%;
				    letter-spacing:0.6px;
				    text-transform:uppercase;
				    padding-bottom:10px;
				}
				dd {
					padding:0;
					margin:0 0 40px;
					img {
						max-width:100%;
						object-fit:cover;
					}
					h3 {
						font-size:14px;
						font-style:normal;
						font-weight:500;
						line-height:157.143%;
						letter-spacing:0.7px;
						text-transform:uppercase;
						font-family:Config.font('primary');
					}
					p {
						font-size:14px;
						font-style:normal;
						font-weight:400;
						line-height:22px;
					}
				}
			}
		}

		#profile-photo {
			object-fit:cover;
		}


		#certifications,
		#features {
			padding-bottom:0 !important;
			position:relative;

			+ #story {
				margin-top:50px;
			}

			//Grid Layout
			&.isgrid {
				.col {
					display:flex;
				}
				@media all and (min-width:801px){
					[data-viewport="mobile"] {
						display:none;
					}
				}

				@media all and (max-width:800px){
					[data-viewport="desktop"] {
						display:none;
					}
				}

				[data-viewport="mobile"] {
					padding-bottom:50px;
				}
			}

			//Non-grid layout
			&:not(.isgrid) {
				ul {
					display:flex;
					padding:50px 0;
					gap:50px;
					.icon {
						height:56px;
						display:flex;
						svg {
							margin:auto;
						}
					}
				}

				@media all and (min-width:1101px){
					[data-viewport="mobile"] {
						display:none;
					}
				}

				@media all and (max-width:1100px){
					[data-viewport="desktop"] {
						display:none;
					}
				}
			}

			ul {
				padding:130px 0;
				margin:auto;
				li {
					text-align:center;
					//max-width:360px;
					margin:0 auto 75px;
					position:relative;
					.line {
						display:none;
						width:10vw;
						height:1px;
						max-width:156px;
						background:Config.color('sage');
						position:absolute;
						top:100%;
						left:100%;
						&.top.left {
							top:100%;
							right:-11vw;
							transform:rotate(25deg);
						}
						&.top.right {
							top:100%;
							left:-11vw;
							transform:rotate(-25deg);
						}
						&.bottom.left {
							top:60%;
							right:-13vw;
							transform:rotate(160deg);
						}
						&.bottom.right {
							top:60%;
							left:-11vw;
							transform:rotate(205deg);
						}
					}
				}
			}

			.swiper-slide {
				text-align:center;
				p {
					max-width:250px;
					margin:0 auto;
				}
			}

			h2 {
				font-weight:400;
			}
			p {
				font-size:14px;
				font-style:normal;
				font-weight:400;
				line-height:22px;
			}
			.icon {
				svg {
					max-width:56px;
					max-height:56px;
					path {
						fill: currentcolor;
					}
				}
			}

			nav {
				position:absolute;
				width:100%;
				top:calc( 50% - (42px / 2 ) );
				left:0;
				z-index:1;
				display:flex;
				justify-content:space-between;

				.button {
					padding:10px;
					svg {
						transform:scale(0.8);
					}
					&:first-child {
						svg {
							transform:rotate(180deg) scale(0.8);
						}
					}
				}
			}
		}


		#refillable {

			#refill-intro {
				text-align:center;
				position:sticky;
				top:calc(50vh - (349px / 2));
				div {
					padding:65px 25px;
					background:Config.color('brand');
					color:#FFF;
					z-index:-1;

					h2 {
						font-size:36px;
						font-style:normal;
						font-weight:400;
						line-height:122.222%;
						margin:0;
						padding:0;
					}
					p {
						font-size:14px;
						font-style:normal;
						font-weight:400;
						line-height:157.143%;
						max-width:295px;
						margin:20px auto;
					}
				}
			}

			#how-to-refill {
				text-align:center;

				header {
					h2 {
						font-size:34px;
						font-style:normal;
						font-weight:400;
						line-height:117.647%;
						letter-spacing:-0.34px;
						margin:0;
					}
				}

				ul.list {
					//padding:37px 0 0;
					//display:flex;
					//flex-direction:column;
					//gap:25px;
					position:relative;
					height:308px;
					li {
						max-width:254px;
						margin:0 auto;
						position:absolute;
						top:0;
						left:calc( 50% - ( 254px / 2 ));
						&:nth-child(1){
							top:20px;
						}
					}
				}
						
				svg {
					max-width:145px;
					margin:0 auto 25px;
					display:block;
					height:auto;
				}

				h3 {
					font-family:Config.font('primary');
					font-size:12px;
					font-style:normal;
					font-weight:500;
					//line-height:116.667%;
					letter-spacing:0.6px;
					text-transform:uppercase;
					text-align:center;
					span {
						display:block;
					}
				}


			}

			@media all and (max-width:1100px){
				#how-to-refill {
					ul.list {
						padding:37px 0 0;
						display:flex;
						flex-direction:column;
						gap:25px;
						height:auto;
						li {
							position:relative;
							padding-bottom:50px;
							left:0;
							top:0;
						}
					}
				}
			}

			@media all and (max-width:750px){
				&.grid {
					background:Config.color('brand');
					margin:25px 0;
					padding:25px 0 !important;
					gap:0;

					[data-view="desktop"] {
						display:none !important;
					}

					#refill-intro {
						div {
							max-width:375px;
							margin:0 auto;
							padding:25px; 
						}
					}

					#how-to-refill {
						padding-bottom:25px;
						header {
							display:none;
						}
						.swiper {
							background:Config.color('background');
							aspect-ratio:1/1;
							max-width:304px;
							border-radius:100%;
							margin:0 auto 20px;
							.swiper-slide {
								margin:auto;
								display:flex;
								flex-direction:column;
								align-items:center;
								justify-content:center;
								padding:0 25px 25px;
								box-sizing:border-box;
								svg {
									margin-bottom:10px;
								}
								h3 {
									margin:0;
								}
								p {
									margin:0;
									font-size:12px;
								}
							}
						}
						nav {
							position:absolute;
							margin-top:-55px;
							width:100%;
							display:flex;
							justify-content:space-between;
							pointer-events:none;
							z-index:2;
							.button {
								padding:10px;
								pointer-events:all;
								position:absolute;
								right:0;
								background:none !important;
								border:none !important;
								margin-top:-15px;
								&:first-child {
									right:auto;
									svg {
										transform:rotate(180deg);
									}
								}
							}
							ul {
								margin:0;
								padding:0;
								display:flex;
								flex-direction:row;
								align-items:center;
								justify-content:center;
								width:100%;
								gap:10px;
								li {
									width:10px;
									height:10px;
									border-radius:100%;
									display:block;
									border:1px solid color.adjust( Config.color('brand') , $lightness:-20% );
									transition:0.3s ease border, 0.3s ease background;
									cursor:pointer;
									pointer-events:all;

									&.active {
										border:1px solid Config.color('brand');
										background:Config.color('brand');
									}

									&:hover {
										border:1px solid Config.color('brand');
									}
								}
							}
						}
					}
				}
			}

			@media all and (min-width:751px){ 
				[data-view="mobile"] {
					display:none !important;
				}
			}
		}


		#batch {
			.lookup {
				padding:50px 40px 61px;
				background:color.adjust( Config.color('sage'), $lightness: 22% );
				box-sizing:border-box;
				
				img {
					width:100%;
					height:auto;
					max-height:300px;
					object-fit:cover;
					display:none;
				}

				header {
					padding-bottom:20px;
					h2 {
						font-size:34px;
						font-style:normal;
						font-weight:400;
						line-height:117.647%;
						letter-spacing:-0.34px;
						margin:0 auto;
						padding:0;
					}
					p {
						font-size:14px;
						font-style:normal;
						font-weight:400;
						line-height:22px;
					}
				}

				.batch-form {
					form {
						box-sizing:border-box;
						min-width:0;
					}
				}
			}
		}


		.batch-info {
			border:1px solid Config.color('sage');
			padding:40px;
			box-sizing:border-box;

			ol {
				padding:0;
				margin:0;
				display:flex;
				flex-direction:column;
				justify-content:space-evenly;
				li {
					list-style:none;
					position:relative;
					margin:10px 0;

					.arrow {
						position:absolute;
						top:9px;
						right:103%;

						@media all and (max-width:750px){
							display:none;
						}

						> div {
							width:100px;
							height:1px;
							background:Config.color('text');

							> div {
							  	width: 0;
							  	height: 0;
							  	border-top:4px solid transparent;
							  	border-bottom:4px solid transparent;
							  	border-right:4px solid Config.color('text');
							  	position:relative;
							  	top:-3.5px;
							  	left:-3px;
							}

						}

					}

					strong {
						font-weight:600;
						font-size:14px;
						line-height:157.143%;
						margin:0 0 5px;
						display:block;
					}

					p {
						font-weight:400;
						font-size:14px;
						line-height:157.143%;
						margin:0;
					}
				}
			}
		}


		#video {
			position:absolute !important;
			z-index:3;
			left:0;
			width:100%;
			height:100%;
			padding:0;
			pointer-events:none;
			.grid {
				padding:0;
				height:100%;
				img,
				video {
					width:100%;
					height:100%;
				}
				.col {
					display:flex;
					div {
						margin:auto;
						--position:100vh;
						--scale:1;
						transform:scale( var(--scale) ) translate3d( 0 , var(--position) , 0 ); 
						//transition:0.1s ease transform;
						will-change:transform;
						width:100%;
						img,
						video {
							margin:auto;
							max-width:100%;
							position:relative !important;
							//left:-5%;
						}
					}
				}
			}
		}


		@media all and (max-width:1100px){
			#product {
				.grid {
					grid-template-columns:repeat(2,minmax(0,1fr));
					padding-bottom:80px;
					.col:nth-child(2){
						display:none;
					}
				}

				#details {
					.col:nth-child(2){
						display:block;
						grid-row:span 2;
					}
					.gallery {
						position:sticky;
						top:0;
					}
				}

				#ingredient-block {
					img {
						width:100%;
						display:block;
					}
					div {
						padding:25px 32.5px 50px;
						top:0;
						h2 {
							max-width:100%;
						}
					}
				}

				#story {
					.grid {
						padding:100px var(--container-padding);
						display:block;
						min-height:0;
						.col {
							display:block;
							#caption {
								max-width:405px;
								margin:0 auto 50px;
								h2 {
									margin:0 0 10px;
								}
							}
							#article {
								max-width:600px;
							}
							&:nth-child(2){
								display:none;
							}
						}
					}
				}

				#certifications {
					min-height:0;
					ul {
						padding:0;
						.line {
							display:none;
						}
					}
					.grid {
						min-height:0;
					}
				}

				.batch-info {
					position:sticky;
					top:0;
					.arrow {
						display:none;
					}
				}

				#video {
					display:none;
				}
			}

			#reviews {
				.wrap {
					padding:0;
				}
			}
		}


		@media all and (max-width:750px){
			#product {
				.grid {
					grid-template-columns:repeat(1,minmax(0,1fr));
					padding-bottom:50px;
					min-height:0;
					.col:nth-child(2){
						display:none;
					}
				}

				#details {
					.col {
						&:nth-child(2){
							grid-row:1;
						}
					}
					.gallery {
						> img {
							max-height:50vh;
						}
					}
				}

				#usage {
					.content {
						h3 {
							font-size:18px;
							font-weight:400;
							text-transform:lowercase;
							letter-spacing:-0.1px;
							margin:0;
						}
						p {
							font-size:15px;
							line-height:150%;
							+ h3 {
								padding-top:15px;
							}
						}
					}
				}

				#primary-notes {
					header {
						h2 {
							max-width:300px;
							margin:0 auto;
						}
					}
				}

				#ingredients {
					padding:0 0 100px;
					#ingredient-block {
						display:none;
					}

					#primary-notes {
						background:color.adjust( Config.color('sage'), $lightness: 22% );
						padding:50px var(--container-padding);
						img {
							width:100%;
							height:auto;
							max-width:200px;
						}
					}
				}

				#safety {
					margin-top:40px;
					padding:10px 0;
					dt {
						h2 {
							font-weight:600;
						}
					}
					dd {
						margin:0;	
					}
				}

				#quote {
					max-width:100%;
					padding:41px var(--container-padding);
				}

				#story {
					margin-bottom:50px;
					+ .grid {
						padding-bottom:0;
					}
				}

				#ideas {
					dt {
						font-weight:600;
						border-bottom:1px solid Config.color('sage');
						padding:10px 0;
						display:flex;
						justify-content:space-between;
						align-items:center;
						transition:0.6s ease padding-top;

						&:not(:first-child){
							cursor:pointer;
							> span {
								width:10px;
								height:10px;
								> span {
									display:block;
									width:100%;
									height:1px;
									background:Config.color('text');
									position:relative;
									top:5px;
									+ span {
										transform:rotate(90deg);
										transition:0.3s ease transform;
										top:4px;
									}
								}
							}
						}

						&:first-child {
							+ dd {
								max-height:100%;
								border-bottom:1px solid Config.color('sage');
							}
						}

						&.open {
							border-bottom:none;
							padding-top:20px;
							> span {
								span {
									transform:rotate(0deg) !important;
								}
							}
							+ dd {
								max-height:500px;
								border-bottom:1px solid Config.color('sage');
								padding-bottom:20px;
							}
						}
					}
					dl {
						max-width:100%;
						dd {
							max-height:0;
							overflow:hidden;
							margin:0;
							transition:0.6s ease max-height, 0.6s ease padding-bottom;
							img {
								width:100%;
								padding-bottom:10px;
							}
							h3 {
								font-size:16px;
								font-weight:600;
								text-transform:lowercase;
								letter-spacing:-0.1px;
								margin:0;
							}
							p {
								font-size:15px;
								line-height:150%;
							}
						}
					}
				}

				#profile-photo {
					display:none;
				}

				#certifications {
					grid-template-columns:repeat(2,minmax(0,1fr));
					.col {
						&:nth-child(2) {
							display:none !important;
						}
						ul {
							margin:0;
							li {
								svg {
									max-height:44px;
								}
							}
						}
					}
				}

				#batch {
					.lookup {
						padding:0;
						img {
							display:block;
						}
						header {
							padding:40px 40px 20px;
						}
						.batch-form {
							padding:0 40px 61px;
						}
					}
				}

				.batch-info {
					display:none;
				}
			}
		}

		@media all and (max-width:500px){
			#product {

				#ingredients {
					padding-bottom:50px;
				}

				#story {
					#article {
						p {
							transition:0.6s ease max-height;
							max-height:0;
							overflow:hidden;
							&:first-child {
								max-height:100%;
								&:after {
									content:'...'
								}
							}
						}

						&.open {
							p {
								max-height:300px;
								&:first-child {
									&:after {
										content:'';
									}
								}
							}
							+ .buttons {
								display:none;
							}
						}
					}
					.buttons {
						display:block;
					}
				}
			}

			#certifications {
				padding:50px var(--container-padding) !important;
				position:relative;
			}

			#manifest {
				background:color.adjust( Config.color('sage') , $lightness: 20% );
			}

			#instagram {
				padding-top:30px;
			}
		}



		/**
		*
		*	Basic Template Styles
		*
		**/
		#product {
			&.basic,
			&.giftcard {
				.grid {
					padding-bottom:150px;

					@media all and (max-width:800px){
						padding-bottom:100px;
					}

					@media all and (max-width:500px){
						padding-bottom:25px;
					}
				}
				#story {
					margin-bottom:100px;
				}
				#benefit-block {
					position:static;
					padding:0 var(--container-padding);
					> div {
						position:static;
						background:none;
						padding:0 0 50px;
						@media all and (max-width:500px){
							padding:50px 0;
						}
					}
				}
				#key-benefits {
					padding-bottom:100px;
					[data-viewport="desktop"] {
						header {
							display:none;
						}
						.list {
							flex-direction:row;

						}
					}
					[data-viewport="mobile"] {
						header {
							display:none;
						}
						.swiper-slide {
							p {
								padding:0 var(--container-padding);
							}
						}
						nav {
							width:100%;
							left:0;
						}
					}
				}
			}
		}

	}
