
	/**
	*
	* 		Batch
	*			- Batch Style(s)
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	.batch-form {

		.error {
			font-size:12px;
			font-style:italic;
			margin:0;
		}

		> a {
			font-size:12px;
			line-height:18px;
			line-height:150%;
			color:Config.color('text');
			transition:0.3s ease color;
			font-weight:600;
			cursor:pointer;
			&:hover {
				color:Config.color('sage');
				border:none;
			}
		}

		form {
			border:1px solid Config.color('sage');
			margin-top:6px;
			padding-right:10px;
			display:flex;
			min-width:265px;

			&.offline {
				position:relative;
				&:after {
					content:'Search Unavailable';
					position:absolute;
					width:100%;
					height:100%;
					line-height:33px;
					color:#000;
					font-weight:500;
					font-size:12px;
					text-align:center;
					background:rgba(245,245,245,.75);
				}
			}

			input {
				color:Config.color('text');
				background:transparent;
				line-height:150%;
				font-size:12px;
				font-weight:400;
				padding:10px;
				width:100%;
				outline:none;
				border:none;
				&[disabled] {
					cursor:not-allowed;
				}
				&::placeholder {
					color:Config.color('text');
				}
			}
			button {
				background:none;
				border:none;
				color:Config.color('text');
				font-weight:700;
				font-size:12px;
				line-height:150%;
				transition:0.3s ease color;
				cursor:pointer;
				&[disabled] {
					cursor:not-allowed;
				}
				&:hover:not([disabled]) {
					color:Config.color('sage');
				}
			}
		}
	}

	//Modal
	#modal .batch-locator {
		max-width:700px;

		header {
			text-transform:none !important;

			h5 {
				font-weight:400;
				font-size:32px;
				line-height:43px;
				letter-spacing:0.49px;
				margin:0;
			}

			p {
				font-weight:400;
				font-size:14px;
				line-height:157.143%;
			}

		}

		.details {
			display:flex;
			justify-content:space-between;
			padding-top:17px;

			@media all and (max-width:750px){
				flex-direction:column;
			}

			> div {

				&:first-child {
					background:color.adjust( Config.color('background'), $lightness:-2% );
					border:1px solid Config.color('sage');
					width:40%;
					max-width:164px;
					padding:3% 6%;
					display:flex;
					flex-direction:column;
					justify-content:space-evenly;
					gap:20px;



					@media all and (max-width:750px){
						width:100%;
					}

					img {
						max-width:225px;
						width:100%;
					}

					p {
						font-weight:500;
						font-size:12px;
						line-height:18px;
						letter-spacing:0.03em;
						margin:0;
						strong {
							text-transform:uppercase;
							display:block;
							margin-bottom:4px;
						}
					}
				}

				&:last-child {
					width:55%;

					@media all and (max-width:750px){
						width:100%;
						padding-top:30px;
					}

					ol {
						padding:0;
						margin:0;
						display:flex;
						flex-direction:column;
						justify-content:space-evenly;
						li {
							list-style:none;
							position:relative;
							margin:10px 0;

							.arrow {
								position:absolute;
								top:9px;
								right:103%;

								@media all and (max-width:750px){
									display:none;
								}

								> div {
									width:40px;
									height:1px;
									background:Config.color('text');

									> div {
									  	width: 0;
									  	height: 0;
									  	border-top:4px solid transparent;
									  	border-bottom:4px solid transparent;
									  	border-right:4px solid Config.color('text');
									  	position:relative;
									  	top:-3.5px;
									  	left:-3px;
									}

								}

							}

							strong {
								font-weight:600;
								font-size:14px;
								line-height:157.143%;
								margin:0 0 5px;
								display:block;
							}

							p {
								font-weight:400;
								font-size:14px;
								line-height:157.143%;
								margin:0;
							}
						}
					}
				}
			}
		}
	}


	//Modal
	.batch-details {
		max-width:500px;

		header {
			text-transform:none !important;

			h5 {
				font-weight:400;
				font-size:32px;
				line-height:43px;
				margin:0;
			}

			p {
				font-weight:500;
				font-size:14px;
				margin:0 0 20px;
			}

		}

		.details {
			margin:0;
			padding:0;
			li {
				list-style:none;
				padding:0;
				h3 {
					margin:0 10px;
					font-family:Config.font('primary');
					font-size:14px;
					text-transform:capitalize;
					font-weight:500;
					padding-bottom:5px;
				}
				a {
					font-weight:600;
					font-size:11px;
					line-height:21px;
					letter-spacing:0.04em;
					text-decoration:none;
					transition:0.3s ease all;
					text-transform:uppercase;
					&:hover {
						color:Config.color('brand');
					}
					svg {
						width:25px;
						height:15px;
						padding-left:5px;
					}
				}
				ul {
					li {
						list-style:disc;
						line-height:100%;
						padding-bottom:5px;
					}
				}
			}
			&.multi {
				> li {
					padding:20px 0;
					border-top:1px solid Config.color('sage');
				}
			}
			&:not(.multi) {
				padding:20px 0 0 20px;
				border-top:1px solid Config.color('sage');
				li {
					list-style:disc;
				}
			}
		}

	}