
	/**
	*
	* 		Ambassadors
	*			- Styles for "Ambassadors" page
	*
	**/

	#pambassadors {
		main {
			padding:50px 0;

			@media all and (max-width:650px){
				padding-bottom:0;
			}

			section {
				display:flex;
				align-items:center;
				justify-content:space-between;
				max-width:1000px;
				gap:50px;

				> div:first-child {
					max-width:344px;

					header {

						h1 {
							font-size:52px;
							font-weight:400;
							line-height:114.286%;
							letter-spacing:-0.56px;
							padding-bottom:12px;
							margin:0;
						}

						h2 {
							font-size:30px;
							font-weight:400;
							line-height:117.647%;
							letter-spacing:-0.34px;
							margin:0;
						}
					}

					p {
						max-width:461px;
						font-size:16px;
						line-height:150%;
						padding:20px 0 30px;
					}
				}

				.collage {
					display:grid;
					grid-template-columns:repeat(2, minmax(0, 1fr));
					grid-gap:29px;
					width:100%;
					max-width:450px;
					img {
						width:100%;
						height:auto;
						object-fit:cover;
					}
				}

				@media all and (max-width:650px){
					flex-direction:column;
					> div:first-child,
					.collage {
						max-width:100% !important;
						gap:20px;
						padding-top:40px;
						p {
							max-width:100%;
						}
					}
				}

				@media all and (max-width:450px){
					> div:first-child {
						header {
							h1 {
								font-size:48px;
							}
							h2 {
								font-size:28px;
							}
						}
					}
				}

			}

		}
	}
