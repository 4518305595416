
	/**
	*
	* 		CONFIG
	*			- Site Config styles for the site
	*
	**/
	@use "sass:map";
		
	//Fonts
	$fonts: 		(
		'headline':	 		('baskerville-display-pt', serif),
		'primary': 			('Montserrat', Arial)
	);

	//Colors
	$colors: 		(
		'offset': 		#F1F1EA,
		'sage': 		#C3C6A7,
		'brand': 		#757A4D,
		'text': 		#535335,
		'background': 	#FBFAF9
	);



	@function font($value) { @return map.get( $fonts , $value ) }
	@function color($value) { @return map.get( $colors , $value ) }