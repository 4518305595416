
	/**
	*
	* 		Retailers
	*			- The Stockists & Makers Page
	*
	**/
	#preturn-policy,
	#pprivacy-policy{
		.wrap {
			max-width:1000px;
			margin:0 auto;
		}
	}
