
	/**
	*
	* 		Header
	*			- The header styles will go hehre
	*
	**/
	@use '../_config' as Config;

	body.search-open {
		overflow:hidden;
	}

	//Search Wrapper
	section#search {
		background:Config.color('background');
		position:fixed;
		width:100vw;
		height:100vh;
		z-index:10;
		left:0;
		top:0;
		padding:0;
		min-width:320px;
		overflow-y:scroll;
		left:120vw;
		transition:0.3s ease left;
		opacity:0;

		header {
			background:Config.color('sage');
			height:30px;
			> div {
				display:flex;
				position:relative;
				a.close {
					margin:0 auto;
					position:absolute;
					top:6px;
					right:var(--container-padding);
					text-transform:uppercase;
					font-size:12px;
					cursor:pointer;
					display:flex;
					flex-direction:row-reverse;
					justify-content:center;
					line-height:17px;
					color:#FFF;
					gap:5px;
					font-weight:600;
					transition:0.3s color;
					> span {
						position:relative;
						display:block;
						width:15px;
						height:15px;
						span {
							position:absolute;
							display:block;
							background:#FFF;
							transition:0.3s ease background;
							width:15px;
							height:2px;

							&:nth-child(1) {
								top:7px;
								transform:rotate(405deg);
							}
							&:nth-child(2) {
								top:7px;
								transform:rotate(-405deg);
							}
						}
					}

					&:hover {
						color:Config.color('brand');
						> span {
							span {
								background:Config.color('brand');
							}
						}
					}
				}
			}
		}

		&.open {
			left:0;
			opacity:1;
		}

	}

	//Search Content
	#search-content {
		display:block;
		height:100%;

		form {
			display:flex;
			align-items:center;
			width:100%;
			margin:20px 0;
			border-bottom:1px solid Config.color('sage');
			
			input {
				width:100%;
				margin-right:10px;
				font-size:32px;
				line-height:43px;
				letter-spacing:0.49px;
				text-align:left;
				color:Config.color('text');
				font-family:Config.font('headline');
				border:none;
				background:transparent;
				font-weight:500;
				letter-spacing:-0.1px;
				padding-bottom:10px;
				outline:none;
				transition:.2s ease all;
				text-transform:lowercase;
				&::placeholder {
					color:Config.color('text');
				}
			}

			button {
				border:none;
				background:transparent;
				outline:none;
				cursor:pointer;
				svg {
					width:25px;
					height:25px;
					fill:none;

				    circle,
				    line {
				    	transition:0.3s ease stroke;
				        stroke:Config.color('text');
				        stroke-width:2;
				    }
				}
				&:hover {
					svg {
						circle,
						line {
							stroke:Config.color('sage');
						}
					}
				}
			}
		}
	}

	#search-bar-results {
		padding:20px 0;
		height:100%;
		position:relative;

		&.active {
			padding-top:30px;
		}

		.product {
			.image {
				padding-right:0;
			}
			.price {
				font-family:Montserrat;
			}
			.description {
				padding-right:20px;
				padding-bottom:10px;
				border-right:none;
			}
		}

		.none {
			color:#999;
			text-align:center;
			font-size:18px;
			width:100%;
			padding:30px 0;
		}

		.view-all {
			display:none;
			text-align:center;
			.button{
				color:#252525;
				margin:0 50px;
				&:hover {
					color:#FFF;
				}
			}
			i {
				color:#FAA;
				cursor:pointer;
				&:hover {
					color:#000;
				}
			}
		}
	}