
	/**
	*
	* 		Ambassadors
	*			- Styles for "Ambassadors" page
	*
	**/
	@use '../_config' as Config;

	#prewards {
		main {
			padding:50px 0;

			.banner {
				.container {
					width:100%;
					max-width:800px;
					h1 {
						font-size:clamp(48px, 9vw, 56px);
						font-weight:400;
						line-height:114.286%;
						margin:0;
					}
					p {
						font-size:14px;
						font-weight:400;
						line-height:157.143%;
						text-align:center;
						margin:0;
						padding:30px 0;
						margin:0 auto;
					}
					.buttons {
						gap:28px;
						justify-content:center;
					}

					hr {
						margin-top:100px;
						width:100%;
						background:none;
						border-width:1px 0 0;
						border-color:Config.color('sage');
						border-style:dashed;
					}
				}
			}

			#introduction {
				padding-top:80px;
				max-width:914px;
				@media all and (max-width:450px){
					padding-top:50px;
				}
				h2 {
					font-size:clamp(30px, 9vw, 49px );
					text-align:center;
					margin:0;
					padding-bottom:50px;
					line-height:normal;
					font-weight:100;
				}

				> div {
					display:flex;
					justify-content:space-between;
					gap:60px;

					> div {
						max-width:332px;

						svg {
							width:40vw;
							height:auto;
							max-width:332px;
							padding:1px; //Fixes Safari cutting off the edge
						}

						h3 {
							margin:0;
							font-size:14px;
							font-family:Config.font('primary');
							font-style:normal;
							font-weight:600;
							line-height:92.857%;
							letter-spacing:1px;
							text-transform:uppercase;
							padding:26px 0 12px;
						}

						p {
							font-size:16px;
							font-style:normal;
							font-weight:400;
							line-height:150%;
							margin:0;
						}
					}

					@media all and (max-width:450px){
						flex-direction:column;
						align-items:center;
						> div {
							margin:0 auto;
							svg {
								width:80%
							}
						}
					}

				}
			}


			#share {
				position:relative;
				margin:100px 0;
				.bg {
					width:100vw;
					height:100vh;
					min-height:500px;
					display:flex;
					position:relative;
					z-index:1;
					> div {
						margin:auto;
						max-width:432px;
						min-width:calc( 320px - (var(--container-padding)*2));
						color:#FFF;
						padding:0 var(--container-padding);
						h2 {
							font-size:clamp(40px, 9vw, 56px);
							font-weight:400;
							line-height:114.286%;
							letter-spacing:-0.56px;
							margin:0 0 15px;
							text-align:center;
						}
						p {
							font-size:clamp( 14px, 3vw, 16px);
							font-style:normal;
							font-weight:400;
							line-height:150%;
							margin:0 0 30px;
						}
						.buttons {
							justify-content:center;
						}
					}
				}
				img {
					object-fit:cover;
				}
			}



			#how-it-works {
				display:flex;
				justify-content:space-between;
				padding:0 calc( var(--container-padding) * 2 ) 100px;
				gap:60px;
				> div {

					&:first-child {
						max-width:475px;
						width:100%;
						h2 {
							font-size:42px;
							font-style:normal;
							font-weight:400;
							line-height:119.048%;
							margin:0 0 15px;
						}
						p {
							font-size:16px;
							font-style:normal;
							font-weight:400;
							line-height:150%;
							margin:0 0 50px;
						}
						.buttons {
							gap:30px;
						}
					}


					&:last-child {
						max-width:623px;
						min-width:500px;
						width:100%;
						table {
							border:1px solid Config.color('brand');
							width:100%;
							thead {
								tr {
									th {
										font-family:Config.font('headline');
										font-size:clamp( 28px, 3vw, 34px );
										font-style:normal;
										font-weight:400;
										line-height:117.647%;
										letter-spacing:-0.34px;
										padding:15px 30px;
										text-align:left;
									}
								}
							}
							tbody {
								tr {
									td {
										font-size:clamp( 11px, 3vw, 14px );
										font-style:normal;
										font-weight:600;
										line-height:clamp( 150%, 3vw, 200% );
										letter-spacing:1px;
										text-transform:uppercase;
										padding:clamp( 13px, 3vw, 28px) clamp( 12px, 3vw, 30px);
										border:1px solid Config.color('text');
										border-width:1px 1px 0 0;
										&:last-child {
											border-width:1px 0 0 0;
										}
									}
								}
							}
						}
					}

				}

				@media all and (max-width:950px){
					flex-direction:column;
					> div {
						&:first-child,
						&:last-child {
							max-width:100%;
							min-width:0;
						}
					}
				}
			}


			#redeem {
				header {
					text-align:center;
					padding-bottom:50px;

					h2 {
						font-size:42px;
						font-style:normal;
						font-weight:400;
						line-height:119.048%;
						margin:0 0 15px;
					}
					p {
						font-size:16px;
						font-style:normal;
						font-weight:400;
						line-height:150%;
					}
				}

				> div {
					position:relative;
					nav {
						position:absolute;
						width:100%;
						top:50%;
						left:0;
						z-index:1;
						justify-content:space-between;
						display:flex;
						pointer-events:none;

						.button {
							padding:10px;
							position:relative;
							pointer-events:auto;
							&:first-child {
								left:-15px;
								svg {
									transform:rotate(180deg);
								}
							}
							&:last-child {
								right:-15px;
							}
						}


						@media all and (max-width:650px){
							top:17vw !important;
							.button {
								transform:scale(0.6);
								&:first-child {
									left:-18px;
								}
								&:last-child {
									right:-18px;
								}
							}
						}
					}
				}

				@media all and (max-width:650px){
					.product-card {
						> a {
							.details {
								.caption {
									min-height:40px;
								}
								.buttons {
									padding-top:0;
								}
							}
						}
					}
				}

				.buttons {
					padding-top:30px;
					justify-content:center;
				}
			}

		}
	}
