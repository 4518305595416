
	/**
	*
	* 		Features
	*			-
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	//Features
	.features {
		padding-bottom:105px;
		overflow-x:hidden;
		max-width:2005px;
		margin:0 auto;
		header {
			display:flex;
			justify-content:space-between;
			align-items:center;
			h2 {
				font-size:42px;
				font-style:normal;
				font-weight:400;
				line-height:119.048%;
			}
			@media all and (max-width:750px){
				flex-direction:column;
				padding-bottom:40px;
				h2 {
					font-size:clamp( 34px , 4.5vw, 42px );
					line-height:clamp( 117.647%, 4.5vw, 119.048% );
					letter-spacing:clamp( -0.34px, 4.5vw, 0px );
					margin-bottom:20px;
				}
			}
		}
		.slider {
			position:relative;

			.list {
				display:flex;
				gap:10px;
				padding:0 var(--container-padding);

				img {
					display:block;
				}

				.item {
					position:relative;
					max-height:620px;
					min-height:320px;
					max-width:653px;

					img {
						height:100%;
						width:100%;
						object-fit:cover;
					}

					&.active {
						.content {
							opacity:1;
							pointer-events:auto;
							transform:translateY(0)
						}
					}

					> a.button,
					.content {
						position:absolute;
						left:20px;
						bottom:20px;

						strike {
							opacity:.5;
							padding-right:5px;
						}
					}

					.content {
						padding:clamp( 15px, 3vw, 20px ) clamp( 15px, 3vw, 25px );
						background:#FFF;
						max-width:450px;
						transform:translateY(100px);
						max-width:83.5%;
						opacity:0;
						pointer-events:none;
						transition:0.3s ease transform, 0.3s ease opacity;

						h3 {
							font-family:Config.font('primary');
							font-size:clamp(12px, 4.5vw, 14px);
							font-style:normal;
							font-weight:500;
							line-height:clamp(183.333%, 4.5vw, 157.143%);
							letter-spacing:clamp(0.6px, 4.5vw, -.7px);
							text-transform:uppercase;
							margin:0;
							padding-bottom:clamp(5px, 4.5vw, 10px);
						}

						p {
							font-family:Config.font('headline');
							font-size:clamp(20px, 4.5vw, 24px);
							font-style:normal;
							font-weight:400;
							line-height:clamp(130%, 4.5vw, 133.333%);
							margin:0;
						}

						.buttons {
							padding-top:clamp(25px, 4.5vw, 40px);
							@media all and (max-width:500px){
								.arrow {
									display:none;
								}
							}
						}
					}


					@media all and (max-width:750px){
						max-width:90%;
						max-height:100%;

						img {
							max-height:250px;
						}

						> a.button {
							display:none;
						}

						.content {
							position:static;
							width:100%;
							max-width:100%;
							box-sizing:border-box;
							opacity:1;
							transform:none;
						}
					}
				}
			}

			nav {
				position:absolute;
				top:50%;
				left:0;
				width:100%;
				display:flex;
				justify-content:space-between;
				pointer-events:none;
				z-index:2;
				.button {
					padding:10px;
					pointer-events:all;
					&:first-child {
						svg {
							transform:rotate(180deg);
						}
					}
				}
				ul {
					margin:0;
					padding:0;
					display:none;
					align-items:center;
					justify-content:center;
					width:100%;
					gap:10px;
					li {
						width:10px;
						height:10px;
						border-radius:100%;
						display:block;
						border:1px solid color.adjust( Config.color('brand') , $lightness:-20% );
						transition:0.3s ease border, 0.3s ease background;
						cursor:pointer;
						pointer-events:all;

						&.active {
							border:1px solid Config.color('brand');
							background:Config.color('brand');
						}

						&:hover {
							border:1px solid Config.color('brand');
						}
					}
				}
				@media all and (max-width:750px){
					position:static;
					padding-top:30px;
					.button {
						display:none;
					}
					ul {
						display:flex;
					}
				}
			}
		}
	}