
	/**
	*
	* 		Modal
	*			- A Modal Function
	*
	**/
	@use '../_config' as Config;

	//Quick Card, Quick-Add Modal
	#modal.product {
		.content {
			max-width:1100px;
			border-top:none;
			display:flex;
			padding:0;
//			gap:52px;

			.quickadd {
				display:flex;
				position:relative;
				box-sizing:border-box;
				z-index:1;
				gap:52px;

				.gallery {
					position:relative;
					background:radial-gradient(circle, rgba(215,193,165,1) 0%, rgba(188,164,135,1) 100%);
					width:50%;
			
					> div {
						position:relative;
						width:100%;
						height:100%;
						img {
							width:100%;
							height:100%;
							display:block;
							transition:0.3s ease opacity;
							object-fit:cover;
							position:absolute;
							opacity:0;
							transition:0.3s ease opacity;
							top:0;
							left:0;
							&.selected {
								position:relative;
								opacity:1;
							}
						}
					}

					footer {
						position:absolute;
						top:20px;
						left:30px;
						display:flex;
						gap:3px;
						flex-direction:column;
						img {
							display:block;
							border:1px solid transparent;
							cursor:pointer;
							filter:grayscale(0.5);
							object-fit:cover;
							&.selected {
								border:1px solid Config.color('text');
								filter:none;
							}
						}
						@media all and (max-width:1000px){
							display:none;
						}
					}
				}

				.details {
					width:50%;
					padding:52px 52px 52px 0;

					header {
						> div:not(.rating) {
							display:flex;
							justify-content:space-between;
							flex-wrap:wrap;
							h2 {
								margin:0;
								font-size:clamp(40px, 6.7vw, 56px);
								line-height:120%;
								letter-spacing:0;
								font-weight:400;
								line-height:114.286%;
								letter-spacing:-0.56px;
								a {
									display:block;
									line-height:100%;
									text-decoration:none;
									color:Config.color('text');
									transition:0.3s ease color;
									&:hover {
										color:Config.color('brand');
									}
								}
								span.inci {
									font-family:Config.font('primary');
									line-height:100%;
									text-decoration:lowercase;
									display:block;
									margin:0;
									font-size:clamp(10px, 4.7vw, 12px);
									font-style:normal;
									font-weight:400;
									line-height:150%;
									padding-bottom:10px;
								}
							}
							p.price {
								font-family:Config.font('headline');
								font-size:34px;
								font-style:normal;
								font-weight:400;
								line-height:117.647%;
								letter-spacing:-0.34px;
								padding:5px 0 0;
								//opacity:.5;
								margin:0;
								.was {
									padding-right:5px;
									font-size:80%;
									position:relative;
									top:-3px;
									opacity:.5;
								}
							}
						}
						> p {
							margin:0;
							font-size:clamp(14px, 4.7vw, 16px);
							font-style:normal;
							font-weight:400;
							line-height:150%;
							padding:10px 0 0;
						}

						.specifications {
							padding:10px 0 20px;
							display:grid;
							grid-template-columns:auto auto;
							align-items:center;
							gap:0 20px;
							
							@media all and (max-width:600px), (max-width:1150) and (min-width:800px){
								grid-template-columns:1fr;
							}

							li {
								display:flex;
								align-items:center;
								flex-wrap:wrap;
								p {
									display:block;
									line-height:normal;
									strong {
										display:block;
										padding-bottom:3px;
									}
								}
							}
						}
					}


					.links {
						a {
							font-size:12px;
							font-style:normal;
							font-weight:400;
							line-height:150%;
							text-decoration-line:underline;
							display:inline-block;
							text-align:center;
							margin-top:15px;
							margin-right:10px;
							padding-right:10px;
							border-right:1px solid Config.color('text');
							border-image:linear-gradient(to bottom, rgb(200, 181, 155) 25%, Config.color('brand') 30%, Config.color('brand') 82%, rgb(200, 181, 155) 76%) 1;
							transition:0.3s ease color;
							&:last-child {
								border-right:none;
							}
							&:hover {
								color:Config.color('brand');
							}
						}
						@media all and (max-width:400px){
							display:flex;
							flex-direction:column;
							text-align:left;
							align-items:flex-start;
							a {
								border-right:none;
								border-image:none;
							}
						}
					}
				}
				@media all and (max-width:800px){
					.gallery {
						display:none;
					}
					.details {
						width:100%;
						padding:52px;
					}
				}

				@media all and (max-width:500px){
					.details {
						padding:30px;
					}
				}
			}
		}
	}