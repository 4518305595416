
	/**
	*
	* 		Category List
	*			-
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	#categories {
		padding-bottom:98px;
		position:relative;
		max-width:2005px;
		overflow-x:hidden;
		margin:0 auto;
		@media all and (max-width:800px){
			padding:0 0 clamp(63px, 4.5vw, 98px);
		}
		.list {
			display:flex;
			gap:10px;
			align-items:stretch;
			justify-content:center;
			.category {
				display:flex;
				flex-direction:column;
				flex-grow:1;
				img {
					width:100%;
					height:auto;
					display:block;
				}
				.content {
					text-align:center;
					max-width:384px;
					margin:0 auto;
					height:100%;
					display:flex;
					flex-direction:column;
					padding:20px 7px 0;

					h2 {
						font-size:clamp( 24px , 5vw , 34px);
						font-style:normal;
						font-weight:400;
						line-height:clamp( 117.647% , 4.5vw , 133.333% );
						letter-spacing:clamp( 0px , 4.5vw , -0.34px );
						text-transform:lowercase;
						margin:0;
						flex-grow:1;
					}
					p {
						font-size:14px;
						font-style:normal;
						font-weight:400;
						line-height:157.143%;
					}
					.button {
						margin:auto auto 0;
					}
				}

				&.subnav {
					.wrap {
						position:relative;
						.bg {
							position:absolute;
							height:100%;
							width:100%;
							padding:0 27px 33px;
							//background:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.15%, rgba(0, 0, 0, 0.70) 74.03%) no-repeat;
							background:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 27.15%, rgba(0, 0, 0, 0.70) 74.03%) no-repeat;
							color:#FFF;
							bottom:0;
							display:flex;
							box-sizing:border-box;
							.content {
								margin:auto 0 0 0;
								height:auto;
								text-align:left;
								.button {
									margin:auto auto auto 0;
								}
							}
						}
					}
					nav {
						header {
							padding-top:60px;
							text-align:center;
							h3 {
								padding:8px 12px;
								display:inline-block;
								background:color.adjust( Config.color('sage'), $lightness:20% );
								font-family:Config.font('primary');
								font-size:10px;
								font-weight:500;
								line-height:120%;
								letter-spacing:0.75px;
								text-transform:uppercase;
							}
						}
						ul {
							padding:0;
							li {
								text-align:center;
								padding-bottom:20px;
								a {
									font-family:Config.font('headline');
									font-size:22px;
									font-weight:400;
									line-height:109%;
									border-bottom:1px solid transparent;
									transition:0.3s ease border-color;
									&:hover {
										border-bottom:1px solid Config.color('text');
									}
								}
							}
						}
					}
				}
			}

			@media all and (max-width:800px){
				justify-content:left;
				padding:0 var(--container-padding);
				.category {
					max-width:45%;
				}
			}

			@media all and (max-width:500px){
				.category {
					min-width:60%;
				}
			}
		}

		nav.controls {
			position:absolute;
			width:100%;
			top:50%;
			left:0;
			z-index:1;
			justify-content:space-between;
			display:none;

			@media all and (max-width:1200px){
				display:flex;
			}

			.button {
				padding:10px;
				&:first-child {
					svg {
						transform:rotate(180deg);
					}
				}
			}
		}

	}