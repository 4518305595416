
	/**
	*
	* 		The Product Options Form
	*			-
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	//The Quick Cart
	#quick-cart {
		height:0px;
		width:100%;
		position:fixed;
		transition:0s ease height;
		overscroll-behavior:contain;
		transition-delay:0.75s;
		z-index:10;
		top:0;
		right:0;

		&.active {
			height:100%;
			transition-delay:unset;
			.bg {
				opacity:1;
				transition-delay:0.25s;
			}
			.viewport {
				right:0;
			}
		}

		.bg {
			background:rgba(0,0,0,.5);
			height:100%;
			width:100%;
			opacity:0;
			transition:0.5s ease opacity;
			position:absolute;
			top:0;
			left:0;
		}

		.viewport {
			position:absolute;
			top:0;
			right:-100%;
			transition:0.5s ease right;
			width:100%;
			height:100%;
			max-width:420px;
			margin:0 0 0 auto;
			display:flex;
			flex-direction:column;
			justify-content:space-between;
			background:Config.color('background');
			box-sizing:border-box;

			header {
				position:relative;
				padding:25px;
				border-top:10px solid Config.color('sage');
				border-bottom:1px solid Config.color('sage');
				margin:0 auto;
				width:100%;
				flex-shrink:0;
				box-sizing:border-box;

				h3 {
					font-family:Config.font('primary');
					font-weight:500;
					font-size:18px;
					text-align:center;
					text-transform:capitalize;
					color:Config.color('brand');
					width:100%;
					margin:0;
					> span {
						font-size:90%;//12px;
						padding-left:5px;
					}
				}

				a {
					position:absolute;
					margin-top:5px;
					cursor:pointer;
				}

				.shipping {
					p {
						font-family:Config.font('primary');
						font-weight:500;
						font-size:12px;
						line-height:20px;
						letter-spacing:-0.01em;
						text-align:center;
						span {
							font-weight:600;
						}
					}
					.bar {
						width:100%;
						width:100%;
						border:1px solid Config.color('sage');
						padding:2px;
						div {
							background:Config.color('sage');
							height:5px;
							width:100%;
							overflow:hidden;
							div {
								background:Config.color('text');
								transition:0.3s ease width;
								height:100%;
							}
						}
					}
				}
			}

			.cart {
				flex:1 1 auto;
				position:relative;
				overflow:hidden;

				.scroll {
					overflow-y:scroll;
					overscroll-behavior:contain;
					width:100%;
					height:100%;

					table {
						margin:0 auto auto;
						width:100%;
						padding:8px 25px;
						table-layout:fixed;
						//height:100%;

						tbody {
							tr {

								&.demo {
									opacity:.10;
									pointer-events:none;
								}

								&.none td {
									vertical-align:middle;
									text-align:center;
									height:100%;
									padding:20px 0 50px;
								}

								&.notice td {
									padding:0;
									text-align:center;
									> div {
										margin-top:-89px;
										width:100%;
										position:relative;
										padding:0;
										display:flex;
										justify-content:center;

										.icon {
											display:inline-block;
											background:Config.color('text');
											border-radius:100%;
											width:39px;
											height:39px;
											display:flex;
											margin-right:10px;
											svg {
												width:19px;
												height:19px;
												display:block;
												margin:auto;
												path {
													fill:Config.color('background');
												}
											}
										}

										p {
											font-weight:600;
											font-size:13px;
											line-height:18px;
											letter-spacing:0.1px;
											text-transform:uppercase;
											max-width:200px;
											text-align:left;
											margin:0;
										}

										@media all and (max-width:550px){
											margin-top:-90px;
										}
									}
								}

								&:last-child,
								&[data-variant-id]:last-child {
									td {
										border-bottom:none;
									}
								}

								td {
									vertical-align:top;
									border-bottom:1px solid Config.color('sage');
									padding:20px 0;
									width:100%;

									&.thumbnail {
										width:85px;
										text-align:center;
										vertical-align:top;
										position:relative;
										a {
											img {
												width:100%;
												height:100%;
												max-height:110px;
												object-fit:cover;
											}
										}
									}

									&.details {

										.product {
											font-weight:normal;
											letter-spacing:0.63px;
											font-size:14px;
											padding-left:10px;

											.title {
												margin:0;
												font-family:Config.font('headline');
												text-transform:lowercase;
												font-size:18px;
												line-height:100%;
												letter-spacing:0;
											}

											a.title:hover {
												border-bottom:1px solid Config.color('text');
											}

											.variant,
											.type {
												margin:0;
												padding:5px 0;
												line-height:normal;
												font-size:80%;
											}
										}

										.quantity {
											padding-top:10px;
											padding-left:10px;
											input, select {
												border:1px solid Config.color('sage');
												font-weight:500;
												padding:5px 10px;
												color:#000;
												width:100%;
												outline:none;
												font-weight:500;
												color:Config.color('text');
												font-size:12px;
												letter-spacing:1.32px;
												line-height:16px;
												display:block;
												width:55px;
											}
											select {
												padding-right:15px;
												background-position:85%;
											}

										}

										.price {
											display:none;
										}
									}

									> .price,
									> .total {
										font-family:Config.font('headline');
										font-weight:400;
										font-size:18px;
										line-height:24px;
										margin:0 0 5px 0;
										text-decoration:none;

										.was {
											text-decoration:line-through;
											color:rgba(255,0,0,.5);
											display:block;
											padding-top:12px;
											+ span {
												display:block;
												position:relative;
												//top:-6px;
											}
										}
									}

									&.action {
										text-align:right;

										.list {
											display:flex;
											justify-content:flex-end;
										}

										a {
											text-decoration:none;
											position:relative;
											text-transform:uppercase;
											display:block;
											color:Config.color('text');
											transition:0.3s ease color;
											cursor:pointer;
											span {
												display:none;
												font-size:10px;
												position:absolute;
												top:20px;
												transform:translateX(-42%);
												background:Config.color('text');
												padding:4px 8px;
												color:#FFF;
												white-space:nowrap;
												font-weight:bold;
												text-align:center;
												line-height:normal;
												&::before {
													width:0;
													height:0;
													border-left:5px solid transparent;
													border-right:5px solid transparent;
													border-bottom:5px solid Config.color('text');
													position:absolute;
													content:'';
													top:-5px;
													left:46%;
												}
											}
											&:hover {
												color:Config.color('brand');
												span {
													display:block;
												}
											}
										}
									}
								}
							}



							.discounts {
								margin:auto auto 0;
								h2 {
									font-family:Montserrat;
									font-weight:500;
									font-size:11px;
									padding-top:15px;
									line-height:13.41px;
									letter-spacing:0.33px;
									text-transform:uppercase;
								}

								.list {
									display:grid;
									grid-template-columns:1fr;
									grid-gap:5px;
									padding-bottom:20px;
									.discount {
										background:#EFEFEF;
										border-radius:5px;
										font:10px/normal Montserrat, Arial;
										font-weight:600;
										letter-spacing:0.5px;
										padding:10px;
										border:1px solid #F5F5F5;
										text-transform:uppercase;
										display:flex;
										justify-content:space-between;
									}
								}
							}

							.upsell {
								margin:auto auto 0;

								h2 {
									font-family:Config.font('primary');
									font-weight:500;
									font-size:11px;
									line-height:13.41px;
									letter-spacing:0.33px;
									padding-top:15px;
									padding-bottom:12px;
									text-transform:uppercase;
								}
								.wrap {
									position:relative;
									.list {
										width:100%;

										.product {
											display:flex;
											min-width:333px;
											margin-right:15px;
											padding:15px 0 16px;
											background:color.adjust( Config.color('sage'), $lightness: 23% );

											.featured {
												position:relative;
												display:flex;

												img {
													margin:auto;
													display:block;
													max-width:115px;
													height:auto;
												}
											}

											.info {
												padding-left:10px;
												max-width:53%;
												h3 {
													margin:0;
													a {
														font-family:Baskerville, Times New Roman;
														font-weight:400;
														font-size:22px;
														text-align:left;
														line-height:133.333%;
														display:block;
														white-space:nowrap;
														overflow:hidden;
														text-overflow:ellipsis;
														text-decoration:none;
													}
												}

												p {
													font-size:11px;
													font-style:normal;
													font-weight:400;
													line-height:145.455%;
													margin:5px 0 10px;
												}

												.button {
													.price {
														&:after {
															content:' - '; 
														}
														.was {
															padding-right:3px;
														}
													}
												}
											}
										}
									}
									nav {
										position:absolute;
										width:100%;
										top:calc( 50% - ( 15.5px / 2 ) );
										left:0;
										z-index:1;
										justify-content:space-between;
										display:flex;

										a {
											padding:0;
											position:relative;
											left:3px;
											color:Config.color('brand');
											cursor:pointer;
											svg {
												transition:0.3s ease transform;
												width:12px;
												path {
													stroke-width:2.5px;
												}
											}
											&:first-child {
												left:-3px;
												svg {
													transform:rotate(180deg);
												}
												&:hover {
													svg {
														transform:rotate(180deg) translateX(2px);
													}
												}
											}
											&:hover {
												svg {
													transform:translateX(2px);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}


			footer {
				border-top:1px solid #D9D9D9;
				margin:auto 0 0;
				padding:25px;
				flex-shrink:0;

				button {
					display:block;
					width:100%;
					[data-cart-loading] {
						svg {
							width:20px;
							height:20px;
							circle {
								fill:#FFF;
							}
						}
						.was {
							text-decoration:line-through;
						}
					}
				}

				.info {
					font-family:Montserrat, Arial;
					font-weight:400;
					font-size:10px;
					line-height:14px;
					letter-spacing:0.01em;
					text-align:center;
					color:#555;
				}
			}
		}
	}