
	/**
	*
	* 		Testimonials
	*			- Website Testimonials
	*
	**/
	@use '../_config' as Config;
	
	#testimonials {
		padding:clamp( 65px, 12vw, 110px ) var(--container-padding);
		max-width:1100px;
		display:flex;
		justify-content:space-between;

		> div {
			position:relative;
			width:50%;

			blockquote {
				background:Config.color('brand');
				width:320px;
				height:313px;
				padding:56px 22px;
				box-sizing:border-box;
				position:absolute;
				top:calc(50% - (313px/2) );
				left:150px;
				display:flex;
				z-index:1;
				opacity:1;
				transition:0.3s ease opacity;

				> div {
					margin:auto;
					color:#FFF;

					header {
						display:flex;
						gap:3px;
						align-items:center;
						justify-content:center;
						padding-bottom:20px;
					}

					q {
						font-family:Config.font('headline');
						font-size:clamp(16px, 4.7vw, 24px);
						font-weight:400;
						line-height:clamp(130%, 4.7vw, 133.333%);
						text-align:center;
						display:block;
					}
					cite {
						display:block;
						font-size:11px;
						font-weight:600;
						text-transform:uppercase;
						letter-spacing:1px;
						line-height:18px;
						font-style:normal;
						text-align:center;
						padding-top:15px;
					}
				}
			}

			&:last-child {
				max-width:390px;
				display:flex;
				flex-direction:column;

				h2 {
					font-size:clamp(34px, 10vw, 42px);
					letter-spacing:clamp(-0.34px, 10vw, 0px);
					line-height:clamp(117.647%, 10vw, 119.048%);
					font-style:normal;
					font-weight:400;
					line-height:100%;
					text-transform:lowercase;
					max-width:90%;
					margin:0;
					padding:27px 0 10px;
				}
				p {
					font-size:clamp(14px, 10vw, 16px);
					line-height:clamp( 157.143%, 10vw, 150%);
					font-weight:400;
				}

				nav {
					margin:auto auto 0 5px;
					font-size:14px;
					font-weight:500;
					line-height:157.143%;
					letter-spacing:0.7px;
					text-transform:uppercase;
					display:flex;
					gap:25px;
					align-items:center;
				}
			}
		}

		&.playing {
			> div {
				blockquote {
					opacity:.1 !important;
					pointer-events:none;
				}
			}
		}

		@media all and (max-width:1000px){
			justify-content:space-evenly;
			> div {
				width:auto;
				blockquote {
					display:none;
				}
			}
		}

		@media all and (max-width:750px){
			flex-direction:column;
			> div {
				margin:0 auto;

				blockquote {
					padding:clamp(28px, 4.7vw, 56px) clamp(20px, 4.7vw, 22px);
					height:auto;
					width:clamp(230px, 50vw, 320px);
					display:block;
					left:20%;
				}

				iframe {
					position:relative;
					left:-30%;
				}

				&:last-child {
					max-width:100%;
					h2 {
						max-width:355px;
						margin:0;
					}
					nav {
						padding-top:25px;
					}
				}
			}
		}

		@media all and (max-width:550px) {
			> div {
				margin:0;
				blockquote {
					right:0;
					left:auto;
					top:auto;
					bottom:0;
					margin:0;
				}
				iframe {
					left:0;
					width:auto;
					padding-bottom:53px;
				}
				&:last-child {
					padding-top:25px;

					h2 {
						max-width:clamp(320px, 50vw, 355px);
					}
				}
			}
		}
	}

	#products {
		padding:0 var(--container-padding) 110px;
		header {
			padding-bottom:30px;
			h2 {
				font-size:42px;
				font-weight:400;
				line-height:119.048%;
				text-align:center;
			}
		}

		footer {
			text-align:center;
			padding-top:50px;
		}
	}