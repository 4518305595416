
	/**
	*
	* 		Manifest Component
	*			- The Manifest Component
	*
	**/
	@use '../_config' as Config;

	//Manifest Component
	#manifest {
		padding:100px 0;
		header {
			text-align:center;
			padding-bottom:50px;
			h2 {
				font-style:normal;
				font-weight:400;
				font-size:32px;
				line-height:120%;
				letter-spacing:-0.34px;
				margin:0;
				@media all and (max-width:500px){
					em {
						display:block;
					}
				}
			}
		}

		.slider {
			padding:0 60px;
			position:relative;

			@media all and (max-width:800px){
				padding:0;
			}

			.list {
				display:flex;

				.swiper-slide {
					margin:0 auto;
					opacity:0;
					transition:0.3s ease opacity;

					@media all and (min-width:1000px){
						width:33% !important;
					}

					&.swiper-slide-prev,
					&.swiper-slide-next {
						@media all and (min-width:400px) and (max-width:800px){
							opacity:.2;
						}
						@media all and (min-width:800px){
							opacity:1;
						}
					}


					&.swiper-slide-active {
						opacity:1;
					}

					> div {
						max-width:268px;
						text-align:center;
						margin:0 auto;
						.icon {
							width:75px;
							height:75px;
							margin:0 auto;
							border-radius:100%;
							aspect-ratio:1/1;
							display:flex;
							background-repeat:no-repeat;
							background-position:center center;
							background-size:52px 52px;
							background:Config.color('brand');
							img {
								display:block;
								margin:auto;
								filter:brightness(0) invert(1);
								width:60%;
								height:60%;
							}
						}
						h3 {
							font-family:Config.font('primary');
							font-style:normal;
							font-weight:500;
							font-size:14px;
							line-height:18px;
							letter-spacing:0.08em;
							text-transform:uppercase;
							padding-top:30px;
						}
						p {
							font-family:Montserrat, Arial;
							font-weight:400;
							font-size:14px;
							line-height:20px;
							text-align:center;
							letter-spacing:0.02em;
							color:#3A3939;
						}
					}
				}
			}

			nav {
				position:absolute;
				width:100%;
				top:calc( 50% - (42px/2));
				left:0;
				z-index:1;
				display:flex;
				justify-content:space-between;


				.button {
					padding:10px;
					&:first-child {
						svg {
							transform:rotate(180deg);
						}
					}
				}
			}
		}

	}