
	/**
	*
	* 		Account
	*			- Account-related classes
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	.profile-loader {
		background:color.adjust( Config.color('sage'), $lightness: 10% );
		border-radius:100%;
		position:relative;
		aspect-ratio:1/1;
		display:flex;
		svg {
			margin:auto;
		}
		img {
			visibility:hidden;
		}
	}
	.profile-photo {
		border-radius:100%;
	}