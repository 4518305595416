
	/**
	*
	* 		Product Specifications
	*			-
	*
	**/
	@use '../_config' as Config;
	
	.specifications {
		margin:0;
		padding:5px 0 0;
		li {
			display:flex;
			align-items:center;
			margin:10px 0;
			font-size:12px;
			@media all and (max-width:600px){
				display:flex;
				width:100%;
			}
			.icon {
				border-radius:100%;
				padding:5px;
				max-width:15px;
				max-height:15px;
				border:1px solid Config.color('text');
				display:flex;
				margin-right:10px;
				svg {
					height:100%;
					width:100%;
					aspect-ratio:1/1;
					margin:auto;
					path {
						fill:Config.color('text');
					}
				}
			}
			p {
				display:flex;
				align-items:center;
				white-space:nowrap;
				flex-wrap:wrap;
				margin:0;
				strong {
					text-transform:uppercase;
					font-size:12px;
					letter-spacing:0.5px;
					padding-right:5px;
				}
			}
		}
	}