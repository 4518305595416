
	/**
	*
	* 		Code
	* 			- With Copy
	*			-
	*
	**/
	@use '../_config' as Config;
	
	code.copyable {
		font-size:clamp(16px, 5.5vw, 25px);
		border:1px dashed Config.color('text');
		font-family:Config.font('primary');
		padding:20px;
		margin:60px auto 20px;
		display:block;
		text-align:center;
		color:Config.color('text');
		cursor:pointer;
		position:relative;
		transition:0.3s ease background;
		max-width:300px;
		.success {
			top:-10px;
			right:0;
			font-size:10px;
			position:absolute;
			opacity:0;
			transition:0.3s ease opacity, 0.3s ease top;
			&.show {
				opacity:1;
				top:-25px;
			}
		}
		.copy {
			position:absolute;
			top:-1px;
			right:-1px;
			align-items:center;
			opacity:0;
			transition:0.3s ease opacity;
			width:10px;
			height:10px;
			padding:5px;
			background:Config.color('text');
			border:1px solid transparent;
			margin-left:10px;
			display:inline-block;
			svg {
				width:100%;
				height:100%;
				display:block;
				path {
					fill:#FFF;
				}
			}
		}
		&:hover {
			background:rgba( Config.color('brand') , 0.25 );
			.copy {
				opacity:1;
			}
		}
	}