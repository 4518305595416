
	/**
	*
	* 		The Product Options Form
	*			-
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;

	//Product Card
	.cart-form {
		strong {
			font-size:11px;
			font-style:normal;
			font-weight:600;
			line-height:163.636%;
			letter-spacing:1px;
			text-transform:uppercase;
			padding-bottom:6px;
			display:block;
		}

		.warn {
			background:color.adjust( Config.color('sage') , $lightness:22% );
			font-size:12px;
			border:1px solid Config.color('sage');
			margin-bottom:10px;
			padding:15px;
			display:flex;
			align-items:center;
			line-height:160%;
			gap:10px;
			svg {
				display:block;
			}
			span {
				display:block;
				strong {
					text-transform:none;
					display:inline;
					font-style:inherit;
				}
			}
		}

		.options {
			display:flex;
			gap:6px;
			padding:0 0 30px;

			@media all and (max-width:415px){
				flex-direction:column;
				gap:15px;
				padding:0 0 15px;
				.item {
					.list {
						border-right:none !important;
					}
				}
				input[type="number"],
				select[name="quantity"] {
					background-position:90% center !important;
				}
				.submit {
					strong {
						display:none;
					}
					&,
					.button {
						width:100%;
					}
				}
			}

			+ .options {
				gap:15px;
			}

			label {
				display:block;
				padding:5px 12px;
				font-size:12px;
				font-style:normal;
				font-weight:500;
				line-height:183.333%;
				letter-spacing:0.6px;
				text-transform:uppercase;
				border:1px solid Config.color('text');
				color:Config.color('text');
				cursor:pointer;
				transition:0.3s ease background, 0.3s ease border, 0.3s ease color;
			}

			input[type="radio"]:checked + label,
			label:hover {
				border:1px solid Config.color('brand');
				background:Config.color('brand');
				color:#FFF;
			}

			//
			.item {
				.list {
					display:flex;
					gap:6px;

					padding-right:15px;
					margin-right:calc(15px - 6px);
					border-right:1px solid Config.color('text');
				}
				&:last-child {
					.list {
						border-right:none;
						padding-right:0;
						margin-right:0;
					}
				}
				@media all and (max-width:350px){
					.list {
						flex-wrap:wrap;
						span {
							width:calc(50% - 3px);
							text-align:center;
						}
					}
				}
			}

			input[type="radio"] {
				display:none;
				&[disabled] + label {
					opacity:.5;
					cursor:not-allowed;
				}
			}

			input[type="number"],
			select[name="quantity"] {
				padding:8px 40px 8px 20px;
				font-size:12px;
				font-style:normal;
				font-weight:500;
				line-height:183.333%;
				letter-spacing:0.6px;
				text-transform:uppercase;
				background:transparent;
				background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6' fill='none'%3E%3Cpath d='M8 1L4.39909 5L0.798144 1' stroke='%235D5A88' stroke-linecap='round'/%3E%3C/svg%3E");
				background-repeat:no-repeat;
				background-position:70% center;
				border:1px solid Config.color('text');
				color:Config.color('text');
				appearance:none;
				border-radius:0;
			}

			input[type="number"] {
				padding:8px 20px;
				background:none;
				max-width:90px;
			}

			.submit {
				.button {
					padding:12px 20px;
					.price {
						&:after {
							content:'-';
							padding-left:3px;
						}
						.was {
							padding-right:5px;
						}
					}
					.points {
						width:12px;
						height:12px;
						padding-right:5px;
						transform:none !important;
					}
					strike {
						opacity:.5;
					}
					> span {
						> span {
							display:flex;
							align-items:center;
							justify-content:center;
							gap:3px;
						}
					}
				}
			}
		}

		.links {
			text-align:center;
			a {
				text-align:center;
				font-size:12px;
				font-style:normal;
				font-weight:400;
				line-height:150%;
				text-decoration-line:underline;
				display:inline-block;
				margin-top:8px;
				transition:0.3s ease color;
				&:hover {
					color:Config.color('brand');
				}
			}
		}
	}