
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use '../_config' as Config;


	#pdashboard {

		#dashboard {
			header {
				h2 {
					margin:0;
				}
				p {
					margin-top:0;
					font-style:italic;
				}
			}
			ul {
				padding:15px 0;
				display:grid;
				grid-template-columns: repeat(3, minmax(0, 1fr));
				justify-content:space-between;
				gap:var(--container-padding);

				@media all and (min-width:900px) and (max-width:1000px), (max-width:750px){
					grid-template-columns: repeat(2, minmax(0,1fr));
				}

				@media all and (max-width:500px){
					grid-template-columns: repeat(1, minmax(0,1fr));
					li {
						p {
							max-width:100%;
						}
					}
				}

				li {
					padding-bottom:20px;
					strong {
						display:block;
						font-family:Config.font('primary');
						text-transform:uppercase;
						font-size:12px;
						font-weight:500;
						letter-spacing:0.34px;
						padding-bottom:10px;
						border-bottom:1px solid Config.color('sage');
					}
					p {
						max-width:300px;
						margin:10px 0;
						.tasks {
							font-weight:500;
							border:1px dashed Config.color('sage');
							padding:10px;
							margin-top:5px;
							display:block;
							max-width:200px;
							i {
								padding-right:5px;
							}
						}
					}
					.small {
						font-size:80%;
						display:inline-block;
					}
				}
			}
			footer {
				padding-top:50px;
				margin-top:50px;
				border-top:1px dashed Config.color('sage');
				ul {
					display:block;
					padding-left:30px;
					li {
						list-style:disc;
						padding-bottom:10px;
						a {
							text-decoration:underline;
						}
					}
				}
			}
		}



	}
