
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;


	#account {

		> .container {
			padding:100px var(--container-padding);
			display:flex;

			h1 {
				font-family:Config.font('headline');
				font-weight:normal;
				font-size:32px;
				text-align:left;
				line-height:28px;
				font-style:italic;
				margin:0;
			}

		}


		//Subnav
		section#subnav {
			width:100%;
			background:color.adjust( Config.color('sage') , $lightness: 22% );
			padding:10px 0;
			margin-bottom:25px;
			min-height:10px;

			.container {
				display:flex;
				flex-wrap:wrap;
				justify-content:center;
				align-items:center;

				a {
					display:block;
					font-size:12px;
					font-weight:500;
					letter-spacing:0.48px;
					line-height:18px;
					text-decoration:none;
					text-transform:uppercase;
					padding:5px 25px;

					span {
						transition:0.3s ease border-bottom;
						border-bottom:1px solid transparent;
					}

					&.current,
					&:hover {
						span {
							border-bottom:1px solid Config.color('text');
						}
					}

				}

			}

		}

		> .content {
			padding-top:25px;
			.container {
				display:flex;
				gap:30px;
			}
		}

	}
