
	/**
	*
	* 		Account Sidebar
	*			- Navigation on Desktop
	*
	**/
	@use '../_config' as Config;
	
	#account aside {

		width:216px;
		padding:0 0 42px;
		margin:0 88px 0 0;

		@media all and (max-width:1100px){
			margin:0;
		}


		@media all and (max-width:900px){
			display:none;
		}

		> div {
			overflow:hidden;

			header {
				border-bottom:1px dashed Config.color('sage');

				.row {
					display:flex;
					border-bottom:1px dashed Config.color('sage');
					width:100%;
					padding:0 0 25px 0;
					box-sizing:border-box;

					img,
					.profile-loader {
						margin-right:10px;
					}

					> div {
						overflow:hidden;

						h3 {
							font-weight:600;
							text-align:left;
							font-size:18px;
							line-height:21px;
							padding:0 10px 0 0;
							margin:auto;
							white-space:nowrap;
							text-overflow:ellipsis;
							overflow:hidden;
							width:100%;
						}

						a {
							font-size:10px;
							text-transform:uppercase;
							font-weight:500;
							line-height:28px;
							border-bottom:1px solid #4C4B4B;
							height:20px;
							text-decoration:none;
						}
					}
				}


				.stats {
					display:flex;
					padding:25px 70px 15px 0;
					box-sizing:border-box;

					div {
						text-transform:uppercase;
						font-size:13px;
						display:flex;
						flex-direction:column;

						a {
							text-transform:uppercase;
							font-size:13px;
							font-weight:400;
							font-family:Config.font('primary');
							letter-spacing:0.5px;
						}

						span.total {
							font-family:Config.font('headline');
							font-size:30px;
							font-weight:600;
							letter-spacing:1.44px;
							display:flex;
							text-transform:lowercase;
							height:30px;
							svg {
								margin-left:5px;
							}
						}

						&.trees {
							padding-left:10px;
							span svg {
								height:28px;
							}
						}

						&.points span svg {
							height:19px;
						}

						&.points + .trees {
							margin:0 0 0 auto;
						}

					}

				}

				nav.access {
					padding-bottom:38px;
					border-bottom:1px solid Config.color('sage');
				}
			}
		}

		nav {

			h2 {
				margin-bottom:0;
				font-size:16px;
				line-height:30px;
			}

			ul {
				padding-left:0;
				font-size:13px;
				letter-spacing:0.85px;
				font-weight:300;
				margin:0;

				li {
					padding-left:0;
					list-style:none;
					line-height:30px;

					a {
						text-decoration:none;

						&:hover {
							text-decoration:underline;
						}

					}

					&.selected a {
						color:Config.color('brand');
						text-decoration:underline;
					}

				}
			}
		}


		hr {
			width:100%;
			height:1px;
			border-bottom:1px dashed Config.color('sage');
			border-width:0 0 1px;
			margin:25px 0 20px;
		}


		.incentive {
			width:100%;
			height:195px;
			background:url('/assets/account/share.jpg') center center no-repeat;
			background-size:cover;
			margin-top:38px;

			div {
				width:100%;
				height:100%;
				background:rgba(0,0,0,.4);
				padding:18px;
				box-sizing:border-box;

				h2 {
					color:#FFF;
					font-size:18px;
					letter-spacing:1.18px;
					padding:10px 0;
					line-height:normal;
					font-weight:normal;
					margin:0;
				}
				p {
					color:#FFF;
					font-size:14px;
					font-style:italic;
					font-weight:400;
					line-height:21px;
					margin:5px 0 10px;
				}
			}
		}


	}