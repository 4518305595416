
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use '_login.scss';

	#preset {

		@extend #plogin;

		main {
			section {
				width:auto;
				border-right:none;
			}
		}
	}
