
	/**
	*
	* 		BADGES
	*			- The Product Badges
	**/
	@use '../_config' as Config;

	//Single Button
	.badges {
		position:absolute;
		margin:10px 0 0;
		padding:0;
		z-index:0;
		left:7%;
		top:2px;
		z-index:1;
		text-align:left;

		li {
			margin:0;
			padding:0;
			list-style:none;

			span {
				background:Config.color('sage');
				color:Config.color('text');
				border-radius:8px;
				font-size:9px;
				font-weight:600;
				letter-spacing:0.5px;
				padding:3px 10px;
				text-transform:uppercase;
			}

			&.new span {
				//display:none;
				background:Config.color('brand');
				color:#FFF;
			}

			&.sale span {

			}

			&.soldout span {

			}
		}
	}