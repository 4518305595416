
	/**
	*
	* 		Product Card
	*			- A Product Card in a Collection
	*
	**/

	//Product Card
	.collection {
		display:grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		justify-content:space-between;
		gap:calc( var(--container-padding) );
		position:relative;

		@media all and (max-width:1100px){
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		@media all and (max-width:800px){
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}