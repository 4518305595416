
	/**
	*
	* 		Account Sidebar
	*			- Navigation on Desktop
	*
	**/
	@use '../_config' as Config;
	
	#modal.rewards {

		//Modal with Form
		&.form {
			.content {
				width:auto;

				section {
					max-width:350px;

					header {
						span {
							font-weight:500;
							text-transform:uppercase;
							letter-spacing:2px;
							display:block;
							text-align:center;
							font-size:13px;
							@media all and (max-width:375px){
								font-size:10px;
							}
						}
						h2 {
							font-weight:700;
							font-size:45px;
							margin:11px 0;
							line-height:normal;
							@media all and (max-width:500px){
								font-size:40px;
							}
							@media all and (max-width:415px){
								font-size:30px;
							}
							@media all and (max-width:350px){
								font-size:25px;
							}
						}
					}

					> div {

						> p {
							line-height:1.4;
							padding:10px 0 30px;
							margin:0;
						}

						form {
							padding-top:30px;
							border-top:1px dashed Config.color('sage');

							.row {

								display:flex;

								@media all and (max-width:500px){
									display:block;
								}

								.error {
									margin:0;
									padding:0;
								}

								button {
									white-space:nowrap;
								}

							}
							> p.success {
								font-size:14px;
								text-align:left;
								color:#090;
								display:none;
							}
							> p {
								text-align:center;
								margin:10px 0 0;
								a {
									color:#777;
									font-size:12px;
									font-weight:400;
									cursor:pointer;
									&:hover {
										text-decoration:underline;
									}
								}
							}

						}
					}
				}
			}
		}

		&.newsletter,
		&.birthday {
			.content section {
				header {
					padding-bottom:15px;
					h2 {
						font-family:Config.font('primary');
						font-size:15px;
						text-transform:uppercase;
						text-align:center;
						font-weight:500;
						letter-spacing:0.34px;

						em {
							font-family:Config.font('headline');
							font-weight:700;
							font-size:45px;
							text-transform:lowercase;
							display:block;
							@media all and (max-width:500px){
								font-size:40px;
							}
						}
					}
				}
			}
		}

		&.newsletter {
			.content section {
				.button {
					width:100%;
				}
			}
		}

	}