
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;


	#pprofile {

		//
		#profile {
			width:100%;

			#info {
				> div {
					display:flex;
					gap:20px;

					.photo {

					}

					ul {
						margin:0;
						padding:0;
						li {
							font-size:12px;
							padding:0;

							strong {
								margin:auto 0;
								padding:0 5px 0 0;
								font-weight:normal;
								letter-spacing:0.49px;
								font-weight:500;
								text-transform:uppercase;
								font-size:12px;
							}
							small {
								white-space:nowrap;
							}
						}
					}
				}
				footer {
					padding:10px 0;
					.buttons {
						flex-wrap:wrap;
					}
				}
			}


			hr {
				border:1px dashed Config.color('sage');
				border-width:1px 0 0;
				margin:40px 0;
			}

			#addresses {
				position:relative;
				width:100%;
				min-height:300px;
				address {
					font-style:normal;
					padding:15px 30px;
					border:1px dashed Config.color('sage');
					margin:15px 0;
					p {
						margin:0;
					}
					.buttons {
						padding-top:10px;
						flex-wrap:wrap;
					}
				}
				footer {
					padding-top:20px;
				}
			}

		}



	}


	#address-form {
		footer {
			padding-top:10px;
			a {
				padding-left:10px;
				text-decoration:underline;
				cursor:pointer;
			}
		}
	}


	#profile-form {
		position:relative;

		.group {
			.buttons {
				padding-top:10px;
			}
		}

		.dropzone {
			padding:20px;
			margin:20px 0;
			border:2px dashed Config.color('sage');
			display:flex;
			align-items:center;
			cursor:pointer;
			gap:20px;
			transition:0.3s ease background;
			position:relative;
			img {
				display:block;
			}
			&.disabled {
				border:2px dashed color.adjust( Config.color('sage') , $lightness: 20% );
				img {
					opacity:.5;
				}
			}
			> .full {
				width:100%;
				.icon {
					padding:0;
				}
				div {
					text-align:left;
					text-transform:lowercase;
					font-size:15px;
					color:color.adjust( Config.color('text'), $lightness: 10% );
					transition:0.3s ease color;
					h2 {
						margin:0 0 5px 0;
						padding:0;
						font-style:italic;
					}
					.text {
						margin:0;
						font-style:italic;
					}
				}
			}
			&:hover {
				background:color.adjust( Config.color('sage') , $lightness: 22% );
				> .full {
					div {
						color:Config.color('text');
					}
				}
			}

		}
	}
