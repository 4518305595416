
	/**
	*
	* 		404
	*			- 404 Page Styles should go here
	*
	**/
	@use '../_config' as Config;
	
	#p404 {
		main {
			.container {
				padding:100px var(--container-padding);
				max-width:1000px;

				h1 {
					font-weight:400;
					text-transform:capitalize;
					font-size:clamp(30px, 4.5vw, 47px);
					letter-spacing:0.72px;
					line-height:34px;
				}

				.content {
					padding:35px 0 0;
					p {
						margin:0;
						padding:0;
						font-size:16px;
						letter-spacing:1.09px;
						line-height:36px;
						font-weight:400;

						@media all and (max-width:600px){
							font-size:16px;
							display:inline;
						}

						@media all and (max-width:400px){
							font-size:14px;
						}
					}
				}

				nav {
					padding-top:45px;
					div {
						padding-top:10px;
						a {
							font-weight:normal;
							font-size:14px;
							letter-spacing:0.54px;
							line-height:27px;
							padding-bottom:2px;
							text-decoration:none;
							transition:0.3s ease color;
						}
						&.top a {
							font-family:Config.font('headline');
							font-size:18px;
							letter-spacing:0.69px;
							line-height:27px;
							border-bottom:none;
						}
						svg {
							transition:0.3s ease transform;
							position:relative;
							top:2px;
							left:8px;
							height:14px;
							width:20px;
							enable-background:new 0 0 17.48 13.77;
							line {
								fill:none;
								stroke:Config.color('text');
								stroke-miterlimit:10;
								transition:0.3s ease stroke;
							}
						}
						&:hover {
							color:Config.color('brand');
							svg {
								transform:translateX(5px);
								line {
									stroke:Config.color('brand');
								}
							}
						}
					}
				}

			}
		}
	}
