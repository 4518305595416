
	/**
	*
	* 		Account - Order Details Page
	*			- Styles for the "Order" page
	*
	**/
	@use '../_config' as Config;

	#phistory {
		main .content {

			#order {
				width:100%;

				header {

					ul {
						border:1px dashed Config.color('sage');
						display:block;
						padding:20px;
						margin:20px 0;
						li {
							font-size:12px;
							text-align:left;
							strong {
								font-weight:normal;
								letter-spacing:0.49px;
								text-transform:uppercase;
							}
						}
					}
				}

				//Cart Table
				table {
					width:100%;
					border-collapse:collapse;
					position:relative;


					@media all and (min-width:551px){
						[data-view="mobile"] {
							display:none;
						}
					}

					@media all and (max-width:550px){
						[data-view="desktop"] {
							display:none;
						}
					}

					@media all and (max-width:1000px){
						width:100%;
					}


					thead {
						th {
							margin:auto 0;
							font-weight:normal;
							letter-spacing:0.49px;
							line-height:43px;
							text-transform:uppercase;
							font-size:12px;
							border-bottom:1px solid Config.color('sage');
						}
					}

					tfoot {
						tr {
							&:first-child {
								td {
									border-top:1px solid Config.color('sage');
									padding-top:15px !important;
								}
							}
							td:first-child {
								text-align:right;
							}
							td {
								padding:5px 0;
								font-size:14px;
								span {
									padding-right:5px;
								}
							}

							&.owing {
								td {
									border-top:1px dashed Config.color('sage');
									padding-top:20px;
								}
							}
						}
					}

					tbody {

						.demo {
							opacity:.10;
						}

						.notice td {
							padding:0;
							text-align:center;
							div {
								margin-top:-65px;
								width:100%;
								font-style:italic;
								position:absolute;
								padding:0;
								font-weight:600;

								@media all and (max-width:550px){
									margin-top:-90px;
								}
							}
						}

						tr {
							td {
								padding:15px 10px;
								vertical-align:middle;

								&.empty {
									padding:15px 0;
								}

								&.thumbnail {
									text-align:center;
									min-width:100px;
									img {
										max-height:80px;
										opacity:.8;
									}
								}

								&.product,
								.product {
									font-weight:normal;
									text-transform:lowercase;
									font-size:14px;
									.title {
										margin:0;
										text-decoration:none;
										font-family:Config.font('headline');
										border-bottom:1px solid transparent;
										font-size:20px;
									}

									a.title:hover {
										border-bottom:1px solid Config.color('text');
									}

									.variant,
									.type {
										margin:0;
										padding:0;
										font-size:80%;
									}
								}

								&.price,
								&.total {
									font-weight:normal;
									letter-spacing:0.63px;
									font-size:14px;
									margin:0;
									text-decoration:none;

									.was {
										text-decoration:line-through;
										color:rgba(255,0,0,.5);
										+ span {
											display:block;
											position:relative;
											top:-6px;
										}
									}
								}



								.price,
								.quantity {
									font-weight:normal;
									letter-spacing:0.63px;
									font-size:11px;
									margin:0;
									text-decoration:none;
									text-transform:uppercase;
									margin:2px 0;
									line-height:normal;

									.was {
										text-decoration:line-through;
										color:rgba(255,0,0,.5);
									}
								}

								&.quantity {

									.qty {
										font-weight:normal;
										letter-spacing:0.63px;
										font-size:14px;
										margin:0;
										text-decoration:none;
										padding:0 10px;
									}
								}

							}
						}
					}
				}

				#addresses {
					margin-top:30px;
					border:1px dashed Config.color('sage');
					padding:20px;
					display:flex;
					gap:10%;

					@media all and (max-width:600px){
						gap:25px;
					}

					@media all and (max-width:450px){
						flex-direction:column;
					}

					.divider {
						width:1px;
						height:100%;
						content:' ';
						border-left:1px dashed Config.color('sage');
						margin:0 20px;
					}

					address {
						h2 {
							font-size:20px;
							margin:0 0 10px;
						}
						ul {
							margin:0;
							padding:0;
							li {
								font-size:14px;
								padding:5px 0;
							}
						}
					}
				}
			}
		}

	}
