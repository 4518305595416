
	/**
	*
	* 		Breadcrumbs
	*			- The Page Breadcrumbs
	**/

	//Breadcrumbs
	nav#breadcrumbs {
		padding:30px var(--container-padding) 27px;
		a {
			font-size:10px;
			font-style:normal;
			font-weight:500;
			line-height:140%;
			letter-spacing:0.5px;
			text-transform:capitalize;
			&:after {
				content:' / ';
			}
			&:last-child {
				&:after {
					content:'';
				}
			}
		}
		@media all and (max-width:750px){
			padding:15px var(--container-padding);
		}
		@media all and (max-width:450px){
			padding:10px var(--container-padding);
		}
	}