
	/**
	*
	* 		Search Page
	*			- Styles for the "Search" page
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	@use '../base/_layout.scss' as *;

	#plogin {

		main {
			@extend .container;
			max-width:calc( 950px + ( var(--container-padding) * 2 ) );
			padding:50px var(--container-padding);
			display:flex;
			justify-content:space-evenly;

			section {
				border-right:1px solid Config.color('sage');
				width:55%;
				padding:100px 0;
				padding:50px 45px;
				box-sizing:border-box;

				header {
					text-align:center;

					h1, h2 {
						font-weight:normal;
						font-size:45px;
						font-weight:400;
						letter-spacing:-0.56px;
						line-height:114.286%;
						margin:0;
						padding:0;

						+ p {
							a {
								display:block;
								text-decoration:none;
								line-height:10px;
							}
						}
					}

					h1 {
						+ p {
							margin:1em 0 0;
						}
					}

					.reset {
						margin:0 0 1em;
						a {
							text-decoration:underline;
							font-size:80%;
						}
					}

				}

				form {
					max-width:350px;
					margin:0 auto;
					.submit {
						text-align:center;
					}
				}

				+ section {
					text-align:center;
					border-right:none;
					width:45%;
					p {
						padding:20px 0;
						margin:1em 0;
					}
				}

				footer {
					margin-top:20px;
					padding-top:20px;
					border-top:1px solid color.adjust( Config.color('sage') , $lightness:20% );
					a {
						text-decoration:underline;
						font-size:80%;
						transition:0.3s ease color;
						&:hover {
							color:Config.color('brand');
						}
					}
				}
			}

			@media all and (max-width:750px){
				flex-direction:column;
				max-width:100%;
				section {
					border-right:none;
					width:100% !important;
					&:first-child {
						border-bottom:1px solid Config.color('sage');
					}
				}
			}

			@media all and (max-width:500px){
				padding:50px 0;
			}

		}
	}
