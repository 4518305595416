
	/**
	*
	* 		Account
	*			- Account-related classes
	*
	**/
	#b2b-cta {
		header {
			h2 {
				font-size:40px;
				font-weight:400;
				line-height:120%;
				margin:0;
				+ p {
					font-size:14px;
					margin:0 0 30px;
				}
			}
		}
		.recaptcha {
			margin:0;
		}
	}