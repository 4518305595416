
	/**
	*
	* 		Footer
	*			- The Footer styles will go here
	*
	**/
	@use '../_config' as Config;

	footer#footer {
		margin-top:210px;
		position:relative;

		@media all and (max-width:700px){
			margin-top:clamp( 50px , 6vw, 75px);
		}

		section.newsletter {
			width:100%;
			position:absolute;
			top:-165px;
			box-sizing:border-box;

			@media all and (max-width:700px){
				position:relative;
				top:0;
				padding:0 var(--container-padding);
			}

			&.business {
				.container {
					background:Config.color('text');
				}
			}

			.container {
				max-width:950px;
				margin:0 auto;
				background:Config.color('brand');
				display:flex;
				min-height:230px;
				padding:0;

				@media all and (max-width:800px){
					width:100%;
					margin:0 auto;
				}

				@media all and (max-width:700px){
					display:block;
				}

				.image {
					width:100%;
					max-width:387px;
					max-height:320px;
					overflow:hidden;
					@media all and (max-width:700px){
						width:100%;
						min-height:205px;
						max-width:100%;
						background-position:center bottom;
					}
					img {
						display:block;
						object-fit:contain;
						object-position:center center;
						width:100%;
						@media all and (max-width:700px){
							object-fit:cover;
						}
					}
				}

				.form {
					max-width:379px;
					width:100%;
					margin:auto;
					padding:0 20px;

					iframe {
						border:none;
						width:100%;
					}

					@media all and (max-width:700px){
						padding:40px 0 60px;
					}

					@media all and (max-width:400px){
						padding:21px 0 37px;
					}

					header {
						font-family:baskerville-display-pt, serif;
						text-transform:lowercase;
						font-weight:400;
						line-height:clamp(133.333%, 5.5vw, 117.647%);
						letter-spacing:-0.34px;
						font-size:clamp(24px, 5.5vw, 34px);
						//max-width:clamp(216px, 45vw, 291px);
						line-height:normal;
						text-align:center;
						margin:0 auto 29px;
						padding:0 20px;
						display:block;
						color:#FFF;
					}

					p {
						font-size:clamp(18px, 5.5vw, 15px);
						color:#FFF;
						text-align:center;
						line-height:normal;
						margin:0 auto;
						padding-bottom:10px;
					}

					code {
						font-size:clamp(18px, 5.5vw, 28px);
						border:1px dashed #FFF;
						padding:20px;
						margin:20px auto 0;
						display:block;
						text-align:center;
						color:#FFF;
						cursor:pointer;
						position:relative;
						transition:0.3s ease background;
						.success {
							top:-10px;
							right:0;
							font-size:10px;
							position:absolute;
							opacity:0;
							transition:0.3s ease opacity, 0.3s ease top;
							&.show {
								opacity:1;
								top:-25px;
							}
						}
						.copy {
							position:absolute;
							top:-1px;
							right:-1px;
							align-items:center;
							opacity:0;
							transition:0.3s ease opacity;
							width:10px;
							height:10px;
							padding:5px;
							background:#FFF;
							border:1px solid transparent;
							margin-left:10px;
							display:inline-block;
							svg {
								width:100%;
								height:100%;
								display:block;
								path {
									fill:Config.color('text');
								}
							}
						}
						&:hover {
							background:rgba( Config.color('text') , 0.25 );
							.copy {
								opacity:1;
							}
						}
					}

					form {

						.error {
							color:#FFF;
							text-align:left;
							padding:10px 0 0 10px;
							font-size:90%;
						}

						> div {
							display:flex;
							label {
								display:none;
							}
							input[type="text"],
							button {
								appearance:none;
								border-radius:0;
								color:#FFF;
								font-weight:400;
								font-size:14px;
								letter-spacing:1px;
								background:transparent;
								line-height:157.143%;
								text-transform:lowercase;
								margin:0;
								padding:0;
								display:block;
								float:left;
								padding:5px;
								outline:none;
								&::placeholder {
									color:#FFF;
								}
							}

							input[type="text"] {
								border:1px solid Config.color('sage');
								border-right:none;
								padding:15px 20px;
								color:#FFF;
								width:100%;
								box-shadow:none;
							}

							button {
								padding:15px 20px;
								margin-left:-1px;
								text-transform:capitalize;
								color:#FFF;
								background:transparent;
								font-weight:600;
								line-height:142.857%;
								border:1px solid Config.color('sage');
								border-left:none;
								font-size:14px;
								cursor:pointer;
								transition:0.3s ease color;
								font-weight:600;
								letter-spacing:0.34px;
								box-shadow:none;
								&:hover {
									color:Config.color('sage');
								}
								&[disabled] {
									background:transparent !important;
									opacity:1 !important;
									border:1px solid Config.color('sage') !important;
									border-left:none !important;
									cursor:not-allowed;
								}
							}
							@media all and (max-width:400px){
								margin:0 25px;
								input[type="text"] {
									padding:15px 5px 15px 15px;
								}
								button {
									padding:15px 15px 15px 5px;
								}
							}
						}
					}

				}
			}
		}




		section.footer {
			background:#FFF;
			padding:175px 0 20px;

			@media all and (max-width:700px){
				padding:50px 0 20px;
			}

			@media all and (max-width:450px){
				padding:54px 0 20px;
			}

			.container {

				.row {
					display:flex;
					flex-direction:row;

					@media all and (max-width:700px){
						flex-wrap:wrap;
					}

					+ .row {
						border-top:1px solid Config.color('sage');
						margin-top:50px;
						padding-top:30px;
						.info {
							max-width:33%;
							@media all and (max-width:700px){
								max-width:100%;
								p {
									text-align:center;
								}
							}
							p {
								margin:0;
							}
						}
					}

					.siteby {
						display:flex;
						text-transform:uppercase;
						align-items:center;
						p {
							font-size:9px !important;
							font-weight:600 !important;
							padding-right:3px;
						}
						a {
							margin:0 !important;
							svg {
								width:65px;
								height:8px;
							}
							&:hover {
								color:#211331 !important;
							}
						}
					}

					header {
						display:block;
						font-weight:600;
						font-size:11px;
						line-height:18px;
						letter-spacing:1.32px;
						text-transform:uppercase;
						color:Config.color('text');
						padding-bottom:15px;
					}

					.info {
						margin:0 auto auto 0;
						max-width:30%;

						@media all and (max-width:700px){
							width:100%;
							max-width:100%;
						}

						.logo {
							max-width:64px;
							max-height:68px;
							height:auto;
						}

						header {
							padding-top:10px;
						}

						h3 {
							font-family:Config.font('primary');
							font-size:11px;
							font-style:normal;
							font-weight:600;
							line-height:163.636%;
							letter-spacing:1px;
							text-transform:uppercase;
							color:Config.color('brand');
							padding-top:10px;
						}

						p {
							text-align:left;
							font-size:14px;
							line-height:157.143%;
							font-weight:400;
						}

						a {
							display:inline-block;
							font-size:14px;
							font-weight:400;
							line-height:157.143%;
							color:Config.color('text');
							margin-top:6px;
							border-bottom:1px solid transparent;
							transition:0.3s ease color;
							&:hover {
								color:Config.color('sage');
							}
						}
					}

					.lists {
						margin:45px 0 auto auto;
						display:flex;
						width:100%;
						padding-left:75px;

						@media all and (max-width:1000px){
							flex-wrap:wrap;
						}

						@media all and (max-width:700px){
							margin:45px 0 0 0;
							width:100%;
							padding-left:0;
						}
					}

					.list {
						margin:0 auto;

						@media all and (min-width:700px) and (max-width:1000px){
							margin:0;
							width:50%;
						}

						a {
							display:inline-block;
							font-size:14px;
							font-weight:400;
							line-height:157.143%;
							color:Config.color('text');
							margin-top:6px;
							border-bottom:1px solid transparent;
							transition:0.3s ease color;
							&:hover {
								color:Config.color('sage');
							}
						}
					}

					div.list {
						@media all and (max-width:1000px){
							width:75%;
							padding-top:40px;
							margin:auto auto auto 0;
						}
						@media all and (max-width:700px){
							width:100%;
							margin:50px 15%;
						}
						@media all and (max-width:500px){
							width:100%;
							margin:50px 0 25px;
						}
					}



					.social {
						margin:auto;
						display:flex;

						@media all and (max-width:700px){
							margin:25px auto;
							width:100%;
							justify-content:center;
						}
						a {
							color:Config.color('text');
							font-size:17px;
							margin-right:10px;
							padding:5px;
							border:1px solid Config.color('text');
							border-radius:100%;
							text-decoration:none;
							display:flex;
							width:30px;
							height:30px;
							i {
								margin:auto;
							}
							&:hover {
								background:Config.color('brand');
								border:1px solid Config.color('brand');
								color:#FFF;
								transition:all 0.5s ease;
							}
						}
					}

					.copyright {
						width:33%;
						text-align:right;
						@media all and (max-width:700px){
							text-align:center;
							width:100%;
						}
						p {
							display:block;
							font-size:14px;
							font-weight:400;
							line-height:157.143%;
							margin:0;
						}

						.links {
							color:Config.color('text');
							font-size:14px;

							@media all and (max-width:700px){
								justify-content:center;
							}

							a {
								display:inline-block;
								font-size:14px;
								font-weight:400;
								line-height:157.143%;
								transition:0.3s ease color;
								&:hover {
									color:Config.color('sage');
								}
							}
						}
					}
				}
			}
		}
	}



