
	/**
	*
	* 		Subnav
	*			- Page Subnavigation
	*
	**/
	@use 'sass:color';
	@use '../_config' as Config;
	
	#subnav {
		background:color.adjust( Config.color('sage') , $lightness: 22% );
		padding:16px 0;
		overscroll-behavior-x:contain;

		&.text {
			padding:10px 0;
			.container {
				nav {
					display:flex;
					flex-wrap:wrap;
					justify-content:center;
					align-items:center;

					a {
						display:block;
						font-size:12px;
						font-weight:500;
						letter-spacing:0.48px;
						line-height:18px;
						text-decoration:none;
						text-transform:uppercase;
						padding:5px 25px;

						span {
							transition:0.3s ease border-bottom;
							border-bottom:1px solid transparent;
						}

						&.current,
						&:hover {
							span {
								border-bottom:1px solid Config.color('text');
							}
						}
					}
				}
				&,
				nav > div {
					gap:0;
				}
			}
		}

		.container {
			flex-wrap:wrap;
			&,
			nav > div {
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
				gap:10px;
				@media all and (max-width:960px){
					justify-content:left;
				}
				@media all and (max-width:400px){
					//flex-direction:column;
					align-items:flex-start;
				}
			}
			header {
				h3 {
					font-family:Config.font('primary');
					font-size:14px;
					font-weight:600;
					line-height:157.143%;
					letter-spacing:0.7%;
					text-transform:uppercase;
					margin:0;
				}
			}
			nav {
				white-space:nowrap;
				overflow-x:hidden;
			}

			@media all and (max-width:800px){
				padding:0;
				margin:0;
				* > div {
					padding:0 var(--container-padding);
				}
				nav {
					/*
					div:after {
						content:'\00a0';
						display:block;
						padding:0 calc( var(--container-padding) - 10px ) 0 0; //Remove the Gap from the Padding to make it even
					}
					*/
				}
			}
		}
	}