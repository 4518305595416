
	/**
	*
	* 		Account Register Page
	*			- Styles for the "Register" page
	*
	**/
	@use '_login.scss';

	#pregister {

		@extend #plogin;

		#register-form {
			.preloader {
				position:fixed;
				z-index:100;
			}
		}

	}
