
	/**
	*
	* 		Home
	*			- Styles specific to the home page should go here
	*
	**/
	@use '../_config' as Config;

	#pblog {

		.banner {
			p {
				text-align:center;
			}
		}


		#featured {
			padding:50px var(--container-padding) 100px;

			@media all and (max-width:650px){
				max-width:none;
				padding:0 0 50px 0;
			}

			article {
				min-height:538px;
				background-color:Config.color('background');
				background-position:center center;
				background-size:cover;
				padding-bottom:0;
				position:relative;
				overflow:hidden;

				@media all and (max-width:650px){
					min-height:413px;
				}

				> img {
					object-fit:cover;
					object-position:center center;
					display:block;
					width:100%;
				}

				> div {
					width:100%;
					height:100%;
					min-height:538px;
					background:rgba(0,0,0,.5);
					display:flex;
					position:relative;
					z-index:1;

					@media all and (max-width:650px){
						min-height:413px;
					}

					> div {
						margin:auto;
						max-width:650px;
						text-align:center;

						@media all and (max-width:650px){
							padding:0 15px;
							max-height:413px;
						}

						h2 {
							font-family:Config.font('headline');
							margin:15px 0;
							font-size:40px;
							line-height:43px;
							text-align:center;
							font-weight:300;
							color:#FFF;

							@media all and (max-width:650px){
								font-size:28px;
								line-height:30px;
							}
						}

						p {
							font-family:Config.font('primary');
							font-weight:300;
							font-size:18px;
							line-height:30px;
							letter-spacing:0.49px;
							padding:15px 0;
							text-align:center;
							font-weight:500;
							color:#FFF;

							@media all and (max-width:650px){
								max-width:400px;
								margin:0 auto;
								font-size:16px;
								line-height:22px;
							}
						}

					}

				}

			}

		}


		.articles {
			margin-bottom:90px;

			@media all and (max-width:600px){
				margin-bottom:40px;
			}

			header {
				padding-bottom:30px;
				h2 {
					font-size:42px;
					font-weight:400;
					line-height:119.048%;
					text-align:center;
				}
			}

			.articles {
				display:flex;
				justify-content:space-evenly;
				flex-wrap:wrap;
				gap:20px;

				article {
					max-width:448px;
					padding-bottom:30px;
					margin:auto auto auto 0;
					width:30%;

					@media all and (max-width:800px){
						width:calc( 50% - 10px );
						max-width:none;
					}

					@media all and (max-width:600px){
						width:100%;
						margin:0 0 25px 0;
					}
				}
			}

		}


		#products {
			padding:0 var(--container-padding) 110px;

			@media all and (max-width:800px){
				padding-bottom:50px;
			}

			@media all and (max-width:550px){
				padding-bottom:0;
			}

			header {
				padding-bottom:30px;
				h2 {
					font-size:42px;
					font-weight:400;
					line-height:119.048%;
					text-align:center;
				}
			}

			.collection {
				.product-card:nth-child(4) {
					display:none;
					@media all and (max-width:800px){
						display:block;
					}
				}
			}

			footer {
				text-align:center;
				padding-top:50px;
			}
		}

		#explore {
			margin:60px auto;
			> div {
				border-radius:30px;
				position:relative;
				overflow:hidden;

				&:before,
				&:after,
				& > div:before,
				& > div:after {
					content:' ';
					position:absolute;
					width:50px;
					height:50px;
					border-radius:100%;
					border:1px dashed Config.color('sage');
					background:Config.color('background');
				}
				&:before {
					top:-20px;
					left:-20px;
				}
				&:after {
					bottom:-20px;
					left:-20px;
				}

				> div:before {
					top:-20px;
					right:-20px;
				}

				> div:after {
					bottom:-20px;
					right:-20px;
				}

				> div {
					padding:70px 70px 30px 70px;
					display:flex;
					justify-content:space-between;
					border:1px dashed Config.color('sage');

					@media all and (max-width:800px){
						flex-direction:column;
					}

					@media all and (max-width:650px){
						padding:70px 40px 30px 40px;
					}

					h5 {
						margin:0;
						font-family:Config.font('headline');
						font-size:32px;
						line-height:34px;
						text-align:left;
						font-weight:100;
						max-width:148px;

						@media all and (max-width:800px){
							max-width:none;
							text-align:center;
							padding-bottom:40px;
						}
					}

					.list {
						display:flex;
						justify-content:space-evenly;
						flex-wrap:wrap;
						width:100%;

						nav {
							margin:0 20px 40px 0;
							min-width:calc( 20% - 20px );
							flex-grow:1;

							@media all and (max-width:650px){
								min-width:calc( 50% - 20px );
							}

							@media all and (max-width:500px){
								min-width:100%;
								margin:0 0 40px 0;
								ul {
									li {
										white-space:nowrap;
									}
								}
							}

							ul {
								list-style:none;
								margin:0;
								padding:0;

								li {
									display:block;
									a {
										font-family:Config.font('primary');
										font-size:12px;
										line-height:23px;
										letter-spacing:1.06px;
										text-align:left;
										text-decoration:none;
										font-weight:300;
										position:relative;
										strong {
											font-weight:600;
											text-transform:uppercase;
											padding-bottom:10px;
											display:inline-block;
										}
										&:hover {
											border-bottom:1px solid Config.color('sage');
											strong {
												color:Config.color('brand');
											}
											.box {
												display:block;
											}
										}

										&[disabled] {
											span {
												opacity:.5;
											}
										}
										
										.box {
											display:none;
											position:absolute;
											background:#FFF;
											border-radius:2px;
											padding:5px;
											text-align:center;
											filter:drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.14));
											font-size:12px;
											font-weight:500;
											line-height:150%;
											width:125px;
											left:calc( ( 50% - (125px / 2 ) ) );
											bottom:100%;
											text-align:center;
											box-sizing:border-box;
											&:after {
												width:0;
												height:0;
												border-left:10px solid transparent;
												border-right:10px solid transparent;
												border-top:10px solid #FFF;
												content:' ';
												position:absolute;
												bottom:-10px;
												left:calc( 50% - 10px );
											}
										}
									}
									&:first-child {
										a:hover {
											border-bottom:none;
										}
									}
								}
							}
						}

					}

				}

			}

		}


	}
