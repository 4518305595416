
	/**
	*
	* 		Our Story
	*			- Styles for "Our Story" page
	*
	**/
	@use '../_config' as Config;

	#pstory {

		#about {
			text-align:center;
			padding-top:50px;
			padding-bottom:100px;
			max-width:755px;

			@media all and (max-width:755px){
				padding-bottom:50px;
				padding-top:5px;
			}

			h1 {
				font-weight:normal;
				text-align:center;
				font-size:40px;
				line-height:34px;
				@media all and (max-width:755px){
					font-size:34px;
					text-align:left;
					line-height:150%;
				}
			}

			p {
				font-weight:400;
				font-size:18px;
				letter-spacing:0.49px;
				line-height:30px;

				@media all and (max-width:755px){
					text-align:left;
					font-size:16px;
					line-height:150%;
					letter-spacing:0.44px;
				}
			}
		}

		#quote {
			padding:100px var(--container-padding);
			max-width:950px;
			text-align:center;

			@media all and (max-width:755px){
				padding-top:50px;
				padding-bottom:50px;
			}

			q {
				font-family:Config.font('headline');
				font-weight:normal;
				font-size:40px;
				line-height:47px;
				display:block;
				&:before,
				&:after {
					content:'"';
				}

				@media all and (max-width:755px){
					font-size:26px;
					line-height:35px;
				}
			}

			cite {
				display:block;
				font-size:18px;
				letter-spacing:0.49px;
				line-height:30px;
				font-style:normal;
				padding-top:20px;

				@media all and (max-width:755px){
					font-size:16px;
					letter-spacing:0.44px;
					line-height:30px;
				}
			}
		}

		#photo {
			max-width:1240px;
			img {
				width:100%;
				margin:0 auto;
				object-fit:cover;
				height:auto;
				min-height:200px;
			}
		}

	}
